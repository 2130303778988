import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { debounce } from 'lodash';

@Directive({
  selector: '[app-debounce-click]',
})
export class DebounceClickDirective {
  @Output() debounceClick = new EventEmitter();

  @HostListener('click', ['$event'])
  debouncedClick = debounce(
    (event: Event) => {
      this.debounceClick.emit(event);
    },
    250,
    { leading: true, trailing: false },
  );
}
