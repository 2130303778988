import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StaticPagesService } from '../../static-pages.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-mobile-register',
  templateUrl: './mobile-register.component.html',
  styleUrls: ['./mobile-register.component.css'],
})
export class MobileRegisterComponent implements OnInit {
  content: SafeHtml;
  showStep1 = true;
  showStep2 = false;
  showStep3 = false;
  showStep4 = false;

  pricingOptions;

  subscriptionOptionCode: string;
  titleName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  subScriptionOption: string;
  groupMembershipCode: string;
  acceptedTermsConditions: string;
  cardNumber: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  telephone: string;
  faxNumber: string;
  emailAddress: string;
  username: string;
  password: string;
  confirmPassword: string;
  profession: string;
  professionOther: string;
  school: string;
  specialty: string;
  emailServices1: string;
  emailServices2: string;
  training: string;
  referral: string;
  cardType: string;
  cardExpMonth: string;
  cardExpYear: string;
  cardSecurityCode: string;
  customerGift: string;
  giftEmail: string;

  subscriptionOption1: string;
  subscriptionOption2: string;
  subscriptionOption3: string;
  subscriptionOption4: string;
  subscriptionOption5: string;
  subscriptionOption6: string;

  checkoutTotal;

  showSubmitRegistrationButton = true;

  priceOptionCount = 0;

  selectedSubscriptionOption = undefined;

  showOverlay = false;
  overlayText = '';

  verisignUrl = `${this.baseUrl}verisign.html`;

  constructor(
    @Inject('BASE_URL') private baseUrl,
    private userService: UserService,
    private staticPages: StaticPagesService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {}

  transform(v: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v);
  }

  ngOnInit() {
    var ptr = this;
    this.pricingOptions = this.userService.getPricing();
    this.pricingOptions.subscribe({
      next(result: Array<any>) {
        ptr.priceOptionCount = result.length;
      },
      complete() {},
    });
    this.staticPages.getPageHtml('site_license_terms').then((content) => {
      this.content = this.transform(
        content
          .replace(/<body>/gi, '')
          .replace(/<\/body>/gi, '')
          .replace(/<html>/gi, '')
          .replace(/<\/html>/gi, '')
          .replace(/<br \/>/gi, ''),
      );
    });
  }

  doSubscriptionRadioSelect(pricingOption: any): void {
    this.checkoutTotal = pricingOption.price * 1 + pricingOption.shipping * 1;
    this.selectedSubscriptionOption =
      pricingOption.price * 1 +
      pricingOption.shipping * 1 +
      '|' +
      (pricingOption.include_textbook == 1 ? pricingOption.billing_interval_code + 'T' : pricingOption.billing_interval_code) +
      '|' +
      (pricingOption.automatic_renewal == 1 ? 'R' : 'S');
  }

  clearStep1(): void {
    this.checkoutTotal = null;
    this.selectedSubscriptionOption = null;
    this.subscriptionOptionCode = null;
    this.doShowStep1();
  }

  doShowStep1(): void {
    this.showStep1 = true;
    this.showStep2 = false;
    this.showStep3 = false;
    this.showStep4 = false;
  }

  doShowStep2(): void {
    if (!(this.selectedSubscriptionOption || (this.subscriptionOptionCode && this.subscriptionOptionCode.trim() != ''))) {
      alert('Please select a subscription option or enter a group code');
      return;
    }

    this.showStep1 = false;
    this.showStep2 = true;
    this.showStep3 = false;
    this.showStep4 = false;
  }

  doShowStep3(): void {
    var validationTargets = [
      { fieldName: 'titleName', message: 'Title' },
      { fieldName: 'firstName', message: 'First Name' },
      { fieldName: 'lastName', message: 'Last Name' },
      { fieldName: 'birthMonth', message: 'Birth Month' },
      { fieldName: 'birthDay', message: 'Birth Day' },
      { fieldName: 'birthYear', message: 'Birth Year' },
      { fieldName: 'address1', message: 'Address' },
      { fieldName: 'city', message: 'City' },
      { fieldName: 'state', message: 'State' },
      { fieldName: 'zip', message: 'Zip' },
      { fieldName: 'country', message: 'Country' },
      { fieldName: 'emailAddress', message: 'Email' },
      { fieldName: 'profession', message: 'Profession' },
      { fieldName: 'school', message: 'School' },
      { fieldName: 'training', message: 'Training' },
      { fieldName: 'username', message: 'Username' },
      { fieldName: 'password', message: 'Password' },
      { fieldName: 'confirmPassword', message: 'Password Confirmation' },
    ];
    for (var validationTarget of validationTargets) {
      if (this[validationTarget.fieldName]) {
      } else {
        alert(validationTarget.message + ' is missing');
        return;
      }
    }
    if (this.password != this.confirmPassword) {
      alert('Password Confirmation does not match Password');
      return;
    }
    if (!this.acceptedTermsConditions) {
      alert('Please accept the Terms and Conditions');
      return;
    }
    this.showStep1 = false;
    this.showStep2 = false;
    this.showStep3 = true;
    this.showStep4 = false;
  }

  doShowStep4(): void {
    var validationTargets = [
      { fieldName: 'cardType', message: 'Card Type' },
      { fieldName: 'cardNumber', message: 'Card Number' },
      { fieldName: 'cardExpMonth', message: 'Card Expiration Month' },
      { fieldName: 'cardExpYear', message: 'Card Expiration Year' },
      { fieldName: 'cardSecurityCode', message: 'Card Security Code' },
    ];

    if (this.subscriptionOptionCode && this.subscriptionOptionCode.trim() != '') {
    } else {
      for (var validationTarget of validationTargets) {
        if (this[validationTarget.fieldName]) {
        } else {
          alert(validationTarget.message + ' is missing');
          return;
        }
      }
    }

    var registrationObject = {};
    var registrationFields = [
      'subscriptionOption1',
      'subscriptionOption2',
      'subscriptionOption3',
      'subscriptionOption4',
      'subscriptionOption5',
      'subscriptionOption6',
      'subscriptionOptionCode',
      'titleName',
      'firstName',
      'middleName',
      'lastName',
      'subScriptionOption',
      'groupMembershipCode',
      'acceptedTermsConditions',
      'cardNumber',
      'birthDay',
      'birthMonth',
      'birthYear',
      'address1',
      'address2',
      'city',
      'state',
      'zip',
      'country',
      'telephone',
      'faxNumber',
      'emailAddress',
      'username',
      'password',
      'confirmPassword',
      'profession',
      'professionOther',
      'school',
      'specialty',
      'emailServices1',
      'emailServices2',
      'training',
      'referral',
      'cardType',
      'cardExpMonth',
      'cardExpYear',
      'cardSecurityCode',
      'customerGift',
      'giftEmail',
    ];
    for (var field of registrationFields) {
      registrationObject[field] = this[field];
    }
    registrationObject['selectedSubscriptionOption'] = this.selectedSubscriptionOption;

    this.showSubmitRegistrationButton = false;
    this.overlayText = 'Processing . . .';
    this.showOverlay = true;

    this.userService.registerUser(registrationObject).subscribe(
      (result: Array<any>) => {
        if (result['register_result'] == 'success') {
          this.showStep1 = false;
          this.showStep2 = false;
          this.showStep3 = false;
          this.showStep4 = false;
          alert(result['message']);
          this.router.navigateByUrl('/landing');
        } else {
          alert(result['message']);
        }
      },
      (err) => {
        console.error(`Caught error: ${err}`);
      },
      () => {
        this.showSubmitRegistrationButton = true;
        this.showOverlay = false;
        this.overlayText = '';
      },
    );
  }
}
