import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable, Simulation } from '../model/mms.model';

@Injectable({
  providedIn: 'root',
})
export class SimulationsService {
  constructor(private http: HttpClient, @Inject('BASE_API_URL') private baseApiUrl: string) {}

  generatePlayerLink(caseSimulationId, simulationPersonName, simulationPersonEmail) {
    const url = `${this.baseApiUrl}simulations/${caseSimulationId}/link?simulationPersonName=${simulationPersonName}&simulationPersonEmail=${simulationPersonEmail}`;

    return this.http.get(url);
  }

  getSimulationsList(): Observable<Pageable<Simulation>> {
    return this.http.get<Pageable<Simulation>>(`${this.baseApiUrl}simulations/`);
  }
}
