<div #cb class="content-balloon d-flex flex-column" [ngStyle]="{ width: width }">
  <div class="content-balloon-content d-flex flex-column flex-1-0-auto" [ngStyle]="{ maxHeight: maxHeight }"><ng-content [ngStyle]="{ display: flex, flex: '1 0 auto', flexDirection: 'column' }"></ng-content></div>
  <div *ngIf="showOverlay" class="overlay">
    <div class="center overlay-panel">
      <div style="flex: 0 0 auto">
        <mat-progress-spinner class="center" color="primary" mode="indeterminate" [diameter]="48"></mat-progress-spinner>
      </div>
      <div class="overlay-text">{{ overlayText }}</div>
    </div>
  </div>
</div>
