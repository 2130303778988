<app-content-balloon width="400px">
  <div class="title-text header">Contact Information</div>

  <div>Our address is:</div>
  <div class="indented">
    <div>Medical Media Systems</div>
    <div>2916 NW Bucklin Hill Rd. #481</div>
    <div>Silverdale, WA 98383</div>
  </div>

  <p>Our telephone number is: 360-516-6110</p>
  <p>Our fax number is: 360-516-6113</p>
  <p>Thank you for your interest in Medical Media Systems.</p>
</app-content-balloon>
