import { Directive, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageItem } from './model/mms.model';
import { Menu2Service } from './services/menus.service';
import { SoundDialogComponent } from './sound-dialog/sound-dialog.component';

@Directive()
export class BasePlayerComponent implements OnInit {
  _pv = false;
  demoMode = false;
  showVideo: boolean = false;
  showVideoList: boolean = false;
  autoplay: boolean = null;
  menuHierarchy;
  lastLoadedMenuHierarchy = [];
  pageItems;
  currentVideoMenuTitle = '';
  videoHtml;
  currentMenu = { pageId: 0 };
  playingItem: PageItem;

  doCloseMenu = false;
  menuTimeout = 1500;

  prevTriggerRef;

  columnList = [];
  touchInProgress = false;
  organizedPageItems = [];
  get playerVisible() {
    return this._pv;
  }

  set playerVisible(value) {
    this._pv = value;
  }

  jsVideoLink;

  constructor(
    protected menuService: Menu2Service,
    protected _sanitizer: DomSanitizer,
    protected dialog: MatDialog,
    demoMode: boolean,
  ) {
    this.demoMode = demoMode;
  }

  ngOnInit() {
    this.menuService.loadRootMenu().subscribe((rootMenu) => {
      this.lastLoadedMenuHierarchy = rootMenu.subMenus;
      this.menuHierarchy = rootMenu.subMenus;

      for (var menuItem of rootMenu.subMenus) {
        if (menuItem.title.trim() != '') {
          console.log('!!!');
          this[menuItem.title.replace(' ', '').trim().toLowerCase() + 'css'] = 'white-text';
        }
      }
    });

    this.postInit();
    this.playingItem = undefined;
  }

  outputNbsps(indentLevel) {
    var str = '';

    for (var i = 0; i < parseInt(indentLevel) - 1; i++) {
      str += '&nbsp;&nbsp;';
    }

    return str;
  }

  displayVideoList(menu) {
    this.stopVideo();

    this.currentMenu = menu;
    this.pageItems = this.menuService.getPageItems(menu.pageId);

    this.pageItems.subscribe((result: Array<any>) => {
      for (var menuItem of result) {
        if (menuItem.title?.trim() !== '') {
          console.log(`Loading column descriptions for: ${menuItem.pageId}`);
          this.loadColumnDescriptions(menuItem.pageId);
          break;
        }
      }
    });

    this.loadOrganizedVideoPlaybackList();

    this.currentVideoMenuTitle = menu.title;
    this.showVideoList = true;
  }

  stopVideo() {
    this.playingItem = undefined;
  }

  helpChromeSound() {
    this.dialog.open(SoundDialogComponent);
  }

  highlightMenuItem(option) {
    for (var menuItem of this.lastLoadedMenuHierarchy) {
      if (menuItem.title.trim() != '') {
        this[menuItem.title.replace(' ', '').trim().toLowerCase() + 'css'] = 'white-text';
      }
    }

    if (this.hasOwnProperty('ophthalmoscopycss')) {
      this['ophthalmoscopycss'] = 'white-text';
    }

    this[option] = 'white-text-hover';
  }

  playVideo(pageItem) {
    if (this.demoMode && !pageItem.availableInDemo) {
      return;
    }

    this.playingItem = pageItem;
  }

  getVideoClass(videoHtml) {
    if (videoHtml && videoHtml.toString().includes('="360"')) {
      return 'video360';
    } else {
      return 'video720';
    }
  }

  signalMenuStayOpen(timeout) {
    this.doCloseMenu = false;
    this.menuTimeout = timeout;
  }

  signalMenuClose(timeout) {
    this.doCloseMenu = true;
    this.menuTimeout = timeout;
  }

  closeMenuLoop(menuTriggerRef) {
    var ptr = this;
    window.setTimeout(function () {
      if (ptr.doCloseMenu === true) {
        menuTriggerRef.closeMenu();
      } else {
        ptr.closeMenuLoop(menuTriggerRef);
      }
    }, ptr.menuTimeout);
  }

  startTouch() {
    this.touchInProgress = true;
  }

  openAVideoMenu(menuTriggerRef, timeout, event = null) {
    if (event?.type === 'mouseenter' && this.touchInProgress) {
      event.preventDefault();
      return;
    }

    this.touchInProgress = false;
    if (this.prevTriggerRef) {
      this.prevTriggerRef.closeMenu();
    }
    this.prevTriggerRef = menuTriggerRef;
    this.menuTimeout = timeout;
    menuTriggerRef.openMenu();
    this.doCloseMenu = false;
    this.closeMenuLoop(menuTriggerRef);
  }

  closeVideoMenu(menuTriggerRef) {
    menuTriggerRef.closeMenu();
  }

  closeAVideoMenu(menuTriggerRef) {
    this.doCloseMenu = true;
  }

  transformHtml(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  getPdfFilename(pageItem: PageItem): string {
    const filename = pageItem.pdfLink.substring(pageItem.pdfLink.lastIndexOf('/')).replace(/^\//, "");

    console.log('filename is: ' + filename);

    return filename;
  }

  protected postInit() {
    // Intentionally blank... Override to perform post-init activity.
  }

  private loadColumnDescriptions(pageId) {
    this.menuService.getPageColumnDescriptions(pageId).subscribe((response: any) => {
      console.log(`Found ${response.length} columns`);
      this.columnList = response;
    });
  }

  private loadOrganizedVideoPlaybackList() {
    this.pageItems.subscribe((result: Array<any>) => {
      this.organizedPageItems.splice(0);

      const tmpItems = [];
      let ct = 0;

      for (var pageItem of result) {
        pageItem.title = pageItem.title?.replaceAll(/&nbsp;/g, ' ');

        if (pageItem.title && pageItem.title !== '') {
          console.log(`Adding menu item ${pageItem.title}`);
          tmpItems.push({ left: pageItem, right: null });
          ++ct;
        } else if (pageItem.title !== null && pageItem.title !== undefined) {
          console.log(`Adding extra item to the right`);
          tmpItems[ct - 1].right = pageItem;
        }
      }

      for (ct = 0; ct < tmpItems.length; ct++) {
        if (!(tmpItems[ct].right === null && tmpItems[ct].left.title === null)) {
          this.organizedPageItems.push(tmpItems[ct]);
        }
      }

      console.log('Organized page content:');
      console.log(JSON.stringify(this.organizedPageItems, null, 2));
    });
  }
}
