import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVarsService } from '../../global-vars.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-mobile-account',
  templateUrl: './mobile-account.component.html',
  styleUrls: ['./mobile-account.component.css'],
})
export class MobileAccountComponent implements OnInit {
  isRequesting = false;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  confirmUnsubscribe: string;
  unregisterEnabled = true;

  customer_title: string;
  first_name: string;
  middle_initial: string;
  last_name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  telephone: string;
  fax: string;
  customer_country: string;
  card_number: string;
  expiration_date: string;
  security_code: string;
  customer_profession: string;
  other_profession: string;
  school: string;
  customer_training: string;

  constructor(private userService: UserService, private globalVarsService: GlobalVarsService, private router: Router) {}

  ngOnInit() {
    var lastResponseString = window.sessionStorage.getItem('lastLoggedInResponse');
    console.log(lastResponseString);
    var lastResponse = JSON.parse(lastResponseString);
    this.customer_title = lastResponse.auth_info['title'];
    this.first_name = lastResponse.auth_info['first_name'];
    this.middle_initial = lastResponse.auth_info['middle_initial'];
    this.last_name = lastResponse.auth_info['last_name'];
    this.address = lastResponse.auth_info['address'];
    this.city = lastResponse.auth_info['city'];
    this.state = lastResponse.auth_info['state'];
    this.zip = lastResponse.auth_info['zip'];
    this.telephone = lastResponse.auth_info['phone'];
    this.fax = lastResponse.auth_info['fax'];
    this.customer_country = lastResponse.auth_info['country'];
    this.customer_profession = lastResponse.auth_info['profession'];
    this.other_profession = lastResponse.auth_info['specialty'];
    this.school = lastResponse.auth_info['school'];
    this.customer_training = lastResponse.auth_info['training'];
  }

  changePassword() {
    if (this.newPassword == null || this.newPassword.length < 6) {
      alert('Password must be at least 6 characters');
      return;
    }

    if (this.newPassword != this.newPasswordConfirmation) {
      alert('Password confirmation must match');
      return;
    }

    const op = this.oldPassword;
    const np = this.newPassword;

    this.oldPassword = null;
    this.newPassword = null;
    this.newPasswordConfirmation = null;

    this.isRequesting = true;
    this.userService.changePassword(op, np).subscribe({
      next: (result: Array<any>) => {
        console.log('Password reset completed!');
      },
      error: (result: any) => {
        console.log('password change failed!');
        console.log(JSON.stringify(result, null, 2));
        this.isRequesting = false;
      },
      complete: () => {
        this.isRequesting = false;
        console.log('done with change password');
      },
    });
  }

  updateProfile() {
    var userProperties = {
      customer_title: this['customer_title'],
      first_name: this['first_name'],
      middle_initial: this['middle_initial'],
      last_name: this['last_name'],
      address: this['address'],
      city: this['city'],
      state: this['state'],
      zip: this['zip'],
      phone: this['telephone'],
      fax: this['fax'],
      customer_country: this['customer_country'],
      card_number: this['card_number'],
      expiration_date: this['expiration_date'],
      security_code: this['security_code'],
      profession: this['customer_profession'],
      other_profession: this['other_profession'],
      school: this['school'],
      training: this['customer_training'],
    };

    var lastResponse = this.globalVarsService.lastLoggedInResponse['auth_info'];

    // this.userService.updateProfile(userProperties, lastResponse['user_id'], lastResponse['pwd']).subscribe({
    //   next(result: Array<any>) {
    //     if (result['result'] == 'success') {
    //       alert(result['message']);
    //     } else if (result['result'] == 'failure') {
    //       alert(result['message']);
    //     } else {
    //       alert(result['message']);
    //     }

    //     console.log(result);
    //   },
    //   complete() {
    //     console.log('done with update profile');
    //   },
    // });
  }

  unregisterUser() {
    if (this.confirmUnsubscribe) {
      const ptr = this;
      ptr.unregisterEnabled = false;
      const lastResponse = this.globalVarsService.lastLoggedInResponse['auth_info'];
      const router = this.router;

      console.log('router is:');
      console.dir(router);
      this.userService.unregister(lastResponse['user_id'], lastResponse['pwd']).subscribe({
        next(result: Array<any>) {
          if (result['result'] == 'success') {
            alert(result['message']);
            router.navigateByUrl('/landing');
          } else if (result['result'] == 'failure') {
            ptr.unregisterEnabled = true;
            alert(result['message']);
          } else {
            ptr.unregisterEnabled = true;
            alert(result['message']);
          }

          console.log(result);
        },
        complete() {
          console.log('you have been unsubscribed');
        },
      });
    } else {
      alert('Please select the checkbox');
    }
  }
}
