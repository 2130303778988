import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TokenStorageService } from "../services/token-storage.service";
import { StaticPagesService } from "../static-pages.service";

@Component({
  selector: "app-sitelicense",
  templateUrl: "./sitelicense.component.html",
  styleUrls: ["./sitelicense.component.css"],
})
export class SitelicenseComponent implements OnInit {
  content: SafeHtml;
  agreedToTermsAndConditions;

  constructor(
    private router: Router,
    private staticPages: StaticPagesService,
    private sanitizer: DomSanitizer,
    private _tokenStorageService: TokenStorageService
  ) {}

  transform(v: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v);
  }

  ngOnInit() {
    this.staticPages.getPageHtml("site_license_terms").then((content) => {
      this.content = this.transform(
        content
          .replace(/<body>/gi, "")
          .replace(/<\/body>/gi, "")
          .replace(/<html>/gi, "")
          .replace(/<\/html>/gi, "")
          .replace(/<br \/>/gi, "")
      );
    });
  }

  routeToPrograms() {
    if (this.agreedToTermsAndConditions) {
      this._tokenStorageService.saveAgreeToTerms(true);
      this.router.navigateByUrl("/programs");
    }
  }
}
