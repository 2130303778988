import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramRedirectGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authService.isAuthenticated().pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._router.parseUrl('/programs');
        } else {
          return true;
        }
      })
    );
  }
}
