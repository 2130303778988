import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { StaticPagesService } from "../static-pages.service";

@Component({
  selector: 'app-textbook',
  templateUrl: './textbook.component.html',
  styleUrls: ['./textbook.component.css'],
})
export class TextbookComponent implements OnInit {
  content: SafeHtml;

  constructor(private staticPages: StaticPagesService, private sanitizer: DomSanitizer) {}

  transform(v: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v);
  }

  ngOnInit() {
    this.staticPages.getPageHtml('textbook').then((content) => {
      this.content = this.transform(
        content
          .replace(/<body>/gi, '')
          .replace(/<\/body>/gi, '')
          .replace(/<html>/gi, '')
          .replace(/<\/html>/gi, '')
          .replace(/<br \/>/gi, ''),
      );
    });
  }
}
