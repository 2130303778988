import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AboutUsComponent } from './about-us/about-us.component';
import { AccountConfirmationCallbackComponent } from './account-confirmation-callback/account-confirmation-callback.component';
import { AccountComponent } from './account/account.component';
import { MobileAccountComponent } from './account/mobile-account/mobile-account.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MobileContactUsComponent } from './contact-us/mobile-contact-us/mobile-contact-us.component';
import { EbookSupportComponent } from './ebook-support/ebook-support.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth-guard';
import { ProgramRedirectGuard } from './guards/program-redirect-guard';
import { HelpComponent } from './help/help.component';
import { HowToUseComponent } from './how-to-use/how-to-use.component';
import { MobileHowToUseComponent } from './how-to-use/mobile-how-to-use/mobile-how-to-use.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProgramsComponent } from './programs/desktop-programs/programs.component';
import { MobileProgramsComponent } from './programs/mobile-programs/mobile-programs.component';
import { MobileRegisterComponent } from './register/mobile-register/mobile-register.component';
import { RegisterComponent } from './register/register.component';
import { MobileSimulationsComponent } from './simulations/mobile-simulations/mobile-simulations.component';
import { SimulationsComponent } from './simulations/simulations.component';
import { SitelicenseComponent } from './sitelicense/sitelicense.component';
import { StaticPagesComponent } from './static-pages/static-pages.component';
import { SupportEbookAdobeIdComponent } from './support-ebook-adobe-id/support-ebook-adobe-id.component';
import { SupportEbookDownloadComponent } from './support-ebook-download/support-ebook-download.component';
import { SupportEbookEreaderComponent } from './support-ebook-ereader/support-ebook-ereader.component';
import { SupportEbookNavComponent } from './support-ebook-nav/support-ebook-nav.component';
import { SupportLoginComponent } from './support-login/support-login.component';
import { SupportPlaybackComponent } from './support-playback/support-playback.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { MobileTextbookComponent } from './textbook/mobile-textbook/mobile-textbook.component';
import { TextbookComponent } from './textbook/textbook.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { VideoPlaybackComponent } from './video-playback/desktop-video-playback/video-playback.component';
import { MobileVideoPlaybackComponent } from './video-playback/mobile-video-playback/mobile-video-playback.component';

function getEnvSpecificComponent(desktopComponent: any, tabletComponent: any = null, mobileComponent: any = null) {
  switch (environment.envId) {
    case 'desktop':
      return desktopComponent;
    case 'tablet':
      return tabletComponent;
    case 'mobile':
      return mobileComponent;
    default:
      return null;
  }
}

const routes: Routes = [
  { path: '', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'account-email-verification', component: AccountConfirmationCallbackComponent },
  { path: 'landing', component: LandingPageComponent, canActivate: [ProgramRedirectGuard] },
  {
    path: 'videoPlayback',
    component: getEnvSpecificComponent(VideoPlaybackComponent, VideoPlaybackComponent, MobileVideoPlaybackComponent),
  },
  {
    path: 'programs',
    component: getEnvSpecificComponent(ProgramsComponent, ProgramsComponent, MobileProgramsComponent),
    canActivate: [AuthGuard],
  },
  { path: 'register', component: getEnvSpecificComponent(RegisterComponent, RegisterComponent, MobileRegisterComponent) },
  { path: 'help', component: HelpComponent },
  { path: 'textbook', component: getEnvSpecificComponent(TextbookComponent, TextbookComponent, MobileTextbookComponent) },
  { path: 'how-to-use', component: getEnvSpecificComponent(HowToUseComponent, HowToUseComponent, MobileHowToUseComponent) },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'faqs', redirectTo: '/static-pages?target=faqs' },
  { path: 'disclaimer', redirectTo: '/static-pages?target=disclaimer' },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact-us', component: getEnvSpecificComponent(ContactUsComponent, ContactUsComponent, MobileContactUsComponent) },
  { path: 'unsubscribe', component: UnsubscribeComponent, canActivate: [AuthGuard] },
  { path: 'account', component: getEnvSpecificComponent(AccountComponent, AccountComponent, MobileAccountComponent), canActivate: [AuthGuard] },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'contact-information', component: ContactInformationComponent },
  { path: 'mobile-login', component: MobileLoginComponent },
  { path: 'mobile-navigation', component: MobileNavigationComponent, canActivate: [AuthGuard] },
  { path: 'mobile-programs', redirectTo: '/programs', pathMatch: 'full' },
  { path: 'simulations', component: getEnvSpecificComponent(SimulationsComponent, SimulationsComponent, MobileSimulationsComponent), canActivate: [AuthGuard] },
  { path: 'ebook-support', component: EbookSupportComponent },
  { path: 'support-login', component: SupportLoginComponent },
  { path: 'support-playback', component: SupportPlaybackComponent },
  { path: 'support-ebook-nav', component: SupportEbookNavComponent },
  { path: 'support-ebook-adobe-id', component: SupportEbookAdobeIdComponent },
  { path: 'support-ebook-ereader', component: SupportEbookEreaderComponent },
  { path: 'support-ebook-download', component: SupportEbookDownloadComponent },
  { path: 'sitelicense', component: SitelicenseComponent },
  { path: 'static-pages', component: StaticPagesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
