<div>
  <!-- User Registration Info -->
  <h3>Registration Form - User Details</h3>

  <div class="instructions">Access to this website is limited to students and practitioners in the health professions.</div>

  <form [formGroup]="form">
    <div class="d-flex">
      <!-- Left Column -->
      <div class="d-flex flex-column flex-1-0-auto" style="max-width: 50%">
        <!-- Personal Info -->
        <div class="content-section">
          <div class="title-text header">Personal Information</div>
          <div class="content-section-content">
            <mat-form-field>
              <mat-label>Title</mat-label>
              <mat-select formControlName="title" required>
                <mat-option value="Mr.">Mr.</mat-option>
                <mat-option value="Mrs.">Mrs.</mat-option>
                <mat-option value="Ms.">Ms.</mat-option>
                <mat-option value="Miss">Miss</mat-option>
                <mat-option value="Dr.">Dr.</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput maxlength="35" formControlName="firstName" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Middle Initial</mat-label>
              <input matInput maxlength="1" formControlName="middleName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput maxlength="35" formControlName="lastName" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="birthDate" required />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address Line 1</mat-label>
              <input matInput maxlength="25" formControlName="address1" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address Line 2</mat-label>
              <input matInput maxlength="25" formControlName="address2" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>City</mat-label>
              <input matInput maxlength="35" formControlName="city" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label>State</mat-label>
              <input matInput maxlength="35" formControlName="state" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Zip / Postal Code</mat-label>
              <input matInput digitOnly maxlength="5" formControlName="zip" required />
            </mat-form-field>
            <app-country-select [required]="true" value="getCountry()" (valueChanged)="onCountryChanged($event)"></app-country-select>
            <mat-form-field>
              <mat-label>Telephone</mat-label>
              <input matInput maxlength="25" formControlName="telephone" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Fax Number</mat-label>
              <input matInput maxlength="25" formControlName="faxNumber" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Right Column -->
      <div class="d-flex flex-column flex-1-0-auto" style="max-width: 50%">
        <!-- Professional Info -->
        <div class="content-section">
          <div class="title-text header">Professional Classification</div>
          <div class="content-section-content"></div>
          <mat-form-field>
            <mat-label>Profession</mat-label>
            <mat-select name="customer_profession" formControlName="profession" required>
              <mat-option value="Medicine">Medicine (MD or DO)</mat-option>
              <mat-option value="Nurse (LPN, RN or NP)">Nurse (LPN, RN or NP)</mat-option>
              <mat-option value="Dentistry">Dentistry</mat-option>
              <mat-option value="Podiatry">Podiatry</mat-option>
              <mat-option value="Chiropractic">Chiropractic</mat-option>
              <mat-option value="Naturopathy">Naturopathy</mat-option>
              <mat-option value="Physician Assistant">Physician Assistant</mat-option>
              <mat-option value="Allied Health">Allied Health</mat-option>
              <mat-option value="EMT / Paramedic">EMT / Paramedic</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [class.cdk-visually-hidden]="!isOtherProfession()">
            <mat-label>If Other Profession, Please Explain</mat-label>
            <input matInput maxlength="35" formControlName="professionOther" [required]="isOtherProfession()" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Name of School</mat-label>
            <input matInput maxlength="35" formControlName="schoolName" required />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Level of Training</mat-label>
            <mat-select name="customer_training" formControlName="training" required>
              <mat-option value="Student">Student</mat-option>
              <mat-option value="Resident">Resident</mat-option>
              <mat-option value="Post-Graduate Student">Post-Graduate Student (MA, PhD)</mat-option>
              <mat-option value="Practitioner or Instructor">Practitioner or Instructor</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Specialty or Major</mat-label>
            <input matInput maxlength="35" formControlName="specialty" />
          </mat-form-field>
        </div>

        <!-- Account Info -->
        <div class="content-section">
        <div class="title-text header">Account Information</div>
        <div class="content-section-content">
            <mat-form-field>
              <mat-label>E-Mail Address</mat-label>
              <input matInput maxlength="255" formControlName="emailAddress" required />
              <mat-error *ngIf="hasError('emailAddress')">{{ getError('emailAddress') }}</mat-error>
            </mat-form-field>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <mat-hint *ngIf="this.form.controls['password'].value === ''"
              >Must be 8-200 characters in length, contain one uppercase, one lowercase, one number and one special character</mat-hint
            >
            <input matInput type="password" maxlength="200" formControlName="password" required />
            <mat-error *ngIf="hasError('password')">{{ getError('password') }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input matInput type="password" maxlength="200" formControlName="confirmPassword" required />
            <mat-error *ngIf="hasError('confirmPassword')">{{ getError('confirmPassword') }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Where You Heard About Us</mat-label>
            <mat-select formControlName="referral">
              <mat-option value="Friend or Relative">Friend or Relative</mat-option>
              <mat-option value="Other student, resident, colleague, etc.">Other student, resident, colleague, etc.</mat-option>
              <mat-option value="Magazine Ad">Magazine Ad</mat-option>
              <mat-option value="Search Engine">Search Engine</mat-option>
              <mat-option value="Link on Another Site">Link on Another Site</mat-option>
              <mat-option value="Ad on Another Site">Ad on Another Site</mat-option>
              <mat-option value="Article (Journal or Online)">Article (Journal or Online)</mat-option>
              <mat-option value="Found Site While Surfing">Found Site While Surfing</mat-option>
              <mat-option value="Our PDA Program">Our PDA Program</mat-option>
              <mat-option value="Email Ad or Link">Email Ad or Link</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        </div>

        <!-- E-Mail Preferences -->
        <div class="content-section">
          <div class="title-text header">E-Mail Preferences</div>
          <div class="content-section-content"></div>
          <div>
            <mat-checkbox color="primary" formControlName="emailUpdates">
              <div class="title-text" style="display: inline">Site Updates</div>
            </mat-checkbox>
          </div>
          <p class="indented" style="margin-top: 4px; margin-bottom: 16px">
            Occasionally, Medical Media Systems will want to contact you regarding special offers, changes to our website, or other updates. To receive these updates, please check
            the box.
          </p>

          <div>
            <mat-checkbox color="primary" formControlName="emailOptIn">
              <div class="title-text" style="display: inline">Opt-In</div>
            </mat-checkbox>
          </div>
          <p class="indented" style="margin-top: 4px; margin-bottom: 16px">
            Occasionally, Medical Media Systems will share our client list with companies and groups that will be of interest to you. To receive special offers and information from
            these companies and groups, please check the box.
          </p>
        </div>
      </div>
    </div>

    <!-- Centered -->
    <div class="d-flex flex-column">
      <div class="content-section">
        <div class="title-text header">Terms and Conditions</div>
        <div class="content-section-content">
          <div style="border: solid 1px black; max-height: 200px; margin-bottom: 8px; overflow: scroll" [innerHtml]="content"></div>
          <div class="center">
            <mat-checkbox color="primary" formControlName="acceptedTermsAndConditions" required>
              <span>I have read and agree to accept the Terms & Conditions as detailed above.</span>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="center">
        <re-captcha #recaptcha (resolved)="handleCorrectCaptcha($event)" type="image"></re-captcha>
      </div>
    </div>
  </form>
</div>
