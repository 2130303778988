import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from '../../contact.service';
import { GlobalVarsService } from '../../global-vars.service';

@Component({
  selector: 'app-mobile-contact-us',
  templateUrl: './mobile-contact-us.component.html',
  styleUrls: ['./mobile-contact-us.component.css'],
})
export class MobileContactUsComponent {
  firstName: string;
  lastName: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  profession: string;
  professionOther: string;
  training: string;
  specialty: string;
  message: string;
  recaptchaSucceeded = false;

  showSubmitContactButton = true;

  constructor(private contactService: ContactService, private globalVarsService: GlobalVarsService, private router: Router) {
    var lastResponse = globalVarsService.lastLoggedInResponse;
    if ('auth_info' in lastResponse && 'first_name' in lastResponse['auth_info']) {
      this.firstName = lastResponse['auth_info']['first_name'];
      this.lastName = lastResponse['auth_info']['last_name'];
      this.zip = lastResponse['auth_info']['zip'];
      this.country = lastResponse['auth_info']['country'];
      this.phone = lastResponse['auth_info']['phone'];
      this.email = lastResponse['auth_info']['email'];
      this.profession = lastResponse['auth_info']['profession'];
      this.training = lastResponse['auth_info']['training'];
      this.specialty = lastResponse['auth_info']['specialty'];
    }
  }

  handleCorrectCaptcha($event) {
    console.log(JSON.stringify($event));
    this.recaptchaSucceeded = true;
  }

  doContact(): void {
    if (this.recaptchaSucceeded === false) {
      alert('Recaptcha Failed. Please try again.');
      return;
    }

    var validationTargets = [
      { fieldName: 'firstName', message: 'First Name' },
      { fieldName: 'lastName', message: 'Last Name' },
      { fieldName: 'country', message: 'Country' },
      { fieldName: 'email', message: 'Email' },
      { fieldName: 'message', message: 'Message' },
    ];
    for (var validationTarget of validationTargets) {
      if (this[validationTarget.fieldName]) {
      } else {
        alert(validationTarget.message + ' is missing');
        return;
      }
    }

    var contactObject = {};
    var contactFields = ['firstName', 'lastName', 'zip', 'country', 'phone', 'email', 'profession', 'professionOther', 'training', 'specialty', 'message'];
    for (var field of contactFields) {
      contactObject[field] = this[field];
    }

    this.showSubmitContactButton = false;

    var thisPtr = this;
    // this.contactService.sendContactRequest(contactObject).subscribe({
    //   next(result: Array<any>) {
    //     if (result['email_result'] == 'success') {
    //       alert(result['message']);
    //       thisPtr.router.navigateByUrl('/landing');
    //     } else {
    //       alert(result['message']);
    //       thisPtr.showSubmitContactButton = true;
    //     }

    //     console.log(result);
    //   },
    //   complete() {
    //     console.log('done with contact request');
    //   },
    // });
  }
}
