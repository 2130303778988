import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalVarsService } from '../global-vars.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.css'],
})
export class MobileNavigationComponent {
  constructor(private userService: UserService, private globalVarsService: GlobalVarsService, private router: Router, private location: Location) {}

  isLoggedIn(): boolean {
    return this.globalVarsService.isLoggedIn;
  }

  logout(): void {
    this.userService.logout();
    this.globalVarsService.lastLoggedInResponse = {};
    this.globalVarsService.isLoggedIn = false;

    window.setTimeout(() => this.router.navigateByUrl('/'), 500);
  }

  goBack(): void {
    this.location.back();
  }
}
