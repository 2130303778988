<h1 mat-dialog-title>Verification Required</h1>

<div mat-dialog-content>
  <p>An email has been sent to {{data.emailAddress}} with a verification code.  Please check your email and enter that code below to continue.</p>
  <em>This code is only valid for 15 minutes.</em>

  <div class="content-section-content">
    <mat-form-field appearance="standard">
      <mat-label>Verification Code</mat-label>
      <input matInput maxlength="6" [(ngModel)]="verificationCode" (keyup.enter)="buttonClicked()" />
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="secondary" (click)="cancelClicked()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="isDisabled()" (click)="buttonClicked()">Verify</button>
</div>
