import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContentBalloonComponent } from '../content-balloon/content-balloon.component';
import { RegistrationRequest, RegistrationStep } from '../model/mms.model';
import { SubscriptionService } from '../services/subscription.service';
import { StaticPagesService } from '../static-pages.service';
import { UserService } from '../user.service';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation-step.component';
import { PaymentComponent } from './payment/payment-step.component';
import { SubscriptionSelectionComponent } from './subscription-selection/subscription-selection-step.component';
import { UserDetailsComponent } from './user-details/user-details-step.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements AfterViewInit {
  @ViewChild('cb') cb: ContentBalloonComponent;
  @ViewChild('subscriptionSelection') subscriptionStep: SubscriptionSelectionComponent;
  @ViewChild('userDetails') userDetailsStep: UserDetailsComponent;
  @ViewChild('emailConfirmation') emailConfirmationStep: EmailConfirmationComponent;
  @ViewChild('payment') paymentStep: PaymentComponent;
  @ViewChild('stepper') stepper: MatStepper;

  emailValidationRequired = true;

  showOverlay = false;
  overlayText = '';
  forceDisableSubmit = false;

  maxHeight = '100px';

  registrationRequest: RegistrationRequest = {
    subscriptionInfo: {},
    userDetails: {},
    paymentDetails: {},
  };

  private _windowHeight;

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private userService: UserService,
    private staticPages: StaticPagesService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private _subscriptionService: SubscriptionService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastr: ToastrService
  ) {}

  ngAfterViewInit(): void {
    this.cb.maxHeightChanged.subscribe(maxHeight => {
      const val = parseInt(maxHeight.replace('px', ''));
      this.maxHeight = val - 66 + 'px';
      this._changeDetectorRef.detectChanges();
    });
    this.stepper.selectionChange.subscribe(this.onStepperChanged.bind(this));
  }

  requiresPayment(): boolean {
    const subscriptionCode = this.registrationRequest?.subscriptionInfo.subscriptionOptionCode;

    return !(subscriptionCode !== null && subscriptionCode !== undefined && subscriptionCode.length > 0);
  }

  showClearButton(): boolean {
    const step = this._getCurrentStep();

    return step?.showClearButton ?? false;
  }

  showPreviousButton(): boolean {
    const step = this._getCurrentStep();

    return step?.showPreviousButton ?? false;
  }

  showNextButton(): boolean {
    const step = this._getCurrentStep();

    return step?.showNextButton ?? false;
  }

  showSubmitButton(): boolean {
    const step = this._getCurrentStep();

    return step?.showSubmitButton ?? false;
  }

  onClear() {
    const step = this._getCurrentStep();

    step?.clear?.();
  }

  onPrevious() {
    this.stepper.previous();
  }

  onNext() {
    const step = this._getCurrentStep();

    console.log('step change');
    console.log(JSON.stringify(this.registrationRequest, null, 2));

    if (step?.preNext) {
      step.preNext().subscribe((response) => {
        console.log('got response:');
        console.log(JSON.stringify(response, null, 2));
        if (response) {
          this.stepper.next();
        } else {
          console.log('OOP!!!  next failed!');
        }
      });
    } else {
      this.stepper.next();
    }
  }

  onStepperChanged(event) {
    console.log('change event');
    console.dir(event);
    const step = this._getStep(event.selectedIndex);

    if (step) {
      if (event.previouslySelectedIndex !== null && event.previouslySelectedIndex !== undefined) {
        this._updateStepData(event.previouslySelectedIndex);
      }

      console.log(`${event.selectedIndex}`);
      console.log('calling onActivate if it exists...');
      console.log('exists: ' + (step.onActivate !== undefined && step.onActivate !== null));
      step?.onActivate?.();
    } else {
      console.log('could not find next step');
    }
  }

  isNextDisabled(): boolean {
    const step = this._getCurrentStep();

    return step?.isNextDisabled?.() ?? true;
  }

  isSubmitDisabled(): boolean {
    const step = this._getCurrentStep();

    return step?.isSubmitDisabled?.() ?? true;
  }

  onSubmit() {
    const step = this._getCurrentStep();
    console.log('submit clicked!');
    this._updateStepData(this.stepper.selectedIndex);
    console.log(JSON.stringify(this.registrationRequest, null, 2));
    this.forceDisableSubmit = true;

    if (step?.preSubmit) {
      step.preSubmit().subscribe((response) => {
        if (response) {
          this._doRegister();
        } else {
          this._toastr.error("Email address validation failed, please try again");
        }
      });
    } else {
      this._doRegister();
    }
  }

  proceedToPayment() {
    if (this.stepper?.selectedIndex === 2) {
      this.onNext();
    }
  }

  onDataTokenUpdated(response: string): void {
    this.registrationRequest.dataToken = response;
  }

  private _doRegister() {
    const comp = this;

    this._subscriptionService.register(this.registrationRequest).subscribe({
      next(response)  {
        if (response.success) {
          comp._toastr.info('Thank you for registering, a confirmation email has been sent.');
          setTimeout(() => comp.router.navigateByUrl('/landing'), 2000);
        } else {
          comp._toastr.error(response?.message || response);
        }

        comp.forceDisableSubmit = false;
      },
      error(response) {
        comp._toastr.error(response.error);
        comp.forceDisableSubmit = false;
      }
    });
  }

  private _getCurrentStep(): RegistrationStep {
    return this._getStep(this.stepper?.selectedIndex);
  }

  private _getStep(index): RegistrationStep {
    switch (index) {
      case 0:
        return this.subscriptionStep;
      case 1:
        return this.userDetailsStep;
      case 2:
        return this.paymentStep;
      default:
        return null;
    }
  }

  private _updateStepData(index): void {
    console.log('updating step data.');
    switch (index) {
      case 0:
        this.registrationRequest.subscriptionInfo = this.subscriptionStep.getSubscriptionInfo();
        break;
      case 1:
        this.registrationRequest.userDetails = this.userDetailsStep.getUserDetails();
        break;
      case 2:
        this.registrationRequest.paymentDetails = this.paymentStep.getPaymentDetails();
        break;
    }

    this._changeDetectorRef.detectChanges();
    console.log(JSON.stringify(this.registrationRequest, null, 2));
  }
}
