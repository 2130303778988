<div *ngIf="canAutoplay !== undefined" [style]="containerStyle">
    <div *ngIf="showVideo && !pageItem.imageLink && !pageItem.pdfLink" style="width: 720px; height: 540px; top: -10px">
      <iframe [attr.allow]="getAllow()" [src]="frameUrl" width="100%" height="100%" frameborder="0" scrolling="auto" style="position:relative"></iframe>
    </div>
    <div *ngIf="!showVideo" style="width: 720px; height: 540px; background-color: #111;">
      <img src="/images/main_video_window.png" width="720px" height="540px" />
    </div>
    <div *ngIf="showVideo && pageItem.imageLink" style="width: 720px; height: 540px; background-color: #111">
      <img [src]="pageItem.imageLink" width="720px" height="540px" />
    </div>
    <div *ngIf="showVideo && pageItem.pdfLink" style="width: 720px; height: 540px; background-color: #111;">
      <iframe [src]="pdfUrl" width="100%" height="100%" frameBorder="0" scrolling="auto" style="position:relative"></iframe>
    </div>
</div>

<!-- Detecting if autoplay is available -->
<div *ngIf="canAutoplay === undefined" style="height: 100%">
  <div style="display: flex; flex-direction: column; height: 100%; justify-content: center;">
    <div style="flex: 0 0 auto; width: 200px; margin-left: auto; margin-right: auto; text-align: center; padding: 32px; border: solid 3px darkslategray; color: white; border-radius: 10px;">
      Detecting Video Capabilities.
    </div>
  </div>
</div>
