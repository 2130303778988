import { Component, HostBinding, Inject, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import canAutoplay from 'can-autoplay';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MmslogService } from "../mmslog.service";
import { PageItem } from "../model/mms.model";
import { AuthService } from "../services/auth.service";
import { TokenStorageService } from "../services/token-storage.service";

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html'
})
export class PlayerComponent implements OnInit {
  @Input()
  public startFullscreen = false;

  @Input()
  public autoplay = true;

  @Input()
  public get pageItem() {
    return this._pageItem;
  }

  public set pageItem(val: PageItem) {
    console.log('setting page item...');
    this._pageItem = val;
    this._play();
  }

  @Input()
  public get width() {
    return this._width;
  };

  public set width(val: number) {
    this._width = val;
    this.containerStyle = `flex:0 0 ${this._width}px; background-color: black; color: white;`;
  }

  @Input()
  public get height() {
    return this._height;
  }

  public set height(val: number) {
    this._height = val;
  }

  containerStyle: string;
  canAutoplay: boolean = null;
  frameUrl: SafeResourceUrl = null;
  pdfUrl: SafeResourceUrl = null;
  showVideo = false;

  @HostBinding("style.flex")
  private _flex = "0 0 720px";

  private _width = 720;
  private _height = 540;
  private _pageItem: PageItem;

  @HostBinding("style.background-color")
  private backgroundColor = "#111";

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private _mmsLogService: MmslogService,
    private _sanitizer: DomSanitizer,
    private _tokenStorageService: TokenStorageService,
    private _authService: AuthService,
  ) {}

  ngOnInit() {
    this._detectAutoplay();
  }

  getAllow(): String {
    if (this.autoplay && this.startFullscreen) {
      return 'autoplay; fullscreen';
    } else if (this.autoplay) {
      return 'autoplay';
    } else if (this.startFullscreen) {
      return 'fullscreen';
    }
  }

  private _play(): void {
    this.showVideo = false;
    this.frameUrl = undefined;

    if (this._pageItem) {
      this._detectAutoplay().then(() => {
        const autoplay = this.autoplay === true && this.canAutoplay === true;

        this._getToken().subscribe(token => {
          const tokenStr = token ? `&token=${token}` : '';
          const url = `${this.baseUrl}videoPlayer?pageId=${this._pageItem.pageId}&pageItemId=${this._pageItem.id}&fullscreen=${this.startFullscreen}&autoplay=${autoplay}${tokenStr}`;
          this.frameUrl = this._transformUrl(url);
          this.pdfUrl = this._transformUrl(`${this.baseUrl}${this._pageItem.pdfLink}`);
          this.showVideo = true;
          this._logVideoPlayback(this._pageItem);
        });
      });
    }
  }

  private _logVideoPlayback(pageItem: any) {
      this._mmsLogService.postVideoLog(pageItem.id).subscribe();
  }

  private async _detectAutoplay(): Promise<any> {
    // Hack to force iPads & iPhones to not autoplay without sound - requires click.
    if (navigator.platform === 'MacIntel' && navigator.userAgent.lastIndexOf('Safari') >= 0 && navigator.maxTouchPoints > 1) {
      // this is appears to be a mac tablet, don't allow autoplay due to Apple not following web standards.
      this.canAutoplay = false;

      return;
    }

    // Reset flag to indicate testing is in progress.
    this.canAutoplay = undefined;

    return canAutoplay.audio().then(({ result }) => {
      console.log('can autoplay?: ' + result);
        this.canAutoplay = result;
    });
  }

  private _getToken(): Observable<string> {
    return this._authService.isAuthenticated().pipe(
      map(isAuthenticated => {
        return isAuthenticated ? this._tokenStorageService.getToken() : null;
      })
    );
  }

  private _transformUrl(v: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(v);
  }
}
