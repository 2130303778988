import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVarsService } from '../global-vars.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.css'],
})
export class MobileLoginComponent implements OnInit {
  lastLoggedInResponse;
  isLoggedIn = false;
  username = '';
  password = '';

  staySignedIn;

  constructor(@Inject('BASE_URL') private baseUrl, private userService: UserService, private router: Router, private globalVarsService: GlobalVarsService) {}

  ngOnInit() {
    this.userService.checkAuth().subscribe((result: Array<any>) => {
      if (result['auth_result'] == 'success') {
        this.isLoggedIn = true;
        this.lastLoggedInResponse = result;
        this.globalVarsService.lastLoggedInResponse = result;
        this.globalVarsService.isLoggedIn = true;

        if ('auth_method' in result && result['auth_method'] == 'ip') {
          this.globalVarsService.authMethod = 'ip';

          if (window.sessionStorage.getItem('lastLoggedInResponse') == null) {
            window.setTimeout(function () {
              this.router.navigateByUrl('/sitelicense');
            }, 1000);
          }
        }
      }
    });
  }

  isDefined<T>(a: T | null | undefined): a is T {
    return a !== null && a !== undefined;
  }

  navigate(option: string): void {
    this.router.navigateByUrl('/' + option);
  }

  clearMenuSelection(): void {}

  login(): void {
    this.userService.authenticateUser(this.username, this.password, this.staySignedIn).subscribe((result: Array<any>) => {
      if (result['auth_result'] == 'success') {
        this.isLoggedIn = true;
        this.lastLoggedInResponse = result;
        this.globalVarsService.lastLoggedInResponse = result;
        this.globalVarsService.isLoggedIn = true;
        this['programsCss'] = 'white-text-hover';

        window.setTimeout(() => this.router.navigateByUrl('/mobile-programs'), 1000);
      } else if (result['auth_result'] == 'failure') {
        alert('Login Failed. Please try again.');
      } else {
        alert('Something went wrong with login. Please try again.');
      }
    });
  }

  logout(): void {
    this.userService.logout();
    this.isLoggedIn = false;
    this.lastLoggedInResponse = null;
    this.globalVarsService.lastLoggedInResponse = {};
    this.globalVarsService.isLoggedIn = false;
    this.username = null;
    this.password = null;
    var ptr = this;
    window.setTimeout(function () {
      ptr.router.navigateByUrl('/');
    }, 500);
  }
}
