import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { StaticPagesService } from "../static-pages.service";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.css"],
})
export class HelpComponent implements OnInit {
  content: SafeHtml;

  constructor(
    private staticPages: StaticPagesService,
    private sanitizer: DomSanitizer
  ) {}

  transform(v: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v);
  }

  ngOnInit() {
    this.staticPages.getPageHtml("help").then((content) => {
      this.content = this.transform(
        content
          .replace(/<body>/gi, "")
          .replace(/<\/body>/gi, "")
          .replace(/<html>/gi, "")
          .replace(/<\/html>/gi, "")
          .replace(/<br \/>/gi, "")
      );
    });
  }
}
