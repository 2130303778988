<div class="full-footer">
  <div class="grouping">
    <ng-template *ngIf="!globalVarsService.isLoggedIn">
      <a [routerLink]="['/register']" class="hlinkw">Subscribe</a>
      <span class="splitter">/</span>
      <a [routerLink]="['/videoPlayback']" class="hlinkw">Demo</a>
      <span class="splitter">/</span>
    </ng-template>
    <ng-template *ngIf="!!globalVarsService.isLoggedIn">
      <a [routerLink]="['/programs']" class="hlinkw">Programs</a>
      <span class="splitter">/</span>
    </ng-template>
    <a [routerLink]="['/how-to-use']" class="hlinkw">How To Use</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/about-us']" class="hlinkw">About Us</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/faqs']" class="hlinkw">FAQs</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/contact-us']" class="hlinkw">Contact Us</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/']" class="hlinkw">Home</a>
  </div>

  <div>
    <div class="grouping">
      <span class="copyright">© {{ getYear() }} Medical Media Systems. All Rights Reserved.</span>
      <a [routerLink]="['/disclaimer']">Disclaimer</a>
      <span class="splitter">/</span>
      <a [routerLink]="['/privacy']">Privacy Policy</a>
    </div>
  </div>
</div>

<div class="small-footer footer">
  <div class="grouping center">
    <a [routerLink]="['/how-to-use']" class="hlinkw">How To Use</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/faqs']" class="hlinkw">FAQs</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/contact-us']" class="hlinkw">Contact Us</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/about-us']" class="hlinkw">About Us</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/disclaimer']">Disclaimer</a>
    <span class="splitter">/</span>
    <a [routerLink]="['/privacy']">Privacy Policy</a>
  </div>

  <div class="grouping center">
    <span class="copyright">© {{ getYear() }}3 Medical Media Systems. All Rights Reserved.</span>
  </div>
</div>
