import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ccExp]',
})
export class CcExpDirective {
  private navigationKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste'];
  inputElement: HTMLInputElement;
  regex: RegExp = new RegExp(/^(0?[1-9]|1[0-2])?\/?(\d{0,2})?$/);

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }

    let newValue = this.forecastValue(e.key);

    if (!this.regex.test(newValue)) {
      e.preventDefault();

      return;
    }

    // 4 characters, no slash
    if (newValue.indexOf('/') === -1 && newValue.length === 4) {
      // Add slash
      newValue = `${newValue.substring(0, 2)}/${newValue.substring(2, 3)}`;

      // update input with new value
      this.inputElement.value = newValue;
      return;
    }

    if (newValue.indexOf('/') === -1 && newValue.length === 3 && newValue[0] !== '0') {
        // Add slash
        newValue = `${newValue.substring(0, 1)}/${newValue.substring(1, 2)}`;

        // update input with new value
      this.inputElement.value = newValue;
      return;
    }
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart ?? 0;
    const selectionEnd = this.inputElement.selectionEnd ?? 0;
    let oldValue = this.inputElement.value;//.replace('/', '');

    // Remove 0 padding for validation.
    //if (oldValue.startsWith('0')) {
    //  oldValue = oldValue.substring(1);
    //}

    const selection = oldValue.substring(selectionStart, selectionEnd);
    return selection ? oldValue.replace(selection, key) : oldValue.substring(0, selectionStart) + key + oldValue.substring(selectionStart);
  }
}
