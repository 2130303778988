<div style="width: 100%; background-color: white">
  <mat-nav-list *ngIf="!showVideoList">
    <mat-list-item class="d-flex flex-column">
      <div class="menu-title">
        <a *ngIf="!showTopMenu" (click)="goBack()"><mat-icon aria-hidden="false" aria-label="back">chevron_left</mat-icon></a>
      </div>
      <div class="center flex-1-1-auto">{{ topLevelMenuTitle }}</div>
      <div class="menu-action">
        <a [routerLink]="['/landing']"> <mat-icon aria-hidden="false" aria-label="close">close</mat-icon></a>
      </div>
    </mat-list-item>

    <div *ngIf="showTopMenu">
      <a mat-list-item *ngFor="let menuItem of menuHierarchy">
        <div class="d-flex flex-row" *ngIf="menuItem.title.trim() !== ''" style="flex: 1 0 auto">
          <div class="menu-title" (click)="selectCurrentMenu(menuItem)">
            <span>{{ menuItem.title }}</span>
          </div>
          <div class="menu-action" (click)="selectCurrentMenu(menuItem)"><mat-icon aria-hidden="false" aria-label="watch video">chevron_right</mat-icon></div>
        </div>
      </a>
    </div>

    <div *ngIf="!showTopMenu">
      <a mat-list-item *ngFor="let menuItem of currentMenuHierarchy">
        <div class="d-flex flex-row" *ngIf="menuItem.title.trim() !== '' && menuItem.subMenus.length > 0" style="flex: 1 0 auto">
          <div class="menu-title" (click)="selectCurrentMenu(menuItem)">
            <span>{{ menuItem.title }}</span>
          </div>
          <div class="menu-action" (click)="selectCurrentMenu(menuItem)"><mat-icon aria-hidden="false" aria-label="watch video">chevron_right</mat-icon></div>
        </div>

        <div *ngIf="menuItem.title.trim() !== '' && menuItem.subMenus.length < 1" style="flex: 1 0 auto">
          <div class="menu-title" (click)="displayVideoList(menuItem)">
            <span>{{ menuItem.title }}</span>
          </div>
        </div>
      </a>
    </div>
  </mat-nav-list>

  <div *ngIf="showVideoList">
    <div class="d-flex flex-row" style="margin-top: 8px">
      <div class="menu-title">
        <a style="cursor: pointer" (click)="this.showVideoList = false; this.playerVisible = false; this.showVideo = false"
          ><mat-icon style="padding-top: 6px; padding-left: 4px" aria-hidden="false" aria-label="back">chevron_left</mat-icon></a
        >
      </div>
      <div class="video-menu-title" style="width: 300px">{{ currentVideoMenuTitle }}</div>
      <div class="menu-action"></div>
    </div>

    <div class="d-flex flex-column">
      <iframe
        *ngIf="playerVisible"
        title="video-playback"
        style="border: 0px solid black; margin-left: auto; margin-right: auto"
        [src]="jsVideoLink"
        width="90%"
        height="220"
        allowFullScreen="true"></iframe>
      <div class="video-menu-columns">
        <div *ngFor="let aColumn of columnList" class="video-menu-column">{{ aColumn.title }}</div>
      </div>
      <div style="display: flex; flex-direction: column; min-height: min-content; margin-bottom: 16px; overflow-y: auto">
        <div *ngFor="let pageItem of organizedPageItems" class="video-menu">
          <div [ngStyle]="{ width: pageItem.right !== null ? '70%' : '100%' }">
            <span [innerHTML]="outputNbsps(pageItem.left.indent_level)"></span>
            <a [class.hlinkb]="pageItem.left.available_in_demo === '1'" (click)="playVideo(pageItem.left)" style="cursor: pointer"
              ><span [innerHtml]="transformHtml(pageItem.left.title)"></span
            ></a>
          </div>
          <div *ngIf="pageItem.right !== null" style="width: 30%" class="center">
            <a [class.hblink]="pageItem.left.available_in_demo === '1'" (click)="playVideo(pageItem.right)" style="cursor: pointer"
              ><img alt="dot" src="/images/dot1.gif" /> <span *ngIf="pageItem.right.duration">({{ pageItem.right.duration }})</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
