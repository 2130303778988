<app-content-balloon width="80vw">
  <div class="account-section">
    <div class="title-text header">Contact Information</div>

    <div class="d-flex flex-column indented">
      <div class="d-flex flex-row">
        <div class="account-cell-left">New Email:</div>
        <div class="account-cell-right"><input /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">New Password:</div>
        <div class="account-cell-right"><input [(ngModel)]="newPassword" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Confirm New Password:</div>
        <div class="account-cell-right"><input [(ngModel)]="newPasswordConfirmation" /></div>
      </div>
      <div class="d-flex flex-row center" style="padding-top: 16px">
        <input type="button" value="UPDATE" (click)="changePassword()" style="background-color: #efefef; font-size: 10px" />
      </div>
    </div>
  </div>

  <div class="account-section">
    <div class="title-text header">Update Profile</div>

    <div class="d-flex flex-column indented">
      <div class="d-flex flex-row">
        <div class="account-cell-left">Title</div>
        <div class="account-cell-right">
          <select [(ngModel)]="customer_title" style="width: 138px">
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Ms.">Ms.</option>
            <option value="Miss">Miss</option>
            <option value="Dr.">Dr.</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">First Name</div>
        <div class="account-cell-right"><input [(ngModel)]="first_name" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Middle Initial</div>
        <div class="account-cell-right"><input [(ngModel)]="middle_initial" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Last Name</div>
        <div class="account-cell-right"><input [(ngModel)]="last_name" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Address</div>
        <div class="account-cell-right"><input [(ngModel)]="address" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">City</div>
        <div class="account-cell-right"><input [(ngModel)]="city" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">State</div>
        <div class="account-cell-right"><input [(ngModel)]="state" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Zip Code</div>
        <div class="account-cell-right"><input [(ngModel)]="zip" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Telephone</div>
        <div class="account-cell-right"><input [(ngModel)]="telephone" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Fax</div>
        <div class="account-cell-right"><input [(ngModel)]="fax" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Country</div>
        <div class="account-cell-right">
          <select [(ngModel)]="customer_country" style="width: 138px">
            <option value="US" selected>United States</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Ashmore and Cartier Islands">Ashmore and Cartier Islands</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas, The">Bahamas, The</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Baker Island">Baker Island</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Bassas da India">Bassas da India</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
            <option value="British Virgin Islands">British Virgin Islands</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burma">Burma</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Clipperton Island">Clipperton Island</option>
            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
            <option value="Congo, Republic of the">Congo, Republic of the</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Coral Sea Islands">Coral Sea Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote d'Ivoire">Cote d'Ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Europa Island">Europa Island</option>
            <option value="Falkland Islands (Islas Malvinas)">Falkland Islands (Islas Malvinas)</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia, The">Gambia, The</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Glorioso Islands">Glorioso Islands</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guernsey">Guernsey</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Holy See (Vatican City)">Holy See (Vatican City)</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Howland Island">Howland Island</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indian Ocean">Indian Ocean</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Jan Mayen">Jan Mayen</option>
            <option value="Japan">Japan</option>
            <option value="Jarvis Island">Jarvis Island</option>
            <option value="Jersey">Jersey</option>
            <option value="Johnston Atoll">Johnston Atoll</option>
            <option value="Jordan">Jordan</option>
            <option value="Juan de Nova Island">Juan de Nova Island</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kingman Reef">Kingman Reef</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, South">Korea, South</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macau">Macau</option>
            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Man, Isle of">Man, Isle of</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
            <option value="Midway Islands">Midway Islands</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Navassa Island">Navassa Island</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pacific Ocean">Pacific Ocean</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palmyra Atoll">Palmyra Atoll</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paracel Islands">Paracel Islands</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn Islands">Pitcairn Islands</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
            <option value="Southern Ocean">Southern Ocean</option>
            <option value="Spain">Spain</option>
            <option value="Spratly Islands">Spratly Islands</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard">Svalbard</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tromelin Island">Tromelin Island</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Virgin Islands">Virgin Islands</option>
            <option value="Wake Island">Wake Island</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="account-section">
    <div class="title-text header">Professional Classification</div>

    <div class="d-flex flex-column indented">
      <div class="d-flex flex-row">
        <div class="account-cell-left">Profession</div>
        <div class="account-cell-right">
          <select [(ngModel)]="customer_profession" style="width: 138px">
            <option value="Medicine" selected>Medicine (MD or DO)</option>
            <option value="Nursing">Nursing</option>
            <option value="Dentistry">Dentistry</option>
            <option value="Podiatry">Podiatry</option>
            <option value="Chiropractic">Chiropractic</option>
            <option value="Naturopathy">Naturopathy</option>
            <option value="Allied Health">Allied Health</option>
            <option value="EMT / Paramedic">EMT / Paramedic</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">If Other Profession, Please Explain</div>
        <div class="account-cell-right"><input [(ngModel)]="other_profession" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">School</div>
        <div class="account-cell-right"><input [(ngModel)]="school" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="account-cell-left">Level of Training</div>
        <div class="account-cell-right">
          <select [(ngModel)]="customer_training" style="width: 138px">
            <option value="Student">Student</option>
            <option value="Resident">Resident</option>
            <option value="Post-Graduate Student">Post-Graduate Student (MA, PhD)</option>
            <option value="Practitioner or Instructor" selected>Practitioner or Instructor</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row center" style="padding-top: 16px">
        <input type="button" value="UPDATE" (click)="updateProfile()" style="background-color: #efefef; font-size: 12px" />
      </div>
    </div>
  </div>

  <div class="account-section">
    <div class="title-text header">Unsubscribe</div>

    <input type="checkbox" [(ngModel)]="confirmUnsubscribe" /><span style="font-size: 10px"
      >Please check this box and click "Unsubscribe" to confirm you wish to cancel your automatic recurrent billing subscription. Sorry, but partial subscription periods cannot be
      refunded.</span
    >

    <div class="d-flex flex-column">
      <div class="d-flex flex-row center" style="padding-top: 16px; font-size: 10px">
        <input *ngIf="unregisterEnabled" type="button" value="UNSUBSCRIBE" style="background-color: #efefef; font-size: 10px" (click)="unregisterUser()" />
        <span *ngIf="!unregisterEnabled">Processing ...</span>
      </div>
    </div>
  </div>

  <div class="account-section">
    <div class="title-text header">E-mail Services</div>

    <input type="checkbox" />
    <div class="title-text" style="display: inline; font-size: 12px">Site Updates</div>
    <p class="indented" style="margin-top: 4px; margin-bottom: 32px; font-size: 10px">
      Occasionally, Medical Media Systems will want to contact you regarding special offers, changes to our website, or other updates. To receive these updates, please check the
      box.
    </p>

    <input type="checkbox" />
    <div class="title-text" style="display: inline; font-size: 12px">Opt-In</div>
    <p class="indented" style="margin-top: 4px; margin-bottom: 16px; font-size: 10px">
      Occasionally, Medical Media Systems will share our client list with companies and groups that will be of interest to you. To receive special offers and information from these
      companies and groups, please check the box.
    </p>
  </div>
</app-content-balloon>
