import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Customer, IpCompany, Simulation } from '../model/mms.model';
import { AuthService } from '../services/auth.service';
import { SchoolsService } from '../services/schools.service';
import { SimulationsService } from '../services/simulations.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-simulations',
  templateUrl: './simulations.component.html',
  styleUrls: ['./simulations.component.css'],
})
export class SimulationsComponent implements OnInit {
  form: FormGroup = null;
  showSimulationLoading = false;
  alreadyHaveUserInfo = false;
  simulationsList: Array<Simulation>;
  schoolChoice;

  private _company: IpCompany = null;

  constructor(
    private _simulationsService: SimulationsService,
    private _schoolsService: SchoolsService,
    private _userService: UserService,
    private _toast: ToastrService,
    private _formBuilder: FormBuilder,
    private _authService: AuthService
  ) {
    this.form = _formBuilder.group({
      firstName: [],
      lastName: [],
      emailAddress: ['', [Validators.email]]
    });
   }

  ngOnInit() {
    this.loadSimulationsList();

    this._authService.loginStatusChanged.subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        this._userService.getLoggedInUser().subscribe(customer => this._populateFromUser(customer));
      } else {
        this._populateFromUser(null);
      }
    });

    if (this._authService.isIpAuth()) {
      this._authService.getCompany().subscribe(company => {
        this.schoolChoice = company.company;
      });
    } else {
      this._userService.getLoggedInUser().subscribe((customer) => this._populateFromUser(customer));
    }
  }

  generatePlayerLink(caseSimulationId) {
    if (this.form.valid) {
      this.showSimulationLoading = true;
      this._simulationsService
        .generatePlayerLink(caseSimulationId, this.form.value.firstName + '_' + this.form.value.lastName + '_sid_' + this.schoolChoice, this.form.value.emailAddress)
        .subscribe({
          next: (result: Array<any>) => {
            // Safari on iOS does not have Array.prototype.includes or Array.prototype.indexOf
            if ((result.includes && result.includes('GeneratePlayerLinkResult')) || 'GeneratePlayerLinkResult' in result) {
              const url = result['GeneratePlayerLinkResult']['playerLink'];

              if (!window.open(url, '_blank')) {
                // Safari doesn't allow window.open here, set location manually.
                location.href = url;
              }
            } else {
              this._toast.error('Error: something went wrong');
            }
            this.showSimulationLoading = false;
          },
        });
    } else {
      this._toast.error('Name and VALID Email address must be entered prior to simulation');
    }
  }

  loadSimulationsList() {
    this._simulationsService.getSimulationsList().subscribe((simulations) => {
      this.simulationsList = simulations.content;
    });
  }

  private _populateFromUser(user: Customer) {
    if (user) {
      this.form.patchValue({
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.email,
      });
      this.schoolChoice = user.school;
      this.alreadyHaveUserInfo = true;
    } else {
      this.form.patchValue({
        firstName: '',
        lastName: '',
        emailAddress: '',
      });
      this.schoolChoice = null;
    }
  }
}
