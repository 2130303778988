<!-- Used a group code code -->
<div *ngIf="subscriptionInfo?.subscriptionOptionCode">
  <h3>Registration Form - Complete Registration</h3>
</div>

<!-- Used a subscription option -->
<div *ngIf="!subscriptionInfo?.subscriptionOptionCode">
  <h3>Registration Form - Payment Information</h3>
  <div class="d-flex flex-column">
    <div class="content-section">
      <div style="padding-bottom: 32px">
        Please carefully check your credit card information before submitting it for processing. If any part of the credit card information is incorrect, you will have to start the
        registration process again from the beginning.
      </div>

      <div><span style="font-weight: bold">Grand Total</span>: ${{ checkoutTotal }}</div>
    </div>

    <div class="content-section">
      <div class="title-text header">Payment Information</div>

      <div class="center">
        <img class="payment-card" [class.inactive-payment-card]="!isVisa" src="images/visa.png" alt="VISA" />
        <img class="payment-card" [class.inactive-payment-card]="!isMastercard" src="images/mastercard.png" alt="Mastercard" />
        <img class="payment-card" [class.inactive-payment-card]="!isAmex" src="images/amex.png" alt="American Express" />
        <img class="payment-card" [class.inactive-payment-card]="!isDiscover" src="images/discover.png" alt="Discover" />
        <span class="payment-type-text">Accepted payment methods</span>
      </div>

      <form [formGroup]="form">
        <div class="content-section-content">
          <mat-form-field>
            <mat-label>Card Number</mat-label>
            <input matInput digitOnly maxLength="20" formControlName="cardNumber" required (keyup)="updateCardType()" mask="0000 0000 0000 0000" />
          </mat-form-field>
          <div style="display: flex; flex-direction: row">
            <mat-form-field style="padding-right: 8px;">
              <mat-label>Expiration Month</mat-label>
              <mat-select formControlName="expirationDateMonth" required>
                <mat-option value="01">Jan</mat-option>
                <mat-option value="02">Feb</mat-option>
                <mat-option value="03">Mar</mat-option>
                <mat-option value="04">Apr</mat-option>
                <mat-option value="05">May</mat-option>
                <mat-option value="06">Jun</mat-option>
                <mat-option value="07">Jul</mat-option>
                <mat-option value="08">Aug</mat-option>
                <mat-option value="09">Sept</mat-option>
                <mat-option value="10">Oct</mat-option>
                <mat-option value="11">Nov</mat-option>
                <mat-option value="12">Dec</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="padding-right: 16px;">
              <mat-label>Expiration Year</mat-label>
              <mat-select #expYear formControlName="expirationDateYear" required>
                <mat-option *ngFor="let year of years" value="{{year}}">{{year}}</mat-option>
              </mat-select>
              <mat-error>Expiration date must be in the future</mat-error>
            </mat-form-field>
          <!-- <mat-form-field>
            <mat-label>Expiration Date</mat-label>
            <input matInput formControlName="expirationDate" required placeholder="MMYY" ccExp />
            <mat-error *ngIf="hasError('expirationDate')">{{getError('expirationDate')}}</mat-error>
          </mat-form-field> -->
          <mat-form-field>
            <mat-label>Security Sequence</mat-label>
            <input matInput digitOnly maxLength="4" formControlName="cardSecurityCode" required />
          </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
