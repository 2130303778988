import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { DataTokenStatusMessageResponse, StatusMessageResponse } from "../model/mms.model";
import { PasswordValidator } from "../password-validator";
import { SubscriptionService } from "../services/subscription.service";
import { passwordsMatch } from "../validators/validators";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  emailAddress = '';
  form: FormGroup;
  passwordForm: FormGroup;

  constructor(
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    private _toast: ToastrService,
    private _passwordValidator: PasswordValidator,
    private _formBuilder: FormBuilder,
  ) {
    this.form = this._formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
    });
    this.passwordForm = this._formBuilder.group(
      {
        password: ['', [this._passwordValidator.validateForForm.bind(this._passwordValidator)]],
        confirmPassword: [],
      },
      {
        validators: passwordsMatch('password', 'confirmPassword'),
      },
    );
  }

  hasError(controlName: string): boolean {
    switch (controlName) {
      case 'confirmPassword':
        const control = this.passwordForm?.controls['confirmPassword'];

        if (this.passwordForm?.controls['password'].valid && this.passwordForm?.errors?.passwordsMatch) {
          control.setErrors({ passwordsMatch: this.passwordForm.errors?.passwordsMatch });

          return true;
        } else {
          control.setErrors({ passwordsMatch: null });
          control.updateValueAndValidity();

          if (control.errors?.required || control.errors?.passwordsMatch) {
            return true;
          }
        }
        break;
      default:
        return this.passwordForm?.controls[controlName]?.invalid || false;
    }
  }

  getError(controlName: string): string {
    const formErrors = this.passwordForm?.errors;
    const errors = this.passwordForm?.controls[controlName]?.errors;

    if (errors || formErrors) {
      switch (controlName) {
        case 'username':
          if (errors?.minlength && errors?.pattern) {
            return 'Must be at least 8 characters and contain only numbers, letters and _ or -';
          } else if (errors?.minlength) {
            return 'Must be at least 8 characters';
          } else {
            return 'Must contain only numbers, letters and _ or -';
          }
        case 'password':
          return errors?.password;
        case 'confirmPassword':
          if (errors?.passwordsMatch) {
            return 'Passwords do not match';
          }
          break;
        default:
          return null;
      }
    }

    return '';
  }

  doVerification(code: string): Observable<StatusMessageResponse> {
    console.log('do verification!');

    return this._subscriptionService.verifyForgotPasswordOtp(this.form.controls.emailAddress.value, code);
  }

  doVerificationSuccess(dataToken: string): Observable<StatusMessageResponse> {
    console.log('do post-validation');

    return of({ success: true });
  }

  doAction(dataToken: string): Observable<StatusMessageResponse> {
    return this._subscriptionService.resetPassword(this.form.controls.emailAddress.value, dataToken, this.passwordForm.controls.password.value);
  }

  sendVerificationCode(): Observable<DataTokenStatusMessageResponse> {
    console.log('send verification code');
    return this._subscriptionService.sendForgotPasswordRequest(this.form.controls.emailAddress.value);
  }

  onActionSuccess() {
    this._toast.info("Your password has been successfully changed.");
    this._router.navigateByUrl('/landing');
  }
}
