<div
  style="
    width: 80%;
    height: 60%;
    position: absolute;
    top: 200px;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
  "
>
  <div><b>Terms &amp; Conditions</b><br /><br /></div>
  <div
    style="
      border: solid 0px white;
      overflow-y: scroll;
      text-align: left;
      display: flex 1 1 auto;
    "
    [innerHtml]="content"
  ></div>
  <div
    style="border-top: solid 1px lightgray; margin-top: 8px; padding-top: 8px"
  >
    <input type="checkbox" [(ngModel)]="agreedToTermsAndConditions" /> I have
    read and agree to accept the Terms & Conditions as detailed above.
    <button
      (click)="routeToPrograms()"
      [disabled]="!agreedToTermsAndConditions"
    >
      NEXT
    </button>
  </div>
</div>
