import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthType, IpCompany, LoginRequest, LoginResponse, SubscriptionType, TokenResponse } from '../model/mms.model';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authType: AuthType;
  public loginStatusChanged = new ReplaySubject<boolean>(1);

  private _rememberMe = false;

  constructor(
    private _http: HttpClient,
    @Inject('BASE_API_URL') private _baseApiUrl: string,
    private _tokenStorageService: TokenStorageService,
    private _jwtHelper: JwtHelperService,
    private _toast: ToastrService,
    private _router: Router,
  ) {
    this._rememberMe = this._tokenStorageService.usesLocalStorage();
    this._checkIpAuth();
  }

  public isIpAuth(): boolean {
    return "IP" === this._tokenStorageService.getAuthType();
  }

  public isAuthenticated(): Observable<boolean> {
    // Check if user is logged in (has a token).
    // If user has a token verify that they have agreed to the site-license agreement if it is via IP auth
    let token = this._tokenStorageService.getToken();
    let authType = this._tokenStorageService.getAuthType();
    let isValid = !this._jwtHelper.isTokenExpired(token);

    if (token !== null && token !== undefined && !isValid) {
      // Token is expired, attempt to refresh token and then check auth status.
      this.refreshToken().subscribe(result => {
        token = this._tokenStorageService.getToken();
        authType = this._tokenStorageService.getAuthType();
        isValid = !this._jwtHelper.isTokenExpired(token);

        return of(this._doIsAuthenticated(authType, isValid));
      });
    } else {
      return of(this._doIsAuthenticated(authType, isValid));
    }
  }

  public getCompany(): Observable<IpCompany> {
    if (this.isIpAuth()) {
      return this._http.get<IpCompany>(`${this._baseApiUrl}auth/ip`);
    }

    return of(null);
  }

  public login(username: string, password: string, rememberMe: boolean) {
    const url = `${this._baseApiUrl}auth/login`;

    this._rememberMe = rememberMe;

    this._http.post<LoginResponse>(url, { username, password, rememberMe } as LoginRequest).subscribe({
      next: (response) => {
        if (response && response.accessToken) {
          this._tokenStorageService.saveToken(response.accessToken, rememberMe);
          this._tokenStorageService.saveRefreshToken(response.refreshToken, rememberMe);
          this._tokenStorageService.saveAuthType(response.type);
          this.loginStatusChanged.next(true);
        } else {
          this.loginStatusChanged.next(false);
          this._toast.error('Login failed, please try again');
        }
      },
      error: () => {
        this._toast.error('Login failed, please try again');
      },
    });
  }

  public logout() {
    this._tokenStorageService.signOut();
    this.loginStatusChanged.next(false);
  }

  public refreshToken(): Observable<boolean> {
    const refreshToken = this._tokenStorageService.getRefreshToken();
    const url = `${this._baseApiUrl}auth/refresh-token`;
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    // If refresh token exists and is not expired, request a new token
    if (refreshToken && !this._jwtHelper.isTokenExpired(refreshToken)) {
      return this._http.post<TokenResponse>(url, { refreshToken }, headers).pipe(
        map((tokenResponse: TokenResponse) => {
          this._tokenStorageService.saveToken(tokenResponse.accessToken, this._rememberMe);
          this._tokenStorageService.saveRefreshToken(tokenResponse.refreshToken, this._rememberMe);
          this.loginStatusChanged.next(true);

          return true;
        }),
        catchError(() => {
          // Got an error refreshing the token, log the user out.
          this.logout();

          return of(false);
        }),
      );
    }

    // Refresh token doesn't exist or is expired, log user out.
    this.logout();

    return of(false);
  }

  public getSubscriptionType(): Observable<SubscriptionType> {
    return this.isAuthenticated().pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          const token = this._tokenStorageService.getToken();

          return this._jwtHelper.decodeToken(token).subscriptionType;
        } else {
          return null;
        }
      })
    );
  }

  private _checkIpAuth(): void {
    this.isAuthenticated().subscribe(isAuthenticated => {
      if (!isAuthenticated && !this.isIpAuth()) {
        this._checkIpAuthAndUpdate();
      }
    });
  }

  private _checkIpAuthAndUpdate(): void {
    this._http.get<LoginResponse>(`${this._baseApiUrl}auth/check-auth`).subscribe({
      next: (response) => {
        this.authType = AuthType.ip;
        this._tokenStorageService.saveToken(response.accessToken, true);
        this._tokenStorageService.saveRefreshToken(response.refreshToken, true);
        this._tokenStorageService.saveAuthType(response.type);
        this._tokenStorageService.saveAgreeToTerms(false);
        this.loginStatusChanged.next(true);
        this._router.navigateByUrl('/sitelicense');
      },
      error: (err) => {
        console.log('error in check-auth');
        console.log(err);
      },
    });
  }

  private _doIsAuthenticated(authType: string, isValid: boolean): boolean {
    if (authType === 'IP') {
      if (!isValid) {
        // Token has expired, refetch token
        console.log('token has expired, refreshing');
        this._checkIpAuthAndUpdate();
        return false;
      }

      const agreed = this._tokenStorageService.getAgreeToTerms();

      // If ip auth & not agreed re-direct to /sitelicense
      if (isValid && agreed) {
        return true;
      } else if (isValid && !agreed) {
        this._router.navigateByUrl('/sitelicense');
      } else {
        return false;
      }
    } else {

      // If not IP auth and has token (and not expired) user is authenticated.
      return isValid;
    }
  }
}
