<app-content-balloon [showOverlay]="showOverlay" [overlayText]="overlayText" width="80vw">
  <div *ngIf="showStep1">
    <!-- Subscription Type Selection -->
    <h3>Registration Form</h3>
    <div style="color: #3c0b2f; font-weight: bold; margin-bottom: 32px">PLEASE ENTER EITHER A PAYMENT PLAN OR GROUP CODE, NOT BOTH.</div>

    <div class="d-flex flex-column">
      <!-- Header -->
      <div class="d-flex flex-row">
        <div class="table-header"><div class="center">SUBSCRIPTION OPTION</div></div>
      </div>

      <!-- Pricing Options -->
      <div *ngIf="!subscriptionOptionCode || subscriptionOptionCode?.trim().length === 0">
        <div class="d-flex flex-row" *ngFor="let pricingOption of pricingOptions | async" [hidden]="">
          <div class="left-column">
            <input type="radio" (click)="doSubscriptionRadioSelect(pricingOption)" name="subscription_option" />
            ${{ pricingOption.price }}
          </div>
          <div class="right-column">
            <div class="option-title">{{ pricingOption.title }}</div>
            <div class="option-description">{{ pricingOption.description }}</div>
          </div>
        </div>
      </div>

      <!-- Group Membership Code -->
      <div *ngIf="!selectedSubscriptionOption" class="d-flex flex-row">
        <div class="left-column"><input type="text" name="subscription_option_code" value="" size="15" [(ngModel)]="subscriptionOptionCode" /></div>
        <div class="right-column">
          <div class="option-title">Group Membership Code</div>
          <div class="option-description">
            <div>This is the code given to you by your group administrator.</div>
            <div style="margin-top: 32px">(Institutions: <a [routerLink]="['/contact-us']" class="link">contact us</a> for rate information.)</div>
          </div>
        </div>
      </div>
    </div>

    <div class="center" style="margin-top: 32px">
      <button (click)="clearStep1()" mat-flat-button>Clear</button>
      <button (click)="doShowStep2()" mat-flat-button color="primary">Next Step</button>
    </div>

    <div class="center" style="margin-top: 16px"><a [routerLink]="['/textbook']" class="link">Please click here to purchase our textbook separately.</a></div>
  </div>

  <div *ngIf="showStep2">
    <!-- User Registration Info -->
    <h3>Registration Form - Step Two</h3>

    <div style="margin-bottom: 32px">Access to this website is limited to students and practitioners in the health professions.</div>

    <!-- Personal Info -->
    <div class="d-flex flex-column">
      <!-- Header -->
      <div class="d-flex flex-row">
        <div class="table-header"><div class="center">PERSONAL INFORMATION</div></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column required">Title</div>
        <div class="right-column">
          <select [(ngModel)]="titleName" name="customer_title">
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Ms.">Ms.</option>
            <option value="Miss">Miss</option>
            <option value="Dr.">Dr.</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">First Name</div>
        <div class="right-column"><input type="text" name="customer_f_name" [(ngModel)]="firstName" size="20" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column">Middle Initial</div>
        <div class="right-column"><input type="text" name="customer_m_name" [(ngModel)]="middleName" size="1" maxlength="1" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">Last Name</div>
        <div class="right-column"><input type="text" name="customer_l_name" [(ngModel)]="lastName" size="20" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">Date of Birth</div>
        <div class="right-column">
          <select [(ngModel)]="birthMonth" name="customer_dob_mm">
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          /
          <select [(ngModel)]="birthDay" name="customer_dob_dd">
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </select>
          /
          <input type="text" [(ngModel)]="birthYear" name="customer_dob_yyyy" size="4" maxlength="4" value="YYYY" />
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">Address</div>
        <div class="right-column">
          <div><input type="text" name="customer_address_1" size="20" value="" [(ngModel)]="address1" /></div>
          <div><input type="text" name="customer_address_2" size="20" value="" [(ngModel)]="address2" /></div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">City</div>
        <div class="right-column"><input type="text" name="customer_city" size="20" [(ngModel)]="city" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">State / Province</div>
        <div class="right-column"><input type="text" name="customer_state" size="20" [(ngModel)]="state" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">Zip / Postal Code</div>
        <div class="right-column"><input type="text" name="customer_zip" size="20" [(ngModel)]="zip" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">Country</div>
        <div class="right-column">
          <select name="customer_country" [(ngModel)]="country">
            <option value="US" selected>United States</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Ashmore and Cartier Islands">Ashmore and Cartier Islands</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas, The">Bahamas, The</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Baker Island">Baker Island</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Bassas da India">Bassas da India</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
            <option value="British Virgin Islands">British Virgin Islands</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burma">Burma</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Clipperton Island">Clipperton Island</option>
            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
            <option value="Congo, Republic of the">Congo, Republic of the</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Coral Sea Islands">Coral Sea Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote d'Ivoire">Cote d'Ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Europa Island">Europa Island</option>
            <option value="Falkland Islands (Islas Malvinas)">Falkland Islands (Islas Malvinas)</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia, The">Gambia, The</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Glorioso Islands">Glorioso Islands</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guernsey">Guernsey</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Holy See (Vatican City)">Holy See (Vatican City)</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Howland Island">Howland Island</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indian Ocean">Indian Ocean</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Jan Mayen">Jan Mayen</option>
            <option value="Japan">Japan</option>
            <option value="Jarvis Island">Jarvis Island</option>
            <option value="Jersey">Jersey</option>
            <option value="Johnston Atoll">Johnston Atoll</option>
            <option value="Jordan">Jordan</option>
            <option value="Juan de Nova Island">Juan de Nova Island</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kingman Reef">Kingman Reef</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, South">Korea, South</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macau">Macau</option>
            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Man, Isle of">Man, Isle of</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
            <option value="Midway Islands">Midway Islands</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Navassa Island">Navassa Island</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pacific Ocean">Pacific Ocean</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palmyra Atoll">Palmyra Atoll</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paracel Islands">Paracel Islands</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn Islands">Pitcairn Islands</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
            <option value="Southern Ocean">Southern Ocean</option>
            <option value="Spain">Spain</option>
            <option value="Spratly Islands">Spratly Islands</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard">Svalbard</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tromelin Island">Tromelin Island</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Virgin Islands">Virgin Islands</option>
            <option value="Wake Island">Wake Island</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column">Telephone</div>
        <div class="right-column"><input type="text" name="customer_phone" size="20" [(ngModel)]="telephone" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column">Fax Number</div>
        <div class="right-column"><input type="text" name="customer_fax" size="20" [(ngModel)]="faxNumber" value="" /></div>
      </div>
      <div class="d-flex flex-row">
        <div class="left-column required">E-Mail Address</div>
        <div class="right-column"><input type="text" name="customer_email" size="30" [(ngModel)]="emailAddress" value="" /></div>
      </div>
    </div>

    <!-- Profession Classification -->
    <div class="d-flex flex-column" style="margin-top: 32px">
      <div class="d-flex flex-row">
        <div class="table-header"><div class="center">PROFESSIONAL CLASSIFICATION</div></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column required">Profession</div>
        <div class="right-column">
          <select name="customer_profession" [(ngModel)]="profession">
            <option value="" style="color: #ff0000" selected>-- please select --</option>
            <option value="Medicine">Medicine (MD or DO)</option>
            <option value="Nurse (LPN, RN or NP)">Nurse (LPN, RN or NP)</option>
            <option value="Dentistry">Dentistry</option>
            <option value="Podiatry">Podiatry</option>
            <option value="Chiropractic">Chiropractic</option>
            <option value="Naturopathy">Naturopathy</option>
            <option value="Physician Assistant">Physician Assistant</option>
            <option value="Allied Health">Allied Health</option>
            <option value="EMT / Paramedic">EMT / Paramedic</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      <div *ngIf="profession === 'Other'" class="d-flex flex-row">
        <div class="left-column required">If Other Profession, Please Explain</div>
        <div class="right-column"><input type="text" name="customer_profession_other" size="20" value="" [(ngModel)]="professionOther" /></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column required">Name of School</div>
        <div class="right-column"><input type="text" name="customer_school" size="34" value="" [(ngModel)]="school" /></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column required">Level of Training</div>
        <div class="right-column">
          <select name="customer_training" [(ngModel)]="training">
            <option value="" style="color: #ff0000" selected>-- please select --</option>
            <option value="Student">Student</option>
            <option value="Resident">Resident</option>
            <option value="Post-Graduate Student">Post-Graduate Student (MA, PhD)</option>
            <option value="Practitioner or Instructor">Practitioner or Instructor</option>
          </select>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column">Specialty or Major</div>
        <div class="right-column"><input type="text" name="customer_specialty" size="20" value="" [(ngModel)]="specialty" /></div>
      </div>
    </div>

    <!-- E-Mail Service Preferences -->
    <div class="d-flex flex-column" style="margin-top: 32px">
      <div class="d-flex flex-row">
        <div class="table-header"><div class="center">E-MAIL SERVICES</div></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column"><input type="checkbox" name="customer_email_services_1" value="1" checked [(ngModel)]="emailServices1" /> Site Updates</div>
        <div class="right-column">
          <div class="option-description">
            Occasionally, Medical Media Systems will want to contact you regarding special offers, changes to our website, or other updates. To receive these updates, please check
            the box.
          </div>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column"><input type="checkbox" name="customer_email_services_2" value="1" checked [(ngModel)]="emailServices2" /> Opt-In</div>
        <div class="right-column">
          Occasionally, Medical Media Systems will share our client list with companies and groups that will be of interest to you. To receive special offers and information from
          these companies and groups, please check the box.
        </div>
      </div>
    </div>

    <!-- Account Information -->
    <div class="d-flex flex-column" style="margin-top: 32px">
      <div class="d-flex flex-row">
        <div class="table-header"><div class="center">ACCOUNT INFORMATION</div></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column">
          <div class="required">Username</div>
          <div>Must be 6-11 characters in length</div>
          <div>and use only letters and numbers.</div>
        </div>
        <div class="right-column"><input type="text" name="customer_username" size="11" maxlength="11" value="" [(ngModel)]="username" /></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column">
          <div class="required">Password</div>
          <div>Must be 6-11 characters in length</div>
          <div>and use only letters and numbers.</div>
        </div>
        <div class="right-column"><input type="password" name="customer_password" size="11" maxlength="11" [(ngModel)]="password" /></div>
      </div>

      <div class="d-flex flex-row">
        <div class="left-column">
          <div class="required">Confirm Password</div>
          <div>Repeat password to ensure it is properly set.</div>
        </div>
        <div class="right-column"><input type="password" name="customer_password_confirm" size="11" maxlength="11" [(ngModel)]="confirmPassword" /></div>
      </div>

      <div class="d-flex flex-row" style="margin-top: 32px">
        <div class="left-column">Where You Heard About Us</div>
        <div class="right-column">
          <select name="customer_referral" [(ngModel)]="referral">
            <option value="" style="color: #ff0000" selected>-- please select --</option>
            <option value="Friend or Relative">Friend or Relative</option>
            <option value="Other student, resident, colleague, etc.">Other student, resident, colleague, etc.</option>
            <option value="Magazine Ad">Magazine Ad</option>
            <option value="Search Engine">Search Engine</option>
            <option value="Link on Another Site">Link on Another Site</option>
            <option value="Ad on Another Site">Ad on Another Site</option>
            <option value="Article (Journal or Online)">Article (Journal or Online)</option>
            <option value="Found Site While Surfing">Found Site While Surfing</option>
            <option value="Our PDA Program">Our PDA Program</option>
            <option value="Email Ad or Link">Email Ad or Link</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Terms and Conditions -->
    <div class="d-flex flex-column" style="margin-top: 32px">
      <div class="d-flex flex-row">
        <div class="table-header"><div class="center">TERMS AND CONDITIONS</div></div>
      </div>

      <div style="border: solid 1px black; width: 500px; max-height: 200px; overflow: scroll" [innerHtml]="content"></div>
      <div style="margin-top: 8px">
        <input type="checkbox" name="customer_terms_agree" value="1" [(ngModel)]="acceptedTermsConditions" />
        I have read and agree to accept the Terms & Conditions as detailed above.
      </div>
    </div>

    <div class="center" style="margin-top: 32px">
      <button (click)="doShowStep1()" mat-flat-button>Previous Step</button>
      <button *ngIf="subscriptionOptionCode" (click)="doShowStep4()" mat-flat-button color="primary">Next Step</button>
      <button *ngIf="!subscriptionOptionCode" (click)="doShowStep3()" mat-flat-button color="primary">Next Step</button>
    </div>
  </div>

  <div *ngIf="showStep3">
    <div *ngIf="!subscriptionOptionCode">
      <div style="padding-bottom: 32px">
        Please carefully check your credit card information before submitting it for processing. If any part of the credit card information is incorrect, you will have to start the
        registration process again from the beginning.
      </div>

      <div><span style="font-weight: bold">Grand Total</span>: ${{ checkoutTotal }}</div>

      <div class="d-flex flex-column">
        <div class="d-flex flex-row">
          <div class="table-header"><div class="center">PAYMENT INFORMATION</div></div>
          <div class="right-column"><iframe [src]="verisignUrl | safe" title="verisign" style="border: 0px; height: 100px"></iframe></div>
        </div>

        <div class="d-flex flex-row">
          <div class="left-column required">Card Type</div>
          <div class="right-column">
            <select name="customer_card_type" [(ngModel)]="cardType">
              <option value="Visa" selected>Visa</option>
              <option value="Mastercard">Mastercard</option>
              <option value="American Express">American Express</option>
              <option value="Discover">Discover</option>
            </select>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="left-column required">Card Number</div>
          <div class="right-column"><input type="text" name="customer_card_number" size="20" [(ngModel)]="cardNumber" /></div>
        </div>

        <div class="d-flex flex-row">
          <div class="left-column required">Expiration Date</div>
          <div class="right-column">
            <select name="customer_card_exp_mm" [(ngModel)]="cardExpMonth">
              <option value="01" selected>01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            /
            <select name="customer_card_exp_yyyy" [(ngModel)]="cardExpYear">
              <option value="21">2021</option>
              <option value="22">2022</option>
              <option value="23">2023</option>
              <option value="24">2024</option>
              <option value="25">2025</option>
              <option value="26">2026</option>
              <option value="27">2027</option>
              <option value="28">2028</option>
              <option value="28">2029</option>
              <option value="28">2030</option>
              <option value="28">2031</option>
              <option value="28">2032</option>
            </select>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="left-column required">Security Sequence</div>
          <div class="right-column"><input type="text" name="customer_card_security" size="4" maxlength="4" value="" [(ngModel)]="cardSecurityCode" /></div>
        </div>

        <div class="d-flex flex-row">
          <div class="left-column"><input type="checkbox" name="customer_gift" value="1" [(ngModel)]="customerGift" />Gift</div>
          <div class="right-column"></div>
        </div>

        <div class="d-flex flex-row" *ngIf="customerGift">
          <div class="left-column">Please list their email addresses</div>
          <div class="right-column">
            <div><input type="text" name="customer_gift_email" size="20" value="" [(ngModel)]="giftEmail" /></div>
            <div>We will send them an email with their login information and a note acknowledging your gift to them.</div>
          </div>
        </div>
      </div>
      <div class="center" style="padding-top: 32px">
        <button *ngIf="showSubmitRegistrationButton" (click)="doShowStep2()" mat-flat-button>Previous Step</button>
        <button *ngIf="showSubmitRegistrationButton" (click)="doShowStep4()" mat-flat-button color="primary">Submit Registration</button>
      </div>
    </div>
  </div>

  <div *ngIf="showStep4">
    <div *ngIf="!subscriptionOptionCode">Thank You For Your Payment.</div>
    <div *ngIf="subscriptionOptionCode">Thank You For Registering.</div>
  </div>

  <script src="//static.getclicky.com/js" type="text/javascript"></script>
  <script type="text/javascript">
    try {
      clicky.init(100574530);
    } catch (e) {}
  </script>
  <noscript
    ><p>
      <img alt="Clicky" width="1" height="1" src="//in.getclicky.com/100574530ns.gif" /></p
  ></noscript>
</app-content-balloon>
