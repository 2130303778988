import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { validate } from 'secure-password-validator';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { GlobalVarsService } from '../global-vars.service';
import { Customer, CustomerMeta, Patch, PatchAction, PatchValueType, SubscriptionType } from '../model/mms.model';
import { OtpDialogComponent } from '../otp-dialog/otp-dialog.component';
import { AuthService } from '../services/auth.service';
import { UserService } from '../user.service';
import { clone, isNullOrUndefined } from '../utils';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit, OnDestroy {
  @ViewChild('unsubscribe') confirmUnsubscribeCheckbox: MatCheckbox;
  @ViewChild('email') emailElement: ElementRef;
  @ViewChild('zip') zipElement: ElementRef;

  isRequesting = false;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  confirmUnsubscribe: boolean;
  unregisterEnabled = true;
  passwordChangeValid = false;
  passwordErrorMessage = '';

  sourceCustomer: Customer;
  customer: Customer;
  sourceCustomerMeta: CustomerMeta;
  customerMeta: CustomerMeta;

  customer_title: string;
  first_name: string;
  middle_initial: string;
  last_name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  telephone: string;
  fax: string;
  customer_country: string;
  card_number: string;
  expiration_date: string;
  security_code: string;
  customer_profession: string;
  other_profession: string;
  school: string;
  customer_training: string;

  emailForm: FormGroup;
  originalEmail: string;

  private _passwordValidator;

  constructor(
    private _toast: ToastrService,
    private _userService: UserService,
    private globalVarsService: GlobalVarsService,
    private router: Router,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    this.emailForm = _formBuilder.group({
      email: ['', [Validators.email]]
    });
  }

  ngOnInit() {
    this._userService.getLoggedInUser().subscribe((customer) => {
      this.sourceCustomer = clone(customer);
      this.customer = clone(customer);
      this.originalEmail = customer.email;
      this.emailForm.controls.email.patchValue(customer.email);
    });
    this._userService.getLoggedInUserMeta().subscribe((customerMeta) => {
      this.sourceCustomerMeta = clone(customerMeta);
      this.customerMeta = clone(customerMeta);
    });
  }

  ngOnDestroy(): void {
    this.oldPassword = null;
    this.newPassword = null;
    this.newPasswordConfirmation = null;
  }

  numberOnly(evt) {
    if (evt.data !== null && evt.data?.match(/\d/) === null) {
      evt.preventDefault();
      return false;
    }

    console.dir(evt);
    this.zipElement.nativeElement.value = this.zipElement.nativeElement.value.replace(/[^0-9]/gi, '');
  }

  onCountryChanged(obj: any) {
    console.log('got change event:');
    console.log(JSON.stringify(obj, null, 2));
    this.customer.country = obj;
  }

  onPasswordChanged() {
    this.passwordChangeValid = this._isPasswordValid();
  }

  showUnsubscribe(): Observable<boolean> {
    return this._authService.getSubscriptionType().pipe(
      map(subscriptionType => subscriptionType === SubscriptionType.RECURRING)
    );
  }

  updateEmailPrefs() {
    const patch: Patch = { operations: [] };

    if (this.customerMeta.emailOptIn !== this.sourceCustomerMeta.emailOptIn) {
      patch.operations.push({
        actionType: PatchAction.REPLACE,
        field: 'emailOptIn',
        value: '' + this.customerMeta.emailOptIn,
        valueType: PatchValueType.BOOL,
      });
    }

    if (this.customerMeta.emailUpdates !== this.sourceCustomerMeta.emailUpdates) {
      patch.operations.push({
        actionType: PatchAction.REPLACE,
        field: 'emailUpdates',
        value: '' + this.customerMeta.emailUpdates,
        valueType: PatchValueType.BOOL,
      });
    }

    if (patch.operations.length > 0) {
      this.isRequesting = true;
      this._userService.updateEmailPreferences(patch).subscribe({
        next: (result) => {
          this.sourceCustomerMeta.emailUpdates = this.customerMeta.emailUpdates;
          this.sourceCustomerMeta.emailOptIn = this.customerMeta.emailOptIn;
          this._toast.success('Email preferences have been updated!');
          this.isRequesting = false;
        },
        error: () => {
          this._toast.error('Could not email preferences');
          this.isRequesting = false;
        },
        complete: () => {
          this.isRequesting = false;
        },
      });
    }
  }

  verifyEmail() {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Change Email Address',
        message: 'After changing your email address, you will be logged out and need to log back in.',
        okText: 'Proceed',
        cancelText: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe({
      next: response => {
        if (response) {
          this.doEmailOtpAndUpdate();
        }
      }
    })
  }

  doEmailOtpAndUpdate() {
    const dialogRef = this._dialog.open(OtpDialogComponent, {
      width: '600px',
      data: {
        emailAddress: this.emailForm.controls.email.value,
        sendVerificationCode: () => this._userService.sendEmailUpdateOtp(this.emailForm.controls.email.value),
        verify: (code) => this._userService.verifyEmailUpdateOtp(this.emailForm.controls.email.value, code),
        onVerifyFailure: () => {
          setTimeout(() => {
            this.emailElement.nativeElement.focus();
            this.emailElement.nativeElement.select()
          }, 50, this);
        },
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (response) => {
        if (isNullOrUndefined(response)) {
          return;
        }

        if (!response.success && response.message && response.message !== 'user canceled') {
          this._toast.error(response.message);
        }

        if (response.dataToken) {
          this._userService.updateEmailAddress(this.emailForm.controls.email.value, response.dataToken).subscribe((response) => {
            if (response.success) {
              this.originalEmail = this.emailForm.controls.email.value;
              this._toast.info('Your email address has been updated');
              this._authService.logout();
            } else {
              this._toast.error(response.message);
            }
          });
        }
      },
    });
  }

  disableUpdateEmail() {
    return this.isRequesting || this.emailForm.invalid || this.emailForm.controls.email.pristine || this.emailForm.controls.email.value === this.originalEmail;
  }

  updateEmailAddress() {
    const patch: Patch = {
      operations: [],
    };

      const value = this.emailForm.controls.email.value;

      patch.operations.push({
        actionType: value === null ? PatchAction.REMOVE : PatchAction.REPLACE,
        field: 'email',
        value: this.emailForm.controls.email.value,
        valueType: PatchValueType.STRING,
      });

    this.isRequesting = true;
    this._userService.updateProfile(patch).subscribe({
      next: (result) => {
        this.emailForm.markAsPristine();
        this.originalEmail = this.emailForm.controls.email.value;
        this._toast.success('Email address has been updated!');
        this.isRequesting = false;
      },
      error: () => {
        this._toast.error('Could not update user profile');
        this.isRequesting = false;
      },
      complete() {
        this.isRequesting = false;
      },
    });
  }

  changePassword() {
    if (this.newPassword == null || this.newPassword.length < 6) {
      alert('Password must be at least 6 characters');
      return;
    }

    if (this.newPassword != this.newPasswordConfirmation) {
      alert('Password confirmation must match');
      return;
    }

    const op = this.oldPassword;
    const np = this.newPassword;

    this.oldPassword = null;
    this.newPassword = null;
    this.newPasswordConfirmation = null;

    this.isRequesting = true;
    this._userService.changePassword(op, np).subscribe({
      next: (result: Array<any>) => {
        this._toast.success('Your password has been updated!');
        this.isRequesting = false;
      },
      error: (result: any) => {
        this._toast.error('Could not change password');
        console.log('password change failed!');
        console.log(JSON.stringify(result, null, 2));
        this.isRequesting = false;
      },
      complete: () => {
        this.isRequesting = false;
      },
    });
  }

  updateProfile() {
    const patch: Patch = {
      operations: [],
    };

    Object.keys(this.customer).forEach((key) => {
      if (key !== 'email' && this.sourceCustomer[key] !== this.customer[key]) {
        const value = this.customer[key];

        patch.operations.push({
          actionType: value === null ? PatchAction.REMOVE : PatchAction.REPLACE,
          field: key,
          value: value,
          valueType: key === 'zip' || Number.isFinite(value) ? PatchValueType.INT : PatchValueType.STRING,
        });
      }
    });

    this.isRequesting = true;
    this._userService.updateProfile(patch).subscribe({
      next: (result) => {
        this.sourceCustomer = clone(this.customer);
        this._toast.success('User profile has been updated!');
        this.isRequesting = false;
      },
      error: () => {
        this._toast.error('Could not update user profile');
        this.isRequesting = false;
      },
      complete: () => {
        this.isRequesting = false;
      },
    });
  }

  unregisterUser() {
    if (this.confirmUnsubscribeCheckbox.checked) {
      this._userService.cancelSubscription().subscribe({
        next: (response) => {
          if (response.success) {
            console.log('unsubscribe successful');
            this._toast.info('Your account has been deactivated along with your subscription website access. A confirmation email has been sent.');
            this._authService.logout();
            setTimeout(() => {
              this.router.navigateByUrl('/landing');
            }, 3000);
            this.unregisterEnabled = false;
          } else {
            console.log('oops, could not unsubscribe');
            this._toast.error('An error has been encountered during the unsubscription process, please try again');
            this.unregisterEnabled = true;
            console.dir(response);
          }
        },
        error: () => {
          console.log('oops, could not unsubscribe');
          this._toast.error('An error has been encountered during the unsubscription process, please try again');
          this.unregisterEnabled = true;
        },
      });
    } else {
      alert('Please select the checkbox');
    }
  }

  private _isPasswordValid() {
    if (isNullOrUndefined(this.newPassword) || isNullOrUndefined(this.newPasswordConfirmation) || isNullOrUndefined(this.oldPassword)) {
      this.passwordErrorMessage = '';

      return false;
    }

    console.log('has some content...');
    if (this.newPassword !== this.newPasswordConfirmation) {
      this.passwordErrorMessage = 'Passwords do not match';

      return false;
    }

    const result = validate(this.newPassword, {
      minLength: 8,
      digits: true,
      letters: true,
      uppercase: true,
      lowercase: true,
      symbols: true,
    });

    if (!result.valid) {
      let message = 'Password must';
      const items = [];
      let isAnd = false;

      if (result.errors.indexOf('MIN_LENGTH') >= 0) {
        isAnd = result.errors.length > 1;
        message = 'Password must be at least 8 characters';
      }

      if (result.errors.indexOf('DIGITS') >= 0) {
        items.push('number');
      }

      if (result.errors.indexOf('UPPERCASE') >= 0) {
        items.push('upper case letter');
      }

      if (result.errors.indexOf('LOWERCASE') >= 0) {
        items.push('lower case letter');
      }

      if (result.errors.indexOf('SYMBOLS') >= 0) {
        items.push('symbol');
      }

      if (isAnd) {
        message = message + ' and ';
      }

      if (items.length > 0) {
        message = message + ' contain at least 1 ' + items.join(', ');
      }

      this.passwordErrorMessage = message;
    } else {
      this.passwordErrorMessage = '';
    }

    return result.valid;
  }
}
