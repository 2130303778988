import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.css'],
})
export class CountrySelectComponent {
  @Input()
  public value;

  @Input()
  public label = 'Country';

  @Input()
  public required = false;

  @Output()
  public valueChanged = new Subject<any>();

  public onValueChanged(obj: any) {
    this.valueChanged.next(obj.value);
  }
}
