import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataTokenStatusMessageResponse, PricingOption, RegistrationRequest, StatusMessageResponse, ValidateAccountInfoRequest, ValidateAccountInfoResponse } from '../model/mms.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private _http: HttpClient, @Inject('BASE_API_URL') private _baseApiUrl: string) {}

  public register(subscriptionRequest: RegistrationRequest): Observable<any> {
    return this._http.post<any>(`${this._baseApiUrl}subscriptions/register`, subscriptionRequest);
  }

  public getPricingOptions(): Observable<Array<PricingOption>> {
    return this._http.get<Array<PricingOption>>(`${this._baseApiUrl}subscriptions/pricing-options`).pipe(
      tap((pricingOptions) => {
        if (pricingOptions && pricingOptions.length > 0) {
          return pricingOptions.sort((a, b) => a.displayOrder - b.displayOrder);
        }

        return pricingOptions;
      }),
    );
  }

  public getTotalCost(pricingOptionId: number): Observable<number> {
    return this._http.get<number>(`${this._baseApiUrl}subscriptions/pricing-options/${pricingOptionId}`);
  }

  public verifyEmailToken(username: string, token: string): Observable<StatusMessageResponse> {
    return this._http.post<StatusMessageResponse>(`${this._baseApiUrl}subscriptions/validate/${encodeURI(username)}`, token);
  }

  public isEmailValidated(username: string, dataTokenId: number): Observable<StatusMessageResponse> {
    return this._http.get<StatusMessageResponse>(`${this._baseApiUrl}subscriptions/validate/${encodeURI(username)}/token/${dataTokenId}`);
  }

  public sendEmailVerifyCode(firstName: string, lastName: string, emailAddress: string, captchaResponse: string): Observable<StatusMessageResponse> {
    const payload = {
      firstName,
      lastName,
      emailAddress
    };

    const headers = new HttpHeaders({
      'captcha-response': captchaResponse,
    });

    return this._http.post<StatusMessageResponse>(`${this._baseApiUrl}subscriptions/validate/otp/`, payload, { headers });
  }

  public verifyEmailCode(emailAddress: string, code: string): Observable<DataTokenStatusMessageResponse> {
    const payload = {
      emailAddress,
      otp: code
    };

    return this._http.post<DataTokenStatusMessageResponse>(`${this._baseApiUrl}subscriptions/validate/otp/verify`, payload);
  }

  public validateAccountInfo(request: ValidateAccountInfoRequest, captchaResponse: string): Observable<ValidateAccountInfoResponse> {
    const headers = new HttpHeaders({
      'captcha-response': captchaResponse,
    });

    return this._http.post<ValidateAccountInfoResponse>(`${this._baseApiUrl}subscriptions/validate/otp`, request, { headers });
  }

  public resetPassword(emailAddress: string, dataToken: string, password: string): Observable<StatusMessageResponse> {
    const payload = {
      emailAddress,
      dataToken,
      password
    };

    return this._http.post<StatusMessageResponse>(`${this._baseApiUrl}subscriptions/forgot-password`, payload);
  }

  public verifyForgotPasswordOtp(emailAddress: string, otp: string): Observable<DataTokenStatusMessageResponse> {
    return this._http.post<DataTokenStatusMessageResponse>(`${this._baseApiUrl}subscriptions/forgot-password/otp/verify`, { emailAddress, otp });
  }

  public sendForgotPasswordRequest(emailAddress: string): Observable<StatusMessageResponse> {
    return this._http.post<StatusMessageResponse>(`${this._baseApiUrl}subscriptions/forgot-password/otp`, { emailAddress });
  }
}
