<app-content-balloon [showOverlay]="showSimulationLoading" width="600px" overlayText="Loading Simulation . . .">
  <h3>Simulations</h3>
  <div *ngIf="!alreadyHaveUserInfo">
    <form [formGroup]="form">
      <p>Please enter your Name and Email address to run simulations.</p>
      <div>
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input formControlName="firstName" matInput required />
      </mat-form-field>
      </div>
      <div>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input formControlName="lastName" matInput required />
      </mat-form-field>
      </div><div>
      <mat-form-field>
        <mat-label>E-Mail Address</mat-label>
        <input formControlName="emailAddress" matInput required />
      </mat-form-field>
      </div>
    </form>
  </div>

  <div *ngIf="form.valid">
  <h4>Available Programs...</h4>
  <div *ngFor="let simulation of simulationsList" class="simulation">
    <a style="cursor: pointer; text-decoration: underline" (click)="generatePlayerLink(simulation.simulationId)">{{ simulation.caption }}</a>
  </div>
  </div>
</app-content-balloon>
