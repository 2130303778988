<app-content-balloon>
  <h3 style="font-size: 18px; font-weight: bold" id="top">Support - Downloading and Viewing e-book</h3>
  <p style="font-weight: bold">1. To obtain an Adobe ID and password.</p>
  <p style="margin-left: 32px">A. Go to <a href="http://www.adobe.com" target="_new" class="link">www.adobe.com</a>.</p>

  <p style="margin-left: 32px">B. Click on 'Sign in&quot; in the upper right corner of the page. Just double click on it; don't select anything from the drop-down menu.</p>

  <p style="margin-left: 32px">
    <img src="/images/ebook/adobe sign in link.jpg" alt="Adobe sign in link" width="157" height="35" />
  </p>

  <p style="margin-left: 32px">C. Click on 'Don't have an Adobe ID'</p>

  <p style="margin-left: 32px">
    <img src="/images/ebook/adobe-log-in.jpg" alt="adobe-log-in-image" width="549" height="415" />
  </p>

  <p style="margin-left: 32px">D. Complete the log-in fields, check the 'Agree to Terms' box, and click 'Create.'</p>

  <p style="margin-left: 32px">
    <img src="/images/ebook/log in fill-in fields.jpg" alt="log in fill-in fields" width="609" height="447" />
  </p>

  <p style="margin-left: 32px">E. You can then complete the additional fields, or 'skip this.'</p>

  <p style="margin-left: 32px; margin-bottom: 32px">
    <img src="/images/ebook/welcome page.jpg" alt="welcome page" width="383" height="329" />
  </p>

  <p>Whichever button you click, you have completed registration and can exit the Adobe website. Now, let's download your e-reader.</p>
  <p style="margin-left: 32px">
    <a [routerLink]="['/support-ebook-ereader']" class="link">Click here to download your e-reader</a>
  </p>
  <p style="margin-left: 32px; margin-bottom: 32px">
    <a [routerLink]="['/support-ebook']" class="link">Click here to return to the previous page.</a>
  </p>
  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('top')">[back to top]</span> &nbsp;|&nbsp; <a [routerLink]="['/help']" class="link">[back to main support page]</a>
  </p>
  <p>
    If you cannot find the information you need,
    <a [routerLink]="['/contact-us']" class="link">click here</a> to send us a message.
  </p>
</app-content-balloon>
