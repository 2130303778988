import { Component, OnInit } from '@angular/core';
import { SchoolsService } from 'src/app/services/schools.service';
import { UserService } from 'src/app/user.service';
import { SimulationsService } from '../../services/simulations.service';

@Component({
  selector: 'app-mobile-simulations',
  templateUrl: './mobile-simulations.component.html',
  styleUrls: ['./mobile-simulations.component.css'],
})
export class MobileSimulationsComponent implements OnInit {
  showSimulationLoading = false;
  simulationPersonFirstName = '';
  simulationPersonLastName = '';
  simulationPersonEmail = '';
  schoolChoice = '';
  alreadyHaveUserInfo = false;
  simulationsList;
  schoolsList;

  constructor(private _simulationsService: SimulationsService, private _schoolsService: SchoolsService, private _userService: UserService) {}

  ngOnInit() {
    this._loadSchoolsList();
    this._loadSimulationsList();

    if (this._userService.loggedInUser) {
      this.alreadyHaveUserInfo = true;
      this.simulationPersonFirstName = this._userService.loggedInUser.firstName;
      this.simulationPersonLastName = this._userService.loggedInUser.lastName;
      this.simulationPersonEmail = this._userService.loggedInUser.email;
      this.schoolChoice = this._userService.loggedInUser.school;
    }
  }

  generatePlayerLink(caseSimulationId) {
    const ptr = this;

    if (ptr.simulationPersonFirstName != '' && this.simulationPersonLastName != '' && ptr.simulationPersonEmail != '' && ptr.simulationPersonEmail.match(/.*\@.*\..*/)) {
      ptr.showSimulationLoading = true;
      this._simulationsService
        .generatePlayerLink(caseSimulationId, this.simulationPersonFirstName + '_' + this.simulationPersonLastName + '_sid_' + this.schoolChoice, this.simulationPersonEmail)
        .subscribe({
          next(result: Array<any>) {
            // Safari on iOS does not have Array.prototype.includes or Array.prototype.indexOf
            if ((result.includes && result.includes('GeneratePlayerLinkResult')) || 'GeneratePlayerLinkResult' in result) {
              const url = result['GeneratePlayerLinkResult']['playerLink'];

              if (!window.open(url, '_blank')) {
                // Safari doesn't allow window.open here, set location manually.
                location.href = url;
              }
            } else {
              alert('Error: something went wrong');
            }
            ptr.showSimulationLoading = false;
          },
        });
    } else {
      alert('Name and VALID Email address must be entered prior to simulation');
    }
  }

  private _loadSimulationsList(): void {
    this._simulationsService.getSimulationsList().subscribe((simulations) => {
      this.simulationsList = simulations.content;
    });
  }

  private _loadSchoolsList(): void {
    this._schoolsService.getSchoolsList().subscribe((schools) => {
      this.schoolsList = schools;
    });
  }
}
