import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from '../contact.service';
import { StatusMessageResponse } from '../model/mms.model';
import { AuthService } from '../services/auth.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {
  @ViewChild('recaptcha') recaptcha: RecaptchaComponent;

  form: FormGroup;

  recaptchaResponse: string;
  recaptchaSucceeded = false;

  showSubmitContactButton = true;
  showRecaptcha = true;

  constructor(
    private _authService: AuthService,
    private _toast: ToastrService,
    private _userService: UserService,
    private contactService: ContactService,
    private router: Router,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this._authService.isAuthenticated().subscribe(isAuthenticated => {
      // Only s how recaptcha when not authenticated.
      this.showRecaptcha = !isAuthenticated;

      if (isAuthenticated && !this._authService.isIpAuth()) {
        console.log('getting logged in user');
        this._userService.getLoggedInUser().subscribe({
          next: (customer) => {
            this.form = this._formBuilder.group({
              firstName: customer.firstName,
              lastName: customer.lastName,
              zipCode: '' + customer.zip,
              country: customer.country,
              phoneNumber: customer.phone,
              email: customer.email,
              profession: customer.profession,
              professionOther: customer.professionOther,
              training: customer.training,
              specialty: customer.specialty,
              message: '',
            });
          },
        });
      } else {
        console.log('user is not logged in, or is IP auth');
        console.log('showRecaptcha: ' + this.showRecaptcha);
        this.form = this._formBuilder.group({
          firstName: '',
          lastName: '',
          zipCode: '',
          country: '',
          phoneNumber: '',
          email: '',
          profession: '',
          professionOther: '',
          training: '',
          specialty: '',
          message: '',
        });
      }
    });
  }

  handleCorrectCaptcha($event) {
    this.recaptchaSucceeded = true;
    this.recaptchaResponse = $event;
  }

  onCountryChanged($event) {
    this.form.controls['country'].patchValue($event);
  }

  doContact(): void {
    console.log('1');
    if (this.showRecaptcha && this.recaptchaSucceeded === false) {
      alert('Recaptcha Failed. Please try again.');
      return;
    }

    console.log('2');
    if (!this.form.valid) {
      return;
    }

    console.log('3');
    this.showSubmitContactButton = false;

    this.contactService.sendContactRequest(this.form.value, this.recaptchaResponse).subscribe({
      next: (response: StatusMessageResponse) => {
        if (response.success) {
          this._toast.info('Thank you, your message has been sent.');
          this.router.navigateByUrl('/landing');
        } else {
          this._toast.error(response.message);
          this.recaptcha?.reset();
          this.recaptchaSucceeded = false;
          this.recaptchaResponse = null;
          this.showSubmitContactButton = true;
        }
      },
      error: (msg) => {
        console.log(msg);
        this._toast.error('Could not submit contact request, please try again later.');
        this.recaptcha?.reset();
        this.recaptchaSucceeded = false;
        this.recaptchaResponse = null;
        this.showSubmitContactButton = true;
      },
    });
  }
}
