import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-support-ebook-adobe-id',
  templateUrl: './support-ebook-adobe-id.component.html',
  styleUrls: ['./support-ebook-adobe-id.component.css'],
})
export class SupportEbookAdobeIdComponent {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  scrollTo(id: string) {
    const element = this.document.getElementById(id);

    if (element) {
      element.scrollIntoView(true);
    }
  }
}
