<app-content-balloon>
  <h3>Registration - E-Mail Address Confirmation</h3>

  <div class="d-flex flex-column">
    <div class="content-section">
      <div class="content-section-content">
        <p *ngIf="!isRequiredDataAvailable()">
          Verification request could not be performed due to missing data. Please click on the link in your email or make sure you copied the full URL.
        </p>

        <div *ngIf="isSuccess()">Thank you for verifying your email address with us. Please close this window and return to the registration window to continue registration.</div>

        <div *ngIf="isFailure()">
          Sorry, we were unable to confirm the email address you provided with the information provided. Please submit a new verification request and try again.
        </div>

        <div *ngIf="processing" class="center overlay-panel">
          <div style="flex: 0 0 auto">
            <mat-progress-spinner class="center" color="primary" mode="indeterminate" [diameter]="48"></mat-progress-spinner>
          </div>
          <div class="overlay-text">Validating . . .</div>
        </div>
      </div>
    </div>
  </div>
</app-content-balloon>
