<app-content-balloon width="600px">
  <app-otp
      #otp
      title="Forgot Password"
      [verify]="doVerification.bind(this)"
      [doSendVerificationCode]="sendVerificationCode.bind(this)"
      [doAction]="doAction.bind(this)"
      [onActionSuccess]="onActionSuccess.bind(this)"
      [hasActionStep]="true"
      [emailAddress]="form.controls.emailAddress.value"
      [dataIsValid]="form.controls.emailAddress.valid"
      [actionDataIsValid]="passwordForm.valid"
      buttonTitle="Reset Password"
    >
    <div class="d-flex flex-column" initial>
      <form [formGroup]="form">
        <p>Please provide the email address associated with your account.</p>

        <div class="content-section-content">
          <mat-form-field appearance="standard">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="emailAddress" (keyup.enter)="otp.buttonClicked()" />
          </mat-form-field>
        </div>
      </form>
    </div>

    <div class="d-flex flex-column" action>
      <form [formGroup]="passwordForm">
        <p>Please enter a new password and confirm.</p>

        <div class="content-section-content">
          <mat-form-field>
            <mat-label>Password</mat-label>
            <mat-hint *ngIf="this.passwordForm.controls['password'].value === ''">Must be 8-200 characters in length, contain one uppercase, one lowercase, one number and one special character</mat-hint>
            <input matInput type="password" maxlength="200" formControlName="password" required />
            <mat-error *ngIf="hasError('password')">{{ getError('password') }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input matInput type="password" maxlength="200" formControlName="confirmPassword" required />
            <mat-error *ngIf="hasError('confirmPassword')">{{ getError('confirmPassword') }}</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </app-otp>
</app-content-balloon>
