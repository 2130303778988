import { Component } from "@angular/core";

@Component({
  selector: "app-support-ebook-ereader",
  templateUrl: "./support-ebook-ereader.component.html",
  styleUrls: ["./support-ebook-ereader.component.css"],
})
export class SupportEbookEreaderComponent {
  constructor() {}
}
