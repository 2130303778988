<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<table
  style="
    background-color: #fff;
    border-radius: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  "
  cellpadding="20"
>
  <tr>
    <td>
      <h3>
        <a name="Anchor-39783"></a>Support -<b
          >Downloading and Viewing e-book</b
        >
      </h3>
      <p><strong>4. How to navigate through our e-textbook.</strong></p>
      <p>
        Our textbook has built-in hyperlinks so you can jump to any chapter
        desired, and even to the specific part of any chapter to check a
        technique quickly. Here's how. We will demonstrate using the iPhone5.
      </p>
      <p>
        A. Once the textbook is downloaded, it will display in your e-reader
        looking like this:
      </p>
      <p>
        <img
          src="/images/ebook/nav-1.jpg"
          alt="RAG icon"
          width="159"
          height="258"
        />
      </p>
      <p>
        B. Tap on it and it will open in your e-reader. The book will open onto
        the same color cover page. Swipe with your finger from right to left to
        move through the pages. You can reach our Table of Contents in two ways.
      </p>
      <p>
        C. In the first method: Simply tap on any page. Control icons will
        appear. Tap on the book symbol:
      </p>
      <p>
        <img
          src="/images/ebook/nav-2.jpg"
          alt="Pop-up TOC"
          width="428"
          height="708"
        />
      </p>
      <p>
        D. The Table of Contents will pop up. You can scroll up and down the
        list with your finger. Tap on any chapter and you will jump to that
        chapter's Rapid Index.
      </p>
      <p>
        <img
          src="/images/ebook/support-ebook-nav_SNAGHTML243817a.PNG"
          alt="Pop-up TOC"
          width="426"
          height="711"
          border="0"
        />
      </p>
      <p>
        E. In the second method, simply swipe through the pages to reach the
        book's own Table of Contents. At this point, turn the iPhone sideways as
        our book shows two pages at a time.
      </p>
      <p><img src="/images/ebook/nav-3.png" width="601" height="328" /></p>
      <p>
        F. Enlarge the image by 'zooming' with your fingers. (You won't have to
        do this if you are using a tablet with a larger screen.)
      </p>
      <p>
        <img
          src="/images/ebook/support-ebook-nav_SNAGHTML227fc82.PNG"
          alt="TOC zoom"
          width="601"
          height="398"
          border="0"
        />
      </p>
      <p>
        G. If you tap on the desired chapter, using the book or the pop-up Table
        of Contents, you will jump to that chapter. Tap on 'Thorax and Lungs.'
      </p>
      <p>
        <img
          src="/images/ebook/support-ebook-nav_SNAGHTML22b9ddf.PNG"
          alt="Thorax and lungs"
          width="603"
          height="524"
          border="0"
        />
      </p>
      <p>H. Zoom in again to your desired selection.</p>
      <p>
        <img
          src="/images/ebook/support-ebook-nav_SNAGHTML22de6ff.PNG"
          alt="T&amp;L Rapid Index"
          width="599"
          height="340"
          border="0"
        />
      </p>
      <p>
        I. Tap on your desired selection. For this example, tap on 'Scoliosis
        Inspection.' You will jump to that section of the chapter.
      </p>
      <p>
        <img
          src="/images/ebook/support-ebook-nav_SNAGHTML232502d.PNG"
          alt="Scoliosis inspection"
          width="604"
          height="524"
          border="0"
        />
      </p>
      <p>
        J. To see more of the chapter, swipe with your finger forwards or
        backwards.
      </p>
      <p>
        K. To jump to a diffferent section, tap the page to access the Table of
        Contents icon again, or use the scroll bar at the bottom of the screen.
      </p>
      <p>
        <img
          src="/images/ebook/support-ebook-nav_SNAGHTML2388d8a.PNG"
          alt="Scroll bar"
          width="430"
          height="99"
          border="0"
        />
      </p>
      <p>
        <a href="#Anchor-39783">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      If you cannot find the information you need,
      <a [routerLink]="['/contact-us']"
        ><b><font color="#0033cc">click here</font></b></a
      >
      to send us a message.<br />

      <br />
      <br />
      <br />
      <br />
    </td>
  </tr>
</table>
