import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-content-balloon',
  templateUrl: './content-balloon.component.html',
  styleUrls: ['./content-balloon.component.css'],
})
export class ContentBalloonComponent implements AfterViewInit {
  @HostBinding('style.display') display = 'flex';
  @HostBinding('style.flex') flex = '1 0 auto';
  @HostBinding('style.flex-direction') flexDirection = 'column';

  @ViewChild('cb', { read: ElementRef }) containerElement: ElementRef;

  @Input() width = '85%';
  @Input() showOverlay = false;
  @Input() overlayText: string;

  @Output() maxHeightChanged = new Subject<string>();

  maxHeight = '100px';

  private _windowHeight;
  private _maxHeightValue;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('window:resize', ['$event']) onResize(event) {
    this._recalculateMaxHeight();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this._recalculateMaxHeight(), 0);
  }

  private _recalculateMaxHeight(): void {
    this._windowHeight = document.documentElement.clientHeight;
    this._maxHeightValue = this._windowHeight - this.containerElement.nativeElement.offsetTop - 110;
    this.maxHeight = this._maxHeightValue + 'px';
    this._changeDetectorRef.detectChanges();
    this.maxHeightChanged.next(this.maxHeight);
  }
}
