<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<table style="background-color: #fff; border-radius: 10px; width: 80%; margin-left: auto; margin-right: auto;" cellpadding="20">
  <tr>
    <td>

      <h3><a name="Anchor-39783"></a>Support -<b>Downloading and Viewing e-book</b></h3>
      <p><strong>2. Download the specialized e-reader software.</strong></p>
      <p>Our textbook cannot be viewed using a Kindle or Nook reader. Free specialized software is available for your Mac, PC, smartphone, iPad or Android tablet. Here is how to download it.</p>
      <p>First, <u>please be sure you have an Adobe ID and password</u>. Click <a [routerLink]="['/support-ebook-adobe-id']"><b><font color="#0033cc">here</font></b></a> if you don't.</p>
      <p>A. For Mac or PC computers, we recommend using Adobe Digital Editions. To install, click <a [routerLink]="['/support-ebook-ereader-ae']"><b><font color="#0033cc">here</font></b></a>.</p>
      <p>B. For Apple IOS devices (iPad, iPhone, etc) or Android devices (smartphone or tablet), we recommend using Bluefire Reader. To install, click <a [routerLink]="['/support-ebook-ereader-bf']"><b><font color="#0033cc">here</font></b></a>.</p>
      <p><a href="#Anchor-39783">[back to top] </a><a [routerLink]="['/help']"> [back to main support page]</a></p>
      <p></p>
      <p>If you cannot find the information you need, <a [routerLink]="['/contact-us']"><b><font color="#0033cc">click here</font></b></a> to send us a message.<br>
      </p>

      <br />
      <br />
      <br />
      <br />

    </td>
  </tr>
</table>
