import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalVarsService {
  public lastLoggedInResponse: any = {};
  public agreedToIpAuthTermsAndConditions = false;
  public authMethod = '';
  public isLoggedIn = false;

  constructor() {}
}
