import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, Subject } from 'rxjs';
import { OtpDialogData, RegistrationStep, SubscriptionUserDetails } from 'src/app/model/mms.model';
import { OtpDialogComponent } from 'src/app/otp-dialog/otp-dialog.component';
import { PasswordValidator } from 'src/app/password-validator';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { StaticPagesService } from 'src/app/static-pages.service';
import { passwordsMatch } from 'src/app/validators/validators';

@Component({
  selector: 'app-register-user-details',
  templateUrl: './user-details-step.component.html',
  styleUrls: ['../register.component.css'],
  providers: [{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
})
export class UserDetailsComponent implements OnInit, RegistrationStep {
  readonly showClearButton = true;
  readonly showPreviousButton = true;

  showNextButton = true;
  showSubmitButton = false;

  @ViewChild('recaptcha') recaptcha: RecaptchaComponent;

  @Input() set userDetails(details: SubscriptionUserDetails) {
    this.form.patchValue(details);
  }

  @Input() set isLastStep(value: boolean) {
    this.showNextButton = !value;
    this.showSubmitButton = value;
  }

  @Output() dataTokenUpdated: Subject<string> = new Subject<string>();

  content: any;
  form: FormGroup;
  recaptchaResponse: any;

  private _recaptchaResolved = false;
  private _invalidEmailAddresses = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _staticPages: StaticPagesService,
    private _sanitizer: DomSanitizer,
    private _passwordValidator: PasswordValidator,
    private _subscriptionService: SubscriptionService,
    private _dialog: MatDialog,
    private _toast: ToastrService,
    private _el: ElementRef
  ) {
    this.form = this._formBuilder.group(
      {
        title: [],
        firstName: [],
        middleName: [],
        lastName: [],
        birthDate: [],
        address1: [],
        address2: [],
        city: [],
        state: [],
        zip: [],
        country: ['', [Validators.required]],
        telephone: [],
        faxNumber: [],
        emailAddress: ['', [Validators.email, this._validateEmailNotInUse.bind(this)]],
        profession: [],
        professionOther: [],
        schoolName: [],
        training: [],
        specialty: [],
        emailUpdates: [],
        emailOptIn: [],
        password: ['', [this._passwordValidator.validateForForm.bind(this._passwordValidator)]],
        confirmPassword: [],
        referral: [],
        acceptedTermsAndConditions: [],
        dataTokenId: [],
        emailValidationRequired: [],
      },
      {
        validators: passwordsMatch('password', 'confirmPassword'),
      },
    );
  }

  ngOnInit(): void {
    this._staticPages.getPageHtml('site_license_terms').then((content) => {
      this.content = this._transform(
        content
          .replace(/<body>/gi, '')
          .replace(/<\/body>/gi, '')
          .replace(/<html>/gi, '')
          .replace(/<\/html>/gi, '')
          .replace(/<br \/>/gi, ''),
      );
    });
  }

  getUserDetails(): SubscriptionUserDetails {
    return this.form.getRawValue() as SubscriptionUserDetails;
  }

  clear() {
    this.recaptchaResponse = null;
    this.recaptcha.reset();
    this.form.reset();
  }

  hasError(controlName: string): boolean {
    switch (controlName) {
      case 'confirmPassword':
        const control = this.form?.controls['confirmPassword'];

        if (this.form?.controls['password'].valid && this.form?.errors?.passwordsMatch) {
          control.setErrors({ passwordsMatch: this.form.errors?.passwordsMatch });

          return true;
        } else {
          control.setErrors({ passwordsMatch: null });
          control.updateValueAndValidity();

          if (control.errors?.required || control.errors?.passwordsMatch) {
            return true;
          }
        }
        break;
      default:
        return this.form?.controls[controlName]?.invalid || false;
    }
  }

  getError(controlName: string): string {
    const formErrors = this.form?.errors;
    const errors = this.form?.controls[controlName]?.errors;

    if (errors || formErrors) {
      switch (controlName) {
        case 'password':
          return errors?.password;
        case 'confirmPassword':
          if (errors?.passwordsMatch) {
            return 'Passwords do not match';
          }
          break;
        case 'emailAddress':
          if (errors?.emailNotInUse) {
            return 'This email address is already in use';
          }
          break;
        default:
          return null;
      }
    }

    return '';
  }

  isSubmitDisabled(): boolean {
    return !(this.showSubmitButton && this.isValid());
  }

  isValid() {
    return this.form.valid && this._recaptchaResolved;
  }

  isOtherProfession() {
    return this.form?.controls['profession']?.value === 'Other';
  }

  getCountry() {
    return this.form?.controls['country']?.value;
  }

  onCountryChanged(val) {
    this.form?.controls['country']?.patchValue(val);
  }

  preNext(): Observable<boolean> {
    return from(this._verifyEmailAddress());
  }

  preSubmit(): Observable<boolean> {
    return from(this._verifyEmailAddress());
  }

  handleCorrectCaptcha(response) {
    this._recaptchaResolved = response !== null;
    this.recaptchaResponse = response;
  }

  isNextDisabled(): boolean {
    return !this.isValid();
  }

  private _transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  private async _verifyEmailAddress(): Promise<boolean> {
    const emailAddress = this.form.controls['emailAddress'].value;
    const firstName = this.form.controls['firstName'].value;
    const lastName = this.form.controls['lastName'].value;

    const dialogRef = this._dialog.open(OtpDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        emailAddress: this.form.controls.emailAddress.value,
        sendVerificationCode: () => this._subscriptionService.sendEmailVerifyCode(firstName, lastName, emailAddress, this.recaptchaResponse),
        verify: (code) => this._subscriptionService.verifyEmailCode(emailAddress, code),
        onVerifyFailure: () => {
          this._invalidEmailAddresses.push(emailAddress.toLocaleLowerCase());
          this.form.controls['emailAddress'].setErrors({ emailNotInUse: 'This email address is already in use' });
          this.form.updateValueAndValidity();
          this._scrollToFirstInvalidControl();
        }
      },
    });

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((response: OtpDialogData) => {
        this.recaptcha.reset();

        if (!response.success && response.message && response.message !== 'user canceled') {
          this._toast.error(response.message);
        }

        if (response.dataToken) {
          this.dataTokenUpdated.next(response.dataToken);
        }

        return response.success;
      })
      .catch(() => {
        return Promise.resolve(false);
      });
  }

  private _validateEmailNotInUse(control: AbstractControl): ValidationErrors | null {
    const email = control.value;
    let result;

    if (this._invalidEmailAddresses?.indexOf(email.toLocaleLowerCase()) != -1) {
      result = { valid: false, message: 'This email address is already in use' };
    } else {
      result = { valid: true, message: '' };
    }

    return !result.valid ? { emailNotInUse: result.message } : null;
  }

  private _scrollToFirstInvalidControl() {
    const elements = document.getElementsByClassName('ng-invalid');
    const container = document.getElementsByClassName('stepper-content')[0] as HTMLElement;

    if (elements?.length > 0) {
      const firstInvalidControl = elements[0] as HTMLElement;

      container.scroll({
        top: firstInvalidControl.offsetTop - 50,
        left: 0,
        behavior: "smooth"
      });
    }
  }
}
