<app-content-balloon #cb [showOverlay]="showOverlay" [overlayText]="overlayText" width="85%">
  <div class="d-flex flex-column flex-1-0-auto" style="overflow: hidden" [style.maxHeight]="cb.maxHeight">
    <div class="stepper-content" [style.maxHeight]="maxHeight">
      <mat-stepper linear #stepper>
        <mat-step>
          <app-register-subscription-selection #subscriptionSelection [subscriptionInfo]="registrationRequest.subscriptionInfo"></app-register-subscription-selection>
        </mat-step>
        <mat-step>
          <app-register-user-details #userDetails [userDetails]="registrationRequest.userDetails" (dataTokenUpdated)="onDataTokenUpdated($event)" [isLastStep]="!requiresPayment()"></app-register-user-details>
        </mat-step>
        <mat-step *ngIf="requiresPayment()">
          <app-register-payment #payment [subscriptionInfo]="registrationRequest.subscriptionInfo"></app-register-payment>
        </mat-step>
      </mat-stepper>
    </div>

    <div class="button-bar">
      <button mat-raised-button app-debounce-click *ngIf="showClearButton()" (debounceClick)="onClear()">Clear</button>
      <button mat-raised-button app-debounce-click *ngIf="showPreviousButton()" (debounceClick)="onPrevious()">Previous Step</button>
      <button mat-raised-button app-debounce-click [disabled]="isNextDisabled()" *ngIf="showNextButton()" (debounceClick)="onNext()" color="primary">Next Step</button>
      <button mat-raised-button app-debounce-click [disabled]="isSubmitDisabled() || forceDisableSubmit" *ngIf="showSubmitButton()" (debounceClick)="onSubmit()" color="primary">Submit Registration</button>
    </div>
  </div>
</app-content-balloon>
