import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordsMatch(passwordControlName: string, confirmPasswordControlName: string): (AbstractControl) => ValidationErrors | null {
  return (control) => {
    const password = control.get(passwordControlName).value;
    const confirm = control.get(confirmPasswordControlName).value;

    return password !== confirm ? { passwordsMatch: 'passwords do not match' } : null;
  };
}

export function validateDateInFuture(monthControlName: string, yearControlName: string): (AbstractControl) => ValidationErrors | null {
  return (control) => {
    // Only validate the full date if both controls have selected values.
      const month = Number(control.get(monthControlName).value);
      const year = Number(control.get(yearControlName).value);
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      // If current month is less than selected month, then date is in the future.
      // If current year is less  than selected year, then date is in the future.
      // If current month equals selected month and current year equals selected year, then date is "in" the future.
      if (currentMonth < month || currentYear < year || currentMonth === month && currentYear === year) {
        control.controls[yearControlName].setErrors(null);

        return null;
      }

      control.controls[yearControlName].setErrors({ dateInFuture: 'Expiration date must be in the future' });

      return { dateInFuture: 'Expiration date must be in the future' };
  }
}

export function validExpirationDate(control: AbstractControl): ValidationErrors | null {
  const value = control.value as string;

  if (value.length > 3) {
    const matches = value.match(/^(0?[1-9]|1[0-2])\/?(\d{2})$/);

    if (matches) {
      const today = new Date();
      const currentMonthYear = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0);
      const monthString = matches[1];
      const yearString = matches[2]
      const month = parseInt(monthString, 10);
      const year = parseInt(yearString, 10);

      // Ensure month is in valid range
      if (month <= 0 || month > 12) {
        return { validExpirationDate: 'month must be in the range of 1-12' };
      }

      // Ensure that the provided year is in the future
      if ((2000 + year) < today.getFullYear()) {
        return { validExpirationDate: 'year must be in the future' };
      }

      // Ensure that the MMYY combo is in the future
      const provided = new Date(2000 + year, month - 1, 1, 0, 0, 0);

      if (provided < currentMonthYear) {
        return { validExpirationDate: 'expiration date must be in the future' };
      }

      // provided date is valid.
      return null;
    } else {
      return { validExpirationDate: 'please enter as MM/YY or MMYY' };
    }
  }

  return { validExpirationDate: 'incomplete, please enter expiration date as MMYY'};
}
