import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { PaymentDetails, RegistrationStep, SubscriptionInfo } from 'src/app/model/mms.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { validateDateInFuture } from 'src/app/validators/validators';

@Component({
  selector: 'app-register-payment',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss'],
  providers: [{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
})
export class PaymentComponent implements RegistrationStep {
  showClearButton = true;
  showPreviousButton = true;
  showNextButton = false;
  showSubmitButton = true;
  years = [];

  @Input() subscriptionInfo: SubscriptionInfo;

  isVisa = false;
  isMastercard = false;
  isAmex = false;
  isDiscover = false;

  form: FormGroup;

  checkoutTotal: number;

  constructor(private _formBuilder: FormBuilder, private _subscriptionService: SubscriptionService, private _changeDetectorRef: ChangeDetectorRef) {
    this.form = _formBuilder.group({
      cardNumber: ['', []],
      expirationDateMonth: ['', []],
      expirationDateYear: ['', []],
      cardSecurityCode: ['', []],
    }, {
      validators: validateDateInFuture('expirationDateMonth', 'expirationDateYear')
    });
  }

  onActivate(): void {
    const year = new Date().getFullYear();

    this.years = [];

    for (var i = 0; i <= 8; i++) {
      this.years.push('' + (year + i));
    }

    if (this.subscriptionInfo && !this.subscriptionInfo.subscriptionOptionCode && this.subscriptionInfo.subscriptionOptionId >= 0) {
      this._subscriptionService.getTotalCost(this.subscriptionInfo.subscriptionOptionId).subscribe(response => {
        this.checkoutTotal = response;
      })
    }
  }

  hasExpirationDateError(): boolean {
    const yearControl = this.form?.controls['expirationDateYear'];
    const monthControl = this.form?.controls['expirationDateMonth'];

    if (!!!yearControl || !!!monthControl) {
      return false;
    }

    console.log('month: ' + monthControl.valid);
    console.log('year: ' + yearControl.valid);
    console.log('form: ' + this.form?.errors?.dateInFuture);

    const inError = monthControl.valid && yearControl.valid && !!this.form?.errors?.dateInFuture;

    if (inError) {
      yearControl.setErrors({ dateInFuture: this.form.errors?.dateInFuture})
      yearControl.updateValueAndValidity();
    } else {
      yearControl.setErrors({ dateInFuture: null });
      yearControl.updateValueAndValidity();
    }

    return inError;
  }

  hasError(controlName: string): boolean {
    return this.form?.controls[controlName]?.invalid || false;
  }

  getError(controlName: string): string {
    const errors = this.form?.controls[controlName]?.errors;

    return errors.validExpirationDate;
  }

  updateCardType() {
    const ccNumber = this.form.controls['cardNumber']?.value;

    this.isMastercard = /^5[1-5]/.test(ccNumber);
    this.isVisa = /^4/.test(ccNumber);
    this.isAmex = /^3[47]/.test(ccNumber);
    this.isDiscover = /^6(011|5)/.test(ccNumber);
  }

  getPaymentDetails(): PaymentDetails {
    const value = this.form.getRawValue() as PaymentDetails;
    const month = '' + this.form.getRawValue().expirationDateMonth;
    const year = Number(this.form.getRawValue().expirationDateYear);
    const year2 = '' + (year - (Math.floor(year / 100) * 100));

    value.expirationDate = month + year2;

    return value;
  }

  isGift(): boolean {
    return this.form.controls['isGift'].value;
  }

  isSubmitDisabled(): boolean {
    if (this.subscriptionInfo?.subscriptionOptionCode) {
      return false;
    }

    return !this.form.valid;
  }

  clear(): void {
    this.form.reset();
  }
}
