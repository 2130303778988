<app-content-balloon [showOverlay]="showSimulationLoading" width="80vw" overlayText="Loading Simulation . . .">
  <h3>Simulations</h3>
  <div *ngIf="!alreadyHaveUserInfo">
    Please enter your Name and Email address to run simulations.<br /><br />
    First Name <input type="text" [(ngModel)]="simulationPersonFirstName" /><br /><br />
    Last Name <input type="text" [(ngModel)]="simulationPersonLastName" /><br /><br />
    Email <input type="text" [(ngModel)]="simulationPersonEmail" /><br /><br />
    Choose School
    <select [(ngModel)]="schoolChoice">
      School
      <option value="{{ school.id }}" *ngFor="let school of schoolsList | async">{{ school.company }}</option>
    </select>
  </div>

  <h4>Available Programs...</h4>
  <div *ngFor="let simulation of simulationsList | async" class="simulation">
    <a style="cursor: pointer; text-decoration: underline" (click)="generatePlayerLink(simulation.case_aka_simulation_id)">{{ simulation.caption }}</a>
  </div>
</app-content-balloon>
