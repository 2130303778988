<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<table
  style="
    background-color: #fff;
    border-radius: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  "
  cellpadding="20"
>
  <tr>
    <td>
      <h3>
        <a name="Anchor-39783"></a>Support -<b
          >Downloading and Viewing e-book</b
        >
      </h3>
      <p><strong>3. Download our textbook into the e-reader.</strong></p>
      <p><strong>How to download our e-textbook:</strong></p>
      <p>We will use the iPhone5 as an example.</p>
      <p>
        Once you purchase an e-copy of our textbook, you will receive a download
        link.
      </p>
      <p>This download link will arrive as an email message.</p>
      <p>
        You <u>must</u> open this email message on the device where you have
        your e-reader installed.
      </p>
      <p>Once you click on it, you will see a screen similar to this:</p>
      <p>
        <img
          src="/images/ebook/edition-guard-page.jpg"
          width="413"
          height="641"
        />
      </p>
      <p>Scroll down to see the following, then tap on the link.</p>
      <p><img src="/images/ebook/acsm link.jpg" width="412" height="475" /></p>
      <p>
        If you have several choices of e-readers, select Bluefire Reader for
        your portable device or Adobe Digital Editions for your Mac or Windows
        PC. In this case, click on 'Open in Bluefire Reader'.
      </p>
      <p>
        On a mobile device, you will then need to choose your e-reader. If more
        than one reader is present, click on 'open with':
      </p>
      <p><img src="/images/ebook/open in 1.jpg" width="414" height="167" /></p>
      <p>
        Select the Bluefire e-reader (unless you already have DL Reader on your
        device)
      </p>
      <p><img src="/images/ebook/open in 2.jpg" width="414" height="391" /></p>
      <p>Then, enter your Adobe Editions user ID and password:</p>
      <p>
        <img src="/images/ebook/bluefire login.jpg" width="418" height="369" />
      </p>
      <p>
        The e-book should then start downloadinig. Please have patience. It can
        take a few minutes.
      </p>
      <p>
        <img src="/images/ebook/downloading.jpg" width="407" height="396" />
      </p>
      <p>
        To learn how to navigate through our textbook, click
        <a href="/static-pages?target=support-ebook-nav"
          ><b><font color="#0033cc">here</font></b></a
        >
        .
      </p>
      <p>
        <a href="/static-pages?target=support-ebook-ereader" target="_parent"
          >Click <b><font color="#0033cc">here</font></b> to</a
        >
        <a [routerLink]="['/support-ebook-ereader']"
          >return to the previous page.</a
        >
      </p>
      <p>
        <a href="#Anchor-39783">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      <p></p>
      <p>
        If you cannot find the information you need,
        <a [routerLink]="['/contact-us']"
          ><b><font color="#0033cc">click here</font></b></a
        >
        to send us a message.<br />
      </p>

      <br />
      <br />
      <br />
      <br />
    </td>
  </tr>
</table>
