import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

export function getTemplate(templateName: string): string {
  return `${templateName}.${environment.envId}.html`;
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function getBaseApiUrl() {
  return `${getBaseUrl()}api/`;
}

export function getBaseWsUrl() {
  let baseUrl = getBaseUrl();
  baseUrl = baseUrl.substring(baseUrl.indexOf(':'), baseUrl.length);
  return `ws${baseUrl}mms-websocket`;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'BASE_WS_URL', useFactory: getBaseWsUrl, deps: [] },
  { provide: 'BASE_API_URL', useFactory: getBaseApiUrl, deps: [] },
];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
