import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BasePlayerComponent } from 'src/app/base-player.component';
import { Menu2Service } from 'src/app/services/menus.service';

@Component({
  selector: 'app-mobile-video-playback',
  templateUrl: './mobile-video-playback.component.html',
  styleUrls: ['./mobile-video-playback.component.css'],
})
export class MobileVideoPlaybackComponent extends BasePlayerComponent {
  currentMenuHierarchy;
  showTopMenu = true;
  topLevelMenuTitle = 'Programs';
  menuNavHistory = [];

  constructor(
    protected menuService: Menu2Service,
    protected _sanitizer: DomSanitizer,
    protected dialog: MatDialog,
  ) {
    super(menuService, _sanitizer, dialog, true);
  }

  selectCurrentMenu(menuItem: any, force = false) {
    if ((this.showTopMenu && this.topLevelMenuTitle === 'Programs') || force || menuItem.available_in_demo === '1') {
      this.currentMenuHierarchy = menuItem.subMenus;
      this.showTopMenu = false;
      this.topLevelMenuTitle = menuItem.title;
      this.menuNavHistory.push(menuItem);
    }
  }

  goBack() {
    this.showVideo = false;
    this.playerVisible = false;
    let lastMenu = this.menuNavHistory.pop();

    if (lastMenu) {
      lastMenu = this.menuNavHistory.pop();
    }

    if (lastMenu) {
      this.selectCurrentMenu(lastMenu, this.menuNavHistory.length === 0);
    } else {
      this.showTopMenu = true;
      this.topLevelMenuTitle = 'Programs';
    }
  }

  playVideo(pageItem) {
    //super.playVideoInIframe(pageItem);
  }
}
