import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MmslogService {
  constructor(
    private http: HttpClient,
    @Inject("BASE_API_URL") private baseApiUrl: string
  ) {}

  postVideoLog(pageItemId: number) : Observable<any> {
    return this.http.post(`${this.baseApiUrl}logs/video/${pageItemId}/`, {});
  }
}
