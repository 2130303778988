<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<table
  style="
    background-color: #fff;
    border-radius: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  "
  cellpadding="20"
>
  <tr>
    <td>
      <h3><a name="anchor"></a>Support - <b>Problems Logging In</b></h3>
      <p><b>1. I am having problems registering on the website.</b></p>
      <p>
        To register or subscribe to our website, always go directly to our site
        at
        <a href="https://medicalmediasystems.com" target="_new"
          ><font color="#0000cc"><b>medicalmediasystems.com</b></font></a
        >. Do not use any link on your school's website to reach us for
        registration as linking can interfere with secure transactions. However,
        once you have registered, our site will usually operate correctly
        through school links.
      </p>
      <p><b>2. I registered but I cannot log in.</b></p>
      <p>
        If you paid by credit card and your card information was correct, you
        will also receive an email message welcoming you to our website. This
        message contains the user name and password you chose during
        registration. If your credit card information was incorrect, you will
        see an error message on our screen and you will not receive the
        confirming email message. Because your attempt to register was
        unsuccessful, we could not open a subscription account for you. In this
        situation, please check your credit card information and try again.
      </p>
      <p>
        <a href="#anchor">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      <p>
        <b
          >3. I was able to log in to your website before but now it is not
          working.
        </b>
      </p>
      <p>
        If this occurs, be sure you are entering the correct user name and
        password. Remember that these are &quot;case sensitive.&quot;&nbsp;That
        means you must use capital or small letters, just as you did when you
        created your user name and password. If you created your user name in
        all small (lower case) letters, you will always have to enter your user
        name the same way. This also applies to your password. If you are
        entering this information correctly and you still cannot enter our
        website, please
        <font color="#0000cc"
          ><b><a [routerLink]="['/contact-us']">contact us</a></b
          >.</font
        >
      </p>
      <p>
        <a href="#anchor">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      <p></p>
      <p>
        If you cannot find the information you need,
        <a [routerLink]="['/contact-us']"
          ><b><font color="#0033cc">click here</font></b></a
        >
        to send us a message.<br />
      </p>
    </td>
  </tr>
</table>
