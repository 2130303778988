import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalVarsService } from '../global-vars.service';
import { SubscriptionType } from '../model/mms.model';
import { AuthService } from '../services/auth.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css'],
})
export class MainMenuComponent implements OnInit {
  isAuthenticated = false;

  username = '';
  password = '';
  staySignedIn = false;
  isIpAuth = true;

  logoWidth = '200px';
  accountCss: string = 'white-text';
  videoPlaybackCss: string = 'white-text';
  programsCss: string = 'white-text';
  simulationsCss: string = 'white-text';
  textbookCss: string = 'white-text';
  registerCss: string = 'white-text';
  helpCss: string = 'white-text';
  showAccountMenu = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public globalVarsService: GlobalVarsService,
    public authService: AuthService,
    public userService: UserService,
    @Inject('BASE_URL') private baseUrl: string,
  ) {
    if (environment.envId === 'tablet') {
      this.logoWidth = '100px';
    }
  }

  ngOnInit() {
    this.username = '';
    this.password = '';
    this.staySignedIn = false;

    this.authService.loginStatusChanged.subscribe((isAuthenticated: boolean) => {
      console.log(`authentication status changed: ${isAuthenticated}`);

      this.isAuthenticated = isAuthenticated;
      this.isIpAuth = this.authService.isIpAuth();
      this.checkSubscriptionType();
      this._handleLoginChanged();
    });

    this.authService.isAuthenticated().subscribe((result) => (this.isAuthenticated = result));
    this.isIpAuth = this.authService.isIpAuth();
    this.setMenuHighlight(this.location.path().slice(1));
    this.checkSubscriptionType();
  }

  isDefined<T>(a: T | null | undefined): a is T {
    return a !== null && a !== undefined;
  }

  setMenuHighlight(option: string): void {
    if (option.indexOf('target=textbook') > 0) {
      this['textbookCss'] = 'white-text-hover';
    } else if (option.indexOf('target=help') > 0) {
      this['helpCss'] = 'white-text-hover';
    } else {
      this[option + 'Css'] = 'white-text-hover';
    }
  }

  navigate(option: string): void {
    this.accountCss = 'white-text';
    this.videoPlaybackCss = 'white-text';
    this.programsCss = 'white-text';
    this.simulationsCss = 'white-text';
    this.textbookCss = 'white-text';
    this.registerCss = 'white-text';
    this.helpCss = 'white-text';

    this.setMenuHighlight(option);
    this.router.navigateByUrl('/' + option);
  }

  clearMenuSelection(): void {}

  login(): void {
    this.authService.login(this.username, this.password, this.staySignedIn);
    this.username = '';
    this.password = '';
    this.staySignedIn = false;
  }

  checkSubscriptionType(): void {
    console.log('showAccountMenu...');
    if (this.isAuthenticated) {
      console.log('checking auth service...');

      this.authService.getSubscriptionType().pipe(
        map((subscriptionType) => {
          console.log('Subscription type is: ' + subscriptionType);

          this.showAccountMenu = subscriptionType === SubscriptionType.RECURRING || subscriptionType === SubscriptionType.STANDARD;
        }),
      ).subscribe();
    } else {
      this.showAccountMenu = false;
    }
  }

  private _handleLoginChanged(): void {
    if (this.isAuthenticated) {
      this.programsCss = 'white-text-hover';
      this.router.navigateByUrl('/programs');
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
