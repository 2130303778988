<div>
  <!-- Subscription Type Selection -->
  <h3>Registration Form</h3>

  <div class="instructions">PLEASE ENTER EITHER A PAYMENT PLAN OR GROUP CODE, NOT BOTH (Click 'Clear' button to reset choice).</div>

  <div class="d-flex flex-column" style="padding-top: 16px">
    <!-- Header -->
    <div class="d-flex flex-row">
      <div class="app-table-header"><div class="center">SUBSCRIPTION OPTION</div></div>
    </div>

    <form [formGroup]="form">
      <!-- Pricing Options -->
      <div *ngIf="showSubscriptionOptions()">
        <mat-radio-group formControlName="subscriptionOptionId" aria-label="Select a subscription option">
          <div class="d-flex flex-row" *ngFor="let pricingOption of pricingOptions">
            <div class="left-column">
              <mat-radio-button [value]="pricingOption.id">${{ pricingOption.price }}</mat-radio-button>
            </div>
            <div class="right-column">
              <div class="option-title">{{ pricingOption.title }}</div>
              <div class="option-description">{{ pricingOption.description }}</div>
            </div>
          </div>
        </mat-radio-group>
      </div>

      <!-- Group Membership Code -->
      <div *ngIf="showGroupMembershipCode()" class="d-flex flex-row">
        <div class="left-column">
          <mat-form-field appearance="outline">
            <input matInput formControlName="subscriptionOptionCode" placeholder="Group Membership Code" />
          </mat-form-field>
        </div>
        <div class="right-column">
          <div class="option-title">Group Membership Code</div>
          <div class="option-description">
            <div>This is the code given to you by your group administrator.</div>
            <div style="margin-top: 32px">(Institutions: <a [routerLink]="['/contact-us']" class="link">contact us</a> for rate information.)</div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="center" style="margin-top: 16px"><a [routerLink]="['/textbook']" class="link">Please click here to purchase our textbook separately.</a></div>
</div>
