<app-content-balloon width="80vw">
  <h3>How To Use</h3>
  <strong>Welcome to Medical Media Systems</strong>
  <p style="margin-bottom: 16px">
    This website has been designed to provide you with fast and easy access to training in the timeless art of physical examination. You can now access streaming media-based
    training in a wide range of common examination techniques used for bedside diagnosis. Through this educational site, you can see both normal examination technique and related
    abnormal findings.
  </p>
  <p>Our website can be previewed by clicking on demo.</p>
  <div class="center">
    <p>
      <img src="/images/hand-on-demo.gif" alt="" height="254" width="159" />
    </p>
  </div>
  <p>Please feel free to look around and see for yourself how easy it is to learn or review examination technique and history/physical findings with Medical Media Systems!</p>
  <p>
    The demo section has only a few video segments activated. These are shown in green. You must register to have access to our entire collection. The pictures below show the
    actual site as it appears to subscribers.
  </p>
  <p>Once you log in, or click on demo, you will then be taken to the video selections page. At the top of the page is the Program Selection Bar.</p>
  <p></p>
  <div class="center">
    <p>
      <img src="/images/hier_menu.gif" alt="" height="120" width="589" />
    </p>
  </div>
  <p>Just point your mouse to the program you want and more menus will appear. Choose the selection you want and you will be taken to that page.</p>
  <p></p>
  <div class="center">
    <p>
      <img src="/images/Drop-down-abdomen.gif" alt="" height="278" width="340" />
    </p>
  </div>
  <p></p>
  <p>Notice the outline to the right of the video screen. You will see text and other lines indented below it. Let's call this a section.</p>
  <p></p>
  <div class="center">
    <p>
      <img src="/images/hand-on-liver-link-new.gif" alt="" height="252" width="290" />
    </p>
  </div>
  <p></p>
  <p>
    If you click on the top line of each section. you can see video for the entire section. If you click on one of the indents, you will see only that part of the section. This
    way, you can choose exactly how much you want to see at any time.
  </p>
  <p>
    In the Examination Technique section, you will find some purple dots (<img src="/images/dot1.gif" alt="" height="15" width="15" />) in the &quot;Abnormal Findings&quot; column.
    When you see these dots, it means there is a demonstration available for the abnormal findings you can encounter when you perform that examination technique. This way you can
    both learn the correct examination technique and then see the abnormal findings you are trying to elicit.
  </p>
  <div class="center">
    <p>
      <img src="/images/hand-on-liver-abnormal-link.gif" alt="" height="252" width="290" />
    </p>
  </div>
  <p>To see another section (or part of a section), just click on it at any time. To choose another program, go back to the Program Selection Bar at the top of the page.</p>
  <p>It's that simple!</p>
</app-content-balloon>
