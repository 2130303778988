<div style="width: 100%; background-color: white">
  <mat-nav-list>
    <mat-list-item class="d-flex flex-column">
      <div class="menu-title">
        <a (click)="goBack()"> <mat-icon aria-hidden="false" aria-label="back">chevron_left</mat-icon></a>
      </div>
      <div class="center flex-1-1-auto">MAIN MENU</div>
      <div class="menu-action">
        <a [routerLink]="['/landing']"> <mat-icon aria-hidden="false" aria-label="close">close</mat-icon></a>
      </div>
    </mat-list-item>
    <a *ngIf="!isLoggedIn()" mat-list-item [routerLink]="['/mobile-login']" class="d-flex">
      <div class="menu-title">Login</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Login">chevron_right</mat-icon></div>
    </a>
    <a *ngIf="isLoggedIn()" mat-list-item (click)="logout()" class="d-flex">
      <div class="menu-title">Logout</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Logout">chevron_right</mat-icon></div>
    </a>

    <a *ngIf="isLoggedIn()" mat-list-item [routerLink]="['/mobile-programs']" class="d-flex">
      <div class="menu-title">Programs</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Programs">chevron_right</mat-icon></div>
    </a>

    <a *ngIf="isLoggedIn()" mat-list-item [routerLink]="['/simulations']" class="d-flex">
      <div class="menu-title">Simulations</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Simulations">chevron_right</mat-icon></div>
    </a>

    <a *ngIf="!isLoggedIn()" mat-list-item [routerLink]="['/videoPlayback']" class="d-flex">
      <div class="menu-title">Demo</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Demo">chevron_right</mat-icon></div>
    </a>

    <a *ngIf="!isLoggedIn()" mat-list-item [routerLink]="['/register']" class="d-flex">
      <div class="menu-title">Register</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Register">chevron_right</mat-icon></div>
    </a>

    <a mat-list-item [routerLink]="['/textbook']" class="d-flex">
      <div class="menu-title">Textbook</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Textbook">chevron_right</mat-icon></div>
    </a>

    <a *ngIf="isLoggedIn()" mat-list-item [routerLink]="['/account']" class="d-flex">
      <div class="menu-title">Account</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Account">chevron_right</mat-icon></div>
    </a>

    <a mat-list-item [routerLink]="['/contact-us']" class="d-flex">
      <div class="menu-title">Contact Us</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Contact Us">chevron_right</mat-icon></div>
    </a>

    <a mat-list-item [routerLink]="['/help']" class="d-flex">
      <div class="menu-title">Help</div>
      <div class="menu-action"><mat-icon aria-hidden="false" aria-label="Help">chevron_right</mat-icon></div>
    </a>
  </mat-nav-list>
</div>
