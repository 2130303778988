import { Observable } from 'rxjs';

export interface LoginRequest {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export enum SubscriptionType {
  PREVIEW = 'PREVIEW',
  RECURRING = 'RECURRING',
  STANDARD = 'STANDARD',
  GROUP = 'GROUP',
}

export interface LoginResponse {
  username: string;
  type: string;
  accessToken: string;
  refreshToken: string;
  subscriptionType: SubscriptionType;
}

export interface TokenResponse {
  accessToken: string;
  refreshToken: string;
}

export enum AuthType {
  account,
  ip,
}

export interface Customer {
  userId: string;
  title?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  birthdate?: string;
  address?: string;
  address2?: string;
  city?: string;
  zip?: number;
  country?: string;
  email?: string;
  fax?: string;
  phone?: string;
  referral?: string;
  school?: string;
  profession?: string;
  professionOther?: string;
  specialty?: string;
  training?: string;
  state?: string;
}

export interface CustomerMeta {
  userId: string;
  signupDate: Date;
  loginDate: Date;
  emailUpdates: boolean;
  emailOptIn: boolean;
  subscription: string;
  dateRenewed: Date;
  dateExpired: Date;
  gift: boolean;
  active: boolean;
}

export interface PasswordResetRequest {
  oldPassword: string;
  newPassword: string;
}

export interface PasswordConstraintViolationResponse {
  errorMessages: Array<string>;
}

export interface MenuEntry {
  id: number;
  pageId: number;
  title: string;
  availableInDemo: boolean;
  sortOrder: number;
  leftValue: number;
  topValue: number;
  width: number;
  visible: boolean;
  abbreviation: string;
  parent: MenuEntry;
  subMenus: Array<MenuEntry>;
}

export interface Pageable<TModel> {
  content: Array<TModel>;
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: any;
  size: number;
  sort: any;
  totalElements: number;
  totalPages: number;
}

export interface Simulation {
  id: number;
  caption: string;
  simulationId: string;
  displayOrder: number;
  active: boolean;
}

export interface IpCompany {
  id: number;
  company: string;
  dateSignup: string;
  dateLogin: string;
  dateRenewed: string;
  dateExpired: string;
}

export interface PageItem {
  id: number;
  pageId: number;
  rowIndex: number;
  columnIndex: number;
  title: string;
  duration: string;
  videoLink: string;
  videoLinkDacast: string;
  imageLink?: string;
  pdfLink?: string;
  clazz: string;
  availableInDemo: boolean;
  indentLevel: number;
  tag: string;
}

export interface TemplateColumn {
  id: number;
  templateId: number;
  columnIndex: number;
  title: string;
  imageSource: string;
  clazz: string;
  width: number;
}

export enum PatchAction {
  REMOVE,
  REPLACE,
}

export enum PatchValueType {
  INT,
  BOOL,
  DATE,
  STRING,
}
export interface Patch {
  operations: Array<PatchOperation>;
}

export interface PatchOperation {
  actionType: PatchAction;
  field: string;
  value: string;
  valueType: PatchValueType;
}

export interface PricingOption {
  id: number;
  title: string;
  description: string;
  price: number;
  shipping: number;
  billingIntervalCode: string;
  automaticRenewal: boolean;
  includeTextbook: boolean;
  displayOrder: number;
  active: boolean;
}

export interface ContactUsRequest {
  firstName: string;
  lastName: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  email: string;
  profession: string;
  professionOther: string;
  training: string;
  specialty: string;
  message: string;
}

export interface StatusMessageResponse {
  success: boolean;
  message?: string;
}

export interface DataTokenStatusMessageResponse extends StatusMessageResponse {
  dataToken?: string;
}

export interface WsSubscription {
  handlerId: number;
  handler: (message: string) => void;
}

export interface RegistrationStep {
  readonly showClearButton: boolean;
  readonly showPreviousButton: boolean;
  readonly showNextButton: boolean;
  readonly showSubmitButton: boolean;
  preNext?: () => Observable<boolean>;
  preSubmit?: () => Observable<boolean>;
  clear?: () => void;
  isNextDisabled?: () => boolean;
  isPreviousDisabled?: () => boolean;
  isSubmitDisabled?: () => boolean;
  onActivate?: () => void;
}

export interface RegistrationRequest {
  subscriptionInfo: SubscriptionInfo;
  userDetails: SubscriptionUserDetails;
  paymentDetails: PaymentDetails;
  dataToken?: string;
}

export interface SubscriptionInfo {
  subscriptionOptionId?: number;
  subscriptionOptionCode?: string;
}

export interface SubscriptionUserDetails {
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  birthDate?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: number;
  country?: string;
  telephone?: string;
  faxNumber?: string;
  emailAddress?: string;
  profession?: string;
  professionOther?: string;
  schoolName?: string;
  training?: string;
  specialty?: string;
  emailUpdates?: boolean;
  emailOptIn?: boolean;
  username?: string;
  password?: string;
  confirmPassword?: string;
  referral?: string;
  acceptedTermsAndConditions?: boolean;
  dataTokenId?: number;
}

export interface PaymentDetails {
  cardNumber?: string;
  expirationDate?: string;
  cardSecurityCode?: number;
}

export enum UsernameValidationErrors {
  MINLENGTH,
  MAXLENGTH,
  INVALID_CHARACTERS,
  ALREADY_EXISTS,
}
export interface ValidateAccountInfoRequest {
  username: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export interface ValidateAccountInfoResponse {
  success: boolean;
  usernameValid: boolean;
  dataTokenId?: number;
  usernameValidationErrors?: Array<UsernameValidationErrors>;
  emailValidationRequired: boolean;
}

export interface ResetPasswordRequest {
  emailAddress: string;
  password: string;
  otp: string;
  dataToken: string;
}

export interface OtpDialogData {
  emailAddress: string;
  success: boolean;
  dataToken?: string;
  message?: string;
  sendVerificationCode: () => Observable<StatusMessageResponse>;
  verify: (code: string) => Observable<DataTokenStatusMessageResponse>;
  onVerifyFailure?: () => void;
}
