import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { DataTokenStatusMessageResponse, StatusMessageResponse } from "../model/mms.model";

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent {
  @Input() title: string;
  @Input() dataIsValid = false;
  @Input() actionDataIsValid = false;
  @Input() verify: (code: string) => Observable<DataTokenStatusMessageResponse>;
  @Input() doSendVerificationCode: () => Observable<StatusMessageResponse>;
  @Input() onVerifySuccess: (dataToken: string) => Observable<StatusMessageResponse>;
  @Input() doAction: (dataToken: string) => Observable<StatusMessageResponse>;
  @Input() onActionSuccess: () => void;
  @Input() hasActionStep = false;
  @Input() showSummary = false;
  @Input() buttonTitle: string;
  @Input() emailAddress: string;

  isRequesting = false;
  verificationCode: string;
  dataToken: string;

  // Steps: 0 - initial display, 1 - verify code, 2 - action
  step = 0;

  form: FormGroup;

  constructor(private _toast: ToastrService, private _formBuilder: FormBuilder) {
    this.form = _formBuilder.group({
      verificationCode: ['', [Validators.required]],
    });
  }

  buttonClicked() {
    this.isRequesting = true;

    switch (this.step) {
      case 0:
        this.doSendVerificationCode?.().subscribe({
          next: response => {
            this.isRequesting = false;
            if (response.success) {
              this.step = 1;
            } else {
              this._toast.error(response.message);
            }
          }
        });
        break;
      case 1:
        this.verify?.(this.form.controls.verificationCode.value).subscribe({
          next: response => {
            this.form.controls.verificationCode.patchValue(null);
            this.isRequesting = false;

            if (response.success) {
              this.dataToken = response.dataToken;
              this.onVerifySuccess?.(this.dataToken);
              this.step = 2;
            } else {
              this._toast.error("The code you provided did not match, please try again.");
            }
          }
        })
        break;
      case 2:
        this.doAction?.(this.dataToken).subscribe({
          next: response => {
            this.isRequesting = false;

            if (response.success) {
              this.onActionSuccess?.();
            } else {
              this._toast.error("Action could not be performed.");
            }
          }
        })
        break;
      default:
        console.log('button clicked, no handler...');
    }
  }

  getButtonText(): string {
    switch (this.step) {
      case 1:
        return 'Verify';
      default:
        return this.buttonTitle;
    }
  }
  isButtonDisabled(): boolean {
    if (this.isRequesting) {
      return true;
    }

    switch (this.step) {
      case 0:
        return !this.dataIsValid;

      case 1:
        return !this.form.controls?.verificationCode?.valid;

      case 2:
        return !(this.hasActionStep && this.actionDataIsValid);

      default:
        return false;
    }
  }
}
