import { Component } from "@angular/core";

@Component({
  selector: "app-support-ebook-nav",
  templateUrl: "./support-ebook-nav.component.html",
  styleUrls: ["./support-ebook-nav.component.css"],
})
export class SupportEbookNavComponent {
  constructor() {}
}
