<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<table
  style="
    background-color: #fff;
    border-radius: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  "
  cellpadding="20"
>
  <tr>
    <td>
      <h3>
        <a name="Anchor-39783"></a>Support - <b>Problems Playing Videos</b>
      </h3>
      <p><b>1. The video seems to stop and start</b></p>
      <p>
        If your internet connection is too slow, video may stop and start. To
        prevent this, you will need a high speed internet connection that
        provides at least 400 kilobytes per second. A normal high-speed
        connection should deliver much more than that.<br />
      </p>
      <p>
        If you have a slow internet connection, you will need to check with your
        internet service provider to see what the problem is. Once you get a
        fast enough internet connection, you will find that our website works
        quite well.
      </p>
      <p>
        <a href="#Anchor-39783">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      <p>
        <b
          >2. I cannot play all of the videos, only the ones highlighted in
          green.</b
        >
      </p>
      <p>
        If this occurs, you are in our demonstration section. Simply click on
        the &quot;Video Selections&quot; link to return to our full library of
        programs.
      </p>
      <p>
        <img
          src="/images/video-selections-graphic.jpg"
          alt="video selections"
          width="172"
          height="70"
        />
      </p>
      <p>
        <a href="#Anchor-39783">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      <p><b>3. The videos won't play at all.</b></p>
      <p>
        Our site is designed to stream video to all computers and mobile
        devices. Occasionally, linkages to our website through a school or other
        institution can cause difficulty with streaming video. If this occurs,
        try going to our site directly at
        <a href="https://www.medicalmediasystems.com" target="_new"
          ><font color="#0000cc"><b>www.medicalmediasystems.com</b></font></a
        >.<br />
      </p>

      <p>
        <a href="#Anchor-39783">[back to top] </a
        ><a [routerLink]="['/help']">[back to main support page]</a>
      </p>
      <p>
        <b>4. Why do the video segments vary in picture and sound quality?</b>
      </p>
      <p>
        Our programs have been made over many years. Video technology has
        progressed considerably and so our programs show their varying origins
        in both older and newer videotape technologies as well as the most
        modern digital formats.
      </p>
      <p>
        <a href="#Anchor-39783">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      <p><b>5. Why can't I view the videos over a dial-up connection?</b></p>
      <p>
        An internet dial-up connection sends information at 56K, or about 56,000
        bytes per second. Although that speed works fine for regular browsing of
        websites, it is simply not fast enough to provide smooth, high-quality
        video playback at a reasonable screen size. For this reason, you will
        need a high-speed connection to view our video segments. This means
        either a DSL line or cable modem in your home, or a high-speed
        connection or wireless access provided through your home, university,
        hospital or other institutional setting.
      </p>
      <p>
        <a href="#Anchor-39783">[back to top] </a
        ><a [routerLink]="['/help']"> [back to main support page]</a>
      </p>
      <p>&nbsp;</p>
      <p></p>
      <p>
        If you cannot find the information you need,
        <a [routerLink]="['/contact-us']"
          ><b><font color="#0033cc">click here</font></b></a
        >
        to send us a message.<br />
      </p>
    </td>
  </tr>
</table>
