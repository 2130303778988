import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Customer, CustomerMeta, DataTokenStatusMessageResponse, Patch, StatusMessageResponse } from './model/mms.model';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public loggedInUser: Customer;
  public loggedInUserMeta: CustomerMeta;

  constructor(private _authService: AuthService, @Inject('BASE_API_URL') private _baseApiUrl: string, private _http: HttpClient) {
    this._authService.loginStatusChanged.subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        this._refreshLoggedInUser();
      } else {
        this.loggedInUser = null;
      }
    });

    this._authService.isAuthenticated().subscribe(isAuthenticated => {
      if (isAuthenticated && !this.loggedInUser) {
        this._refreshLoggedInUser();
      }
    });
  }

  getLoggedInUser(): Observable<Customer> {
    return this._http.get<Customer>(`${this._baseApiUrl}me/`);
  }

  getLoggedInUserMeta(): Observable<CustomerMeta> {
    return this._http.get<CustomerMeta>(`${this._baseApiUrl}me/meta`);
  }

  _refreshLoggedInUser() {
    forkJoin<Customer, CustomerMeta>([this.getLoggedInUser(), this.getLoggedInUserMeta()]).subscribe(([user, userMeta]) => {
      this.loggedInUserMeta = userMeta;
      this.loggedInUser = user;
    });
  }

  authenticateUser(username, password, staySignedIn) {
    const remainLoggedIn = staySignedIn ? 'true' : 'false';
    const params = `authenticate.php?username=${username}&password=${password}&staySignedIn=${remainLoggedIn}`;

    return this._http.get(`${this._baseApiUrl}${params}`, {
      withCredentials: true,
    });
  }

  checkAuth() {
    return this._http.get(`${this._baseApiUrl}auth_check.php`, {
      withCredentials: true,
    });
  }

  logout() {
    this._http.get(`${this._baseApiUrl}logout.php`, { withCredentials: true }).subscribe({
      next(result: Array<any>) {},
      complete() {
        console.log('done with logout subscriber');
      },
    });
  }

  public cancelSubscription(): Observable<StatusMessageResponse> {
    return this._http.delete<StatusMessageResponse>(`${this._baseApiUrl}me/`);
  }

  registerUser(registrationProperties) {
    console.log(JSON.stringify(registrationProperties));
    return this._http.post(`${this._baseApiUrl}register.php`, JSON.stringify(registrationProperties));
  }

  updateProfile(patch: Patch): Observable<any> {
    return this._http.patch<Observable<any>>(`${this._baseApiUrl}me/`, patch);
  }

  updateEmailPreferences(patch: Patch): Observable<any> {
    return this._http.patch<Observable<any>>(`${this._baseApiUrl}me/email-prefs`, patch);
  }

  changePassword(oldPassword, newPassword) {
    var payload = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    return this._http.put(`${this._baseApiUrl}me/password`, payload);
  }

  sendEmailUpdateOtp(emailAddress: string): Observable<StatusMessageResponse> {
    return this._http.post<StatusMessageResponse>(`${this._baseApiUrl}me/email/otp`, emailAddress);
  }

  verifyEmailUpdateOtp(emailAddress: string, otp: string): Observable<DataTokenStatusMessageResponse> {
    const payload = {
      emailAddress,
      otp,
    };

    return this._http.post<DataTokenStatusMessageResponse>(`${this._baseApiUrl}me/email/otp/verify`, payload);
  }

  updateEmailAddress(emailAddress: string, dataToken: string): Observable<StatusMessageResponse> {
    const payload = {
      emailAddress,
      dataToken
    };

    return this._http.post<StatusMessageResponse>(`${this._baseApiUrl}me/email`, payload);
  }
  unregister(username, password) {
    var payload = {
      username: username,
      password: password,
    };

    return this._http.post(`${this._baseApiUrl}unsubscribe.php`, JSON.stringify(payload));
  }

  getPricing() {
    return this._http.get(`${this._baseApiUrl}pricing.php`);
  }
}
