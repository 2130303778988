import { Injectable } from "@angular/core";
import * as DeviceDetector from "device-detector-js";
import { DeviceDetectorResult } from "device-detector-js";

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  private _detector = new DeviceDetector();

  public detect(): DeviceDetectorResult {
    const userAgent = navigator.userAgent;

    return this._detector.parse(userAgent);
  }
}
