<h1 mat-dialog-title>No Sound?</h1>

<div mat-dialog-content>
  <p>Some browsers, particularly Chrome, automatically mute the video when it starts playing.</p>

  <p>To disable the auto-mute feature in the Chrome browser, do the following:</p>

  <ol>
    <li>Click on Chrome settings (three vertical dots at upper right corner of browser) and select <strong>Settings</strong></li>
    <li>Then click on <strong>Privacy and security</strong> from the menu on the left</li>
    <li>Then click on <strong>Site Settings</strong> from the <strong>Privacy and security</strong> section below</li>
    <li>Then click on <strong>Additional content settings</strong> at the bottom of the page</li>
    <li>Then click on <strong>Sound</strong></li>
    <li>Then, under <strong>Allow to play sound</strong>, click on the <strong>Add</strong> button and add the URL of our website: {{siteName}}</li>
  </ol>

    <p>Once this is done, the videos will play with normal sound again.</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="primary" cdkFocusInitial>Ok</button>
</div>
