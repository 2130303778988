import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PricingOption, RegistrationStep, SubscriptionInfo } from 'src/app/model/mms.model';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-register-subscription-selection',
  templateUrl: './subscription-selection-step.component.html',
  styleUrls: ['../register.component.css', './subscription-selection-step.component.scss'],
})
export class SubscriptionSelectionComponent implements OnInit, RegistrationStep {
  readonly showClearButton = true;
  readonly showPreviousButton = false;
  readonly showNextButton = true;
  readonly showSubmitButton = false;

  @Input() set subscriptionInfo(info: SubscriptionInfo) {
    this.form.patchValue(info);
  }

  form: FormGroup;
  pricingOptions: Array<PricingOption> = [];

  constructor(private _subscriptionService: SubscriptionService, private _formBuilder: FormBuilder) {
    this.form = this._formBuilder.group({
      subscriptionOptionId: '',
      subscriptionOptionCode: '',
    });
  }

  ngOnInit(): void {
    this._subscriptionService.getPricingOptions().subscribe({
      next: (pricingOptions) => {
        this.pricingOptions = pricingOptions;
      },
    });
  }

  clear(): void {
    this.form.reset();
  }

  getSubscriptionInfo(): SubscriptionInfo {
    return this.form.getRawValue() as SubscriptionInfo;
  }

  isValid(): boolean {
    const value = this.form.value;

    return value.subscriptionOptionCode?.trim()?.length > 0 || value.subscriptionOptionId;
  }

  showGroupMembershipCode(): boolean {
    return !this.form?.controls['subscriptionOptionId']?.value ?? true;
  }

  showSubscriptionOptions(): boolean {
    return !this.form?.controls['subscriptionOptionCode']?.value?.trim() ?? true;
  }

  isNextDisabled(): boolean {
    return !this.isValid();
  }
}
