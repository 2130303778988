import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './/app-routing.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { AccountConfirmationCallbackComponent } from './account-confirmation-callback/account-confirmation-callback.component';
import { AccountComponent } from './account/account.component';
import { MobileAccountComponent } from './account/mobile-account/mobile-account.component';
import { AppComponent } from './app.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MobileContactUsComponent } from './contact-us/mobile-contact-us/mobile-contact-us.component';
import { ContentBalloonComponent } from './content-balloon/content-balloon.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { CcExpDirective } from './directives/cc-exp.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { EbookSupportComponent } from './ebook-support/ebook-support.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HelpComponent } from './help/help.component';
import { HowToUseComponent } from './how-to-use/how-to-use.component';
import { MobileHowToUseComponent } from './how-to-use/mobile-how-to-use/mobile-how-to-use.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LeftNavigationDemoComponent } from './left-navigation-demo/left-navigation-demo.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { OtpDialogComponent } from './otp-dialog/otp-dialog.component';
import { OtpComponent } from './otp/otp.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { PlayerComponent } from './player/player.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProgramsComponent } from './programs/desktop-programs/programs.component';
import { MobileProgramsComponent } from './programs/mobile-programs/mobile-programs.component';
import { EmailConfirmationComponent } from './register/email-confirmation/email-confirmation-step.component';
import { MobileRegisterComponent } from './register/mobile-register/mobile-register.component';
import { PaymentComponent } from './register/payment/payment-step.component';
import { RegisterComponent } from './register/register.component';
import { SubscriptionSelectionComponent } from './register/subscription-selection/subscription-selection-step.component';
import { UserDetailsComponent } from './register/user-details/user-details-step.component';
import { ScripthackComponent } from './scripthack/scripthack.component';
import { DeviceDetectorService } from './services/device-detector.service';
import { getToken } from './services/token-storage.service';
import { MobileSimulationsComponent } from './simulations/mobile-simulations/mobile-simulations.component';
import { SimulationsComponent } from './simulations/simulations.component';
import { SitelicenseComponent } from './sitelicense/sitelicense.component';
import { SoundDialogComponent } from './sound-dialog/sound-dialog.component';
import { StaticPagesComponent } from './static-pages/static-pages.component';
import { SupportEbookAdobeIdComponent } from './support-ebook-adobe-id/support-ebook-adobe-id.component';
import { SupportEbookDownloadComponent } from './support-ebook-download/support-ebook-download.component';
import { SupportEbookEreaderComponent } from './support-ebook-ereader/support-ebook-ereader.component';
import { SupportEbookNavComponent } from './support-ebook-nav/support-ebook-nav.component';
import { SupportLoginComponent } from './support-login/support-login.component';
import { SupportPlaybackComponent } from './support-playback/support-playback.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { MobileTextbookComponent } from './textbook/mobile-textbook/mobile-textbook.component';
import { TextbookComponent } from './textbook/textbook.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { VideoPlaybackComponent } from './video-playback/desktop-video-playback/video-playback.component';
import { MobileVideoPlaybackComponent } from './video-playback/mobile-video-playback/mobile-video-playback.component';
@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    LandingPageComponent,
    VideoPlaybackComponent,
    MobileVideoPlaybackComponent,
    LeftNavigationDemoComponent,
    ProgramsComponent,
    MobileProgramsComponent,
    RegisterComponent,
    HelpComponent,
    FooterComponent,
    TextbookComponent,
    HowToUseComponent,
    AboutUsComponent,
    ContactUsComponent,
    UnsubscribeComponent,
    AccountComponent,
    TermsAndConditionsComponent,
    ForgotPasswordComponent,
    ContactInformationComponent,
    MobileProgramsComponent,
    MobileNavigationComponent,
    MobileLoginComponent,
    SimulationsComponent,
    EbookSupportComponent,
    SitelicenseComponent,
    SupportLoginComponent,
    SupportPlaybackComponent,
    SupportEbookNavComponent,
    SupportEbookAdobeIdComponent,
    SupportEbookEreaderComponent,
    SupportEbookDownloadComponent,
    ScripthackComponent,
    StaticPagesComponent,
    ContentBalloonComponent,
    SafePipe,
    SafeHtmlPipe,
    MobileContactUsComponent,
    MobileRegisterComponent,
    PrivacyComponent,
    MobileSimulationsComponent,
    MobileHowToUseComponent,
    MobileAccountComponent,
    MobileTextbookComponent,
    CountrySelectComponent,
    SubscriptionSelectionComponent,
    UserDetailsComponent,
    EmailConfirmationComponent,
    AccountConfirmationCallbackComponent,
    PaymentComponent,
    OtpComponent,
    OtpDialogComponent,
    SoundDialogComponent,
    CcExpDirective,
    ConfirmDialogComponent,
    PlayerComponent,
    DebounceClickDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatRadioModule,
    MatDatepickerModule,
    DigitOnlyModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
      },
    }),
    NgxMaskModule.forRoot()
  ],
  providers: [
    JwtHelperService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LfB234fAAAAAK4MpZJLGOh-WqUzB13sx353D794' } as RecaptchaSettings,
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DeviceDetectorService
  ],
  bootstrap: [AppComponent],
  entryComponents: [OtpDialogComponent]
})
export class AppModule {}
