<app-content-balloon overlayText="Please Wait..." [showOverlay]="isRequesting" width="750px">
  <div class="account-section" *ngIf="showUnsubscribe()">
    <div class="title-text header">Unsubscribe</div>
    <div class="account-section-content">
      <mat-checkbox #unsubscribe color="primary">
        I wish to cancel my automatic recurring billing subscription. Sorry, but partial subscription periods cannot be refunded.</mat-checkbox
      >

      <div class="d-flex flex-column">
        <div class="d-flex flex-row center" style="padding-top: 16px">
          <button mat-raised-button *ngIf="unregisterEnabled" [disabled]="!unsubscribe.checked" color="primary" (click)="unregisterUser()">Unsubscribe</button>
          <span *ngIf="!unregisterEnabled">Processing ...</span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row">
    <div class="d-flex flex-column" style="flex: 1 1; padding-right: 32px">
      <div class="account-section" *ngIf="customer">
        <form [formGroup]="emailForm">
          <div class="title-text header">Email Address</div>
          <div class="account-section-content">
            <div class="d-flex flex-column indented">
              <div class="d-flex flex-row">
                <mat-form-field style="flex: 1 1 auto">
                  <mat-label>Email Address</mat-label>
                  <input #email matInput formControlName="email"/>
                </mat-form-field>
              </div>
              <div class="d-flex flex-row center">
                <button mat-raised-button [disabled]="disableUpdateEmail()" (click)="verifyEmail()" color="primary">Update Email Address</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="account-section" *ngIf="customer">
        <div class="title-text header">Password</div>
        <div class="account-section-content">
          <div class="d-flex flex-column indented">
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Old Password</mat-label>
                <input type="password" matInput [(ngModel)]="oldPassword" (keyup)="onPasswordChanged()" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>New Password</mat-label>
                <input type="password" matInput [(ngModel)]="newPassword" (keyup)="onPasswordChanged()" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Confirm Password</mat-label>
                <input type="password" matInput [(ngModel)]="newPasswordConfirmation" (keyup)="onPasswordChanged()" (keyup.enter)="changePassword()" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row" *ngIf="passwordErrorMessage">
              <span class="error-message">{{ passwordErrorMessage }}</span>
            </div>
            <div class="d-flex flex-row center">
              <button mat-raised-button [disabled]="!passwordChangeValid" (click)="changePassword()" color="primary">Change Password</button>
            </div>
          </div>
        </div>
      </div>
      <div class="account-section" *ngIf="customerMeta">
        <div class="d-flex flex-column">
          <div class="title-text header">E-Mail Preferences</div>
          <div class="account-section-content">
            <div>
              <mat-checkbox color="primary" [(ngModel)]="customerMeta.emailUpdates">
                <div class="title-text" style="display: inline">Site Updates</div>
              </mat-checkbox>
            </div>
            <p class="indented" style="margin-top: 4px; margin-bottom: 16px">
              Occasionally, Medical Media Systems will want to contact you regarding special offers, changes to our website, or other updates. To receive these updates, please
              check the box.
            </p>

            <div>
              <mat-checkbox color="primary" [(ngModel)]="customerMeta.emailOptIn">
                <div class="title-text" style="display: inline">Opt-In</div>
              </mat-checkbox>
            </div>
            <p class="indented" style="margin-top: 4px; margin-bottom: 16px">
              Occasionally, Medical Media Systems will share our client list with companies and groups that will be of interest to you. To receive special offers and information
              from these companies and groups, please check the box.
            </p>

            <div class="d-flex flex-row center">
              <button mat-raised-button (click)="updateEmailPrefs()" color="primary">Update Email Preferences</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column" style="flex: 1 1 auto">
      <div class="account-section" *ngIf="customer">
        <div class="title-text header">Profile</div>
        <div class="account-section-content">
          <div class="d-flex flex-column indented">
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Title</mat-label>
                <mat-select [(ngModel)]="customer.title">
                  <mat-option value="Mr.">Mr.</mat-option>
                  <mat-option value="Mrs.">Mrs.</mat-option>
                  <mat-option value="Ms.">Ms.</mat-option>
                  <mat-option value="Miss">Miss</mat-option>
                  <mat-option value="Dr.">Dr.</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>First Name</mat-label>
                <input matInput [(ngModel)]="customer.firstName" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Middle Initial</mat-label>
                <input matInput [(ngModel)]="customer.middleName" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Last Name</mat-label>
                <input matInput [(ngModel)]="customer.lastName" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Address Line 1</mat-label>
                <input matInput [(ngModel)]="customer.address" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Address Line 2</mat-label>
                <input matInput [(ngModel)]="customer.address2" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>City</mat-label>
                <input matInput [(ngModel)]="customer.city" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>State</mat-label>
                <input matInput [(ngModel)]="customer.state" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Zip Code</mat-label>
                <input #zip matInput maxlength="5" (beforeinput)="numberOnly($event)" [(ngModel)]="customer.zip" class="email-input" />
                <mat-hint align="end">{{('' + customer.zip).length}}/5</mat-hint>
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Telephone</mat-label>
                <input matInput [(ngModel)]="customer.phone" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Fax</mat-label>
                <input matInput [(ngModel)]="customer.fax" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <app-country-select [value]="customer.country" (valueChanged)="onCountryChanged($event)"></app-country-select>
            </div>
          </div>
        </div>

        <div class="title-text header">Professional Classification</div>
        <div class="account-section-content">
          <div class="d-flex flex-column indented">
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Profession</mat-label>
                <mat-select [(ngModel)]="customer.profession">
                  <mat-option value="Medicine" selected>Medicine (MD or DO)</mat-option>
                  <mat-option value="Nursing">Nursing</mat-option>
                  <mat-option value="Dentistry">Dentistry</mat-option>
                  <mat-option value="Podiatry">Podiatry</mat-option>
                  <mat-option value="Chiropractic">Chiropractic</mat-option>
                  <mat-option value="Naturopathy">Naturopathy</mat-option>
                  <mat-option value="Allied Health">Allied Health</mat-option>
                  <mat-option value="EMT / Paramedic">EMT / Paramedic</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex flex-row" *ngIf="customer.profession === 'Other'">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>If Other Profession, Please Explain</mat-label>
                <input matInput [(ngModel)]="customer.professionOther" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>School</mat-label>
                <input matInput [(ngModel)]="customer.school" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Specialty or Major</mat-label>
                <input matInput [(ngModel)]="customer.specialty" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-row">
              <mat-form-field style="flex: 1 1 auto">
                <mat-label>Level of Training</mat-label>
                <mat-select [(ngModel)]="customer.training">
                  <mat-option value="Student">Student</mat-option>
                  <mat-option value="Resident">Resident</mat-option>
                  <mat-option value="Post-Graduate Student">Post-Graduate Student (MA, PhD)</mat-option>
                  <mat-option value="Practitioner or Instructor" selected>Practitioner or Instructor</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex flex-row center" style="padding-top: 16px">
              <button mat-raised-button (click)="updateProfile()" color="primary">Update Profile</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-content-balloon>
