import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '../utils';

const TOKEN_KEY = 'auth-token';
const REFRESH_TOKEN_KEY = 'auth-refresh-token';
const USER_KEY = 'auth-user';
const AUTH_TYPE_KEY = 'auth-type';
const TERMS_KEY = 'auth-agree-terms';

export const getToken = () => {
    const local = window.localStorage.getItem(TOKEN_KEY);
    const session = window.sessionStorage.getItem(TOKEN_KEY);

    return !isNullOrUndefined(local) ? local : session;
}

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  signOut(): void {
    this.clear();
  }

  public saveToken(token: string, useLocalStorage: boolean = false): void {
    this._update(TOKEN_KEY, token, useLocalStorage);

    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, accessToken: token });
    }
  }

  public getToken(): string | null {
    return getToken();
  }

  public saveRefreshToken(token: string, useLocalStorage: boolean = false): void {
    this._update(REFRESH_TOKEN_KEY, token, useLocalStorage);
  }

  public getRefreshToken(): string | null {
    return this._get(REFRESH_TOKEN_KEY);
  }

  public saveAuthType(authType: string, usesLocalStorage: boolean = false): void {
    this._update(AUTH_TYPE_KEY, authType, usesLocalStorage);
  }

  public getAuthType(): string | null {
    return this._get(AUTH_TYPE_KEY);
  }

  public saveAgreeToTerms(agree: boolean, usesLocalStorage: boolean = false): void {
    this._update(TERMS_KEY, String(agree), usesLocalStorage);
  }

  public getAgreeToTerms(): boolean {
    return Boolean(this._get(TERMS_KEY));
  }

  public saveUser(user: any, usesLocalStorage: boolean = false): void {
    this._update(USER_KEY, JSON.stringify(user), usesLocalStorage);
  }

  public getUser(): any {
    const user = this._get(USER_KEY);

    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public clear() {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.removeItem(AUTH_TYPE_KEY);
    window.localStorage.removeItem(TERMS_KEY);
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(AUTH_TYPE_KEY);
    window.sessionStorage.removeItem(TERMS_KEY);
  }

  public usesLocalStorage(): boolean {
    return !isNullOrUndefined(window.localStorage.getItem(TOKEN_KEY));
  }

  private _get(key: string) {
    const local = window.localStorage.getItem(key);
    const session = window.sessionStorage.getItem(key);

    return !isNullOrUndefined(local) ? local : session;
  }

  private _storage(useLocalStorage: boolean = false) {
    return useLocalStorage ? window.localStorage : window.sessionStorage;
  }

  private _update(key: string, value: string, useLocalStorage: boolean = false) {
    window.sessionStorage.removeItem(key);
    window.localStorage.removeItem(key);

    this._storage(useLocalStorage).setItem(key, value);
  }
}
