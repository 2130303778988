import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-account-confirmation-callback',
  templateUrl: './account-confirmation-callback.component.html',
})
export class AccountConfirmationCallbackComponent implements OnInit {
  processing = false;

  private _success: boolean;
  private _token: string;
  private _username: string;

  constructor(private _subscriptionsService: SubscriptionService, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._username = this._route.snapshot.queryParams?.username;
    this._token = this._route.snapshot.queryParams?.token;

    if (this.isRequiredDataAvailable()) {
      this._performValidation();
    }
  }

  isRequiredDataAvailable(): boolean {
    return !!(this._username && this._token);
  }

  isSuccess(): boolean {
    return this._success === true;
  }

  isFailure(): boolean {
    return this._success === false;
  }

  close(): void {
    window.close();
  }

  _performValidation() {
    this.processing = true;
    this._subscriptionsService.verifyEmailToken(this._username, this._token).subscribe({
      next: (response) => {
        console.log('got response:');
        console.log(JSON.stringify(response, null, 2));
        this.processing = false;
        this._success = response.success;
      },
      error: () => {
        this.processing = false;
        this._success = false;
      },
    });
  }
}
