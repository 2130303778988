<app-content-balloon>
  <h3 style="font-size: 18px; font-weight: bold">Terms and Conditions</h3>
  <p>PLEASE READ THIS AGREEMENT CAREFULLY</p>

  <p>1. ACCEPTANCE OF TERMS</p>
  <p style="margin-bottom: 32px">
    By clicking on the &quot;I agree&quot; or &quot;yes&quot; button below, User acknowledges that User has read this agreement, understands it, and agrees to be bound by it. If
    User does not agree to any of the terms below, Medical Media Systems is unwilling to license the Medical Media Systems information to User, and User should click on the &quot;I
    decline&quot; or &quot;no&quot; button below to discontinue the registration process.
  </p>

  <p style="margin-bottom: 32px">
    Welcome to Medical Media Systems. Our mission is to provide health professionals and students in the health professions with advanced yet easy-to-use tools and knowledge needed
    to provide the highest quality medical care to their patients. Your use of this web site and the Service (as defined below) is subject to your agreement to these Terms and
    Conditions of Service. Please note that these Terms and Conditions of Service include any posted guidelines or rules applicable to particular services that may be posted from
    time to time and, if you later enroll in any services offered to health professionals, any Enrollment Form, Service Agreement or similar agreement that you complete at the time
    of such enrollment, all of which are incorporated as though fully set forth herein (&quot;Terms and Conditions&quot;). These Terms and Conditions govern your use of the Medical
    Media Systems site and Service and may be updated by Medical Media Systems from time to time without notice to you.
  </p>

  <p style="margin-bottom: 32px">
    This is a license agreement (&quot;Agreement&quot;) between Medical Media Systems and the individual User or healthcare provider (&quot;User&quot;) who has completed the online
    registration form on the website (as defined below). This agreement provides the User the right to access and view the streaming video and other content thereto made available
    by Medical Media Systems. The content is referred to collectively as the &quot;Service.&quot; Medical Media Systems is willing to grant licensee the following license (The
    &quot;License&quot;) to use the Medical Media Systems information according to this agreement, only on the condition that User accepts all terms in this agreement.
  </p>

  <p>2. USE OF SERVICE</p>

  <p>Medical Media Systems currently provides health professionals and students in the health professions with access to a collection of medical education resources, including:</p>
  <div class="indented" style="margin-bottom: 32px">
    <p>&#x2022; Streaming media access to physical diagnosis instructional video and audio programs.</p>
    <p>&#x2022; Downloadable files to assist with physical diagnosis skills, when available.</p>
    <p>&#x2022; Web links to other useful sites pertaining to physical diagnosis skills, when offered.</p>
    <p>&#x2022; Other educational programs, when offered.</p>
  </div>

  <p style="margin-bottom: 32px">
    Medical Media Systems may alter, augment or remove the Service in whole or in part at any time and from time to time in its sole discretion. The Service is specifically
    intended for use by students and practitioners of the health professions. In all cases, however, the Service is intended for general reference only... User will use the Medical
    Media Systems Information only as a reference aid, and will acknowledge that such information is not intended to be (nor should it be used as) a substitute for the exercise of
    professional judgment. In view of the possibility of human error or changes in medical science, User should confirm the information in the Content through independent sources.
    User agrees and acknowledges that User will, at all times, advice patients to seek professional diagnosis and treatment for any medical condition and to discuss information
    obtained from the Content with their healthcare provider.
  </p>

  <p style="margin-bottom: 32px">
    To use the Service you will need to have access to the World Wide Web and you must pay any fees associated with your use of such access. Of course, you will also need equipment
    and a modem or other access device.
  </p>

  <p>3. CONTENT MAINTAINED BY MEDICAL MEDIA SYSTEMS.</p>

  <p style="margin-bottom: 32px">
    User acknowledges and agrees that: (a) Medical Media Systems or its licensors may, from time to time, elect to update information contained in the Content, but Medical Media
    Systems does not warrant or guarantee that any product description(s) or other information accessed through the Service will be updated at any time during the term of this
    Agreement; (b) Medical Media Systems does not assume, and expressly disclaims, any obligation to obtain and include any information in the Content; (c) the Content includes or
    may include information provided to Medical Media Systems by its licensors or other third parties, and Medical Media Systems does not assume and expressly disclaims any
    responsibility for the accuracy of such third-party content; (d) Medical Media Systems is not advocating the use of any product described in the Content (or elsewhere), nor is
    Medical Media Systems responsible for misuse of a product or procedure due to typographical or other errors in the Content, User negligence or otherwise.
  </p>

  <p style="margin-bottom: 32px">4. RESTRICTIONS.</p>

  <p style="margin-bottom: 32px">
    User may not use, copy, modify, or transfer the Medical Media Systems Information, or any copy thereof, in whole or in part, except as expressly provided in this Agreement.
    User may not reverse engineer, disassemble, decompile, or translate the Medical Media Systems Information, or otherwise attempt to derive the source code of the Software, or
    authorize any third party to do any of the foregoing. User is prohibited from developing, selling or distributing applications that are capable of launching, being launched
    from, or are otherwise integrated with, the Medical Media Systems Information without the express written consent of Medical Media Systems. User may not rent, lease, loan,
    resell for profit, distribute, sublicense or use in a time-sharing arrangement the Medical Media Systems Information, or any part thereof.
  </p>

  <p style="margin-bottom: 32px">
    As a single exception, Users may copy and share physical diagnosis software designed for a personal digital assistant (PDA) provided free of charge by Medical Media Systems,
    either by file copying and sharing, or by beaming the software from one PDA to another.
  </p>

  <p style="margin-bottom: 32px">5. DISCLAIMER OF WARRANTIES.</p>

  <p style="margin-bottom: 32px">
    The customer acknowledges that the service and any products are provided on an &quot;as is&quot; basis. Except for warranties which may not be disclaimed as a matter of law,
    Medical Media Systems makes no representation or warranties whatsoever, express or implied, including but not limited to representations or warranties regarding the accuracy or
    nature of the content of the service, warranties of title, noninfringement, merchantability or fitness for a particular purpose. Medical Media Systems makes no warranty that
    the service will meet your requirements, or will be uninterrupted, timely, secure, current, accurate, complete or error-free or that the results that may be obtained by use of
    the service will be accurate or reliable. You understand and acknowledge that your sole and exclusive remedy with respect to any defect in or dissatisfaction with the service
    is to cease to use the service.
  </p>

  <p style="margin-bottom: 32px">
    The customer acknowledges that updates to the Service are at the sole discretion of Medical Media Systems. Medical Media Systems makes no representations or warranties
    whatsoever, express or implied, with respect to the compatibility of the Service, or future releases thereof, with any computer hardware or software, nor does Medical Media
    Systems represent or warrant the continuity of the features or the facilities provided by or through the Service as between various releases thereof.
  </p>

  <p style="margin-bottom: 32px">
    Any warranties expressly provided herein do not apply if: (i) the Customer alters, mishandles or improperly uses, stores or installs all, or any part, of the Service, (ii) the
    Customer uses, stores or installs the Service on a computer system which fails to meet the specifications provided by Medical Media Systems, or (iii) the breach of warranty
    arises out of or in connection with acts or omissions of persons other than Medical Media Systems.
  </p>

  <p>6. LIMITATION OF LIABILITY</p>

  <p style="margin-bottom: 32px">
    You expressly understand and agree that Medical Media Systems shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including
    without limitation, damages for loss of profits, goodwill, use, data loss or other losses (even if Medical Media Systems has been advised of the possibility of such damages),
    resulting from: the use or inability to use the service, statements or conduct of any third party provided or received as a result of the service, any link provided in
    connection with the service, or any matter otherwise related to your use of the service.
  </p>

  <p style="margin-bottom: 32px">
    The customer assumes all risk for selection and use of the service and content provided thereon or any software furnished in connection therewith. Medical Media Systems shall
    not be responsible for any errors, misstatements, inaccuracies or omissions regarding content delivered through the service or any delays in or interruptions of such delivery.
  </p>

  <p style="margin-bottom: 32px">
    The customer acknowledges that Medical Media Systems: (A) has no control of or responsibility for the customer's use of the service or content provided thereon, (B) has no
    knowledge of the specific or unique circumstances under which the service or content provided thereon may be used by the customer, (C) undertakes no obligation to supplement or
    update content of the service, or (D) has no liability to any person for any data or information input on the service by the customer to the service.
  </p>

  <p style="margin-bottom: 32px">
    Medical Media Systems shall not be liable to any person (including but not limited to the Customer and persons treated by or on behalf of the Customer) for, and the customer
    agrees to indemnify and hold Medical Media Systems harmless from any claims, lawsuits, proceedings, costs, attorney's fees, damages or other losses (collectively,
    &quot;losses&quot;) arising out of or relating to (A) the customer's use of the service or content provided thereon or any software furnished in connection therewith and (B)
    any data or information input on the service by Customer, in all case including but not limited to losses for tort, personal injury, medical malpractice or product liability.
    Further, without limiting the foregoing, in no event shall Medical Media Systems be liable for any special, incidental, consequential, or indirect damages, including damages
    for loss of profits, loss of business, or down time, even if Medical Media Systems has been advised of the possibility of such damages, the information contained within the
    service is intended for use only by physicians and other healthcare professionals who should rely on their clinical discretion and judgment in diagnosis and treatment. As
    between the customer and Medical Media Systems, the Customer hereby assumes full responsibility for insuring the appropriateness of using and relying upon the information in
    view of all attendant circumstances, indications and contradictions.
  </p>

  <p>7. EXCLUSIONS AND LIMITATIONS</p>

  <p style="margin-bottom: 32px">
    Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages and, therefore, some of
    the limitations set forth in Sections 3 and 4 may not apply to you.
  </p>

  <p>8. LIMITATIONS ON USE</p>

  <p style="margin-bottom: 32px">
    The License granted by Medical Media Systems to the User will terminate immediately without notice to User if User breaches any term or condition of this Agreement. Medical
    Media Systems reserves the right to deny, suspend or terminate your access to the Service at any time or to enhance or change the Service provided at anytime without notice to
    the User. User may terminate this Agreement at any time by notifying Medical Media Systems in writing. Upon termination, the license to access the Service shall cease.
  </p>

  <p>9. CONDUCT ON THE SITE.</p>

  <p>
    You understand and agree that all information, data and other material provided in this Service (&quot;Content&quot;) is the responsibility of the person or entity providing
    such Content. You are responsible for all Content that you upload, post, email or otherwise transmit using the Service. Likewise, you are responsible to respect third party
    rights with respect to all Content that appears on the Site and not to download, email or otherwise transmit such Content in violation of such third party's rights. You agree
    not to use the Service to:
  </p>
  <div class="indented" style="margin-bottom: 32px">
    <p>
      &#x2022; Upload, post, email, or otherwise transmit any Content that infringes any patent, trademark, trade secret, copyright or other intellectual property right, privacy
      right, or publicity right of any person or entity;
    </p>
    <p>
      &#x2022; Upload, post, email, or otherwise transmit any Content that is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous,
      invasive of another's privacy, hateful, racially, ethnically, or otherwise offensive.
    </p>
    <p>
      &#x2022; Impersonate another person or entity, including without limitation, a medical doctor, health professional or student of the health professions, representative of
      Medical Media Systems, forum leader, guide or host, falsely represent or misrepresent your affiliation with another person or entity, falsely represent or misrepresent your
      age if you are a minor, or forge headers or otherwise manipulate identifiers that would disguise the origin of any Content transmitted through the Service.
    </p>
    <p>
      &#x2022; Upload, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, including without limitation initiation or perpetuation
      of any &quot;Spam&quot;, &quot;Junk Mail&quot;, &quot;Chain Letter&quot; &quot;Pyramid Scheme&quot;, or any other potentially objectionable form of unsolicited communication,
      except to the extent such an activity is clearly reasonable and appropriate (such as advertising) and then only in areas of the web site designated for such activities (such
      as a shopping room).
    </p>
    <p>
      &#x2022; Use the Service or the web site for any purpose or in any manner that violates any local, state, or federal law or regulation or the law or regulation of any foreign
      government.
    </p>
    <p>
      &#x2022; Collect or store personal data with respect to other Users other than in such circumstances (such as chat groups), and then only for the express purpose of providing
      lawful communication that may reasonably be anticipated by such other Users.
    </p>
    <p>
      &#x2022; Directly or indirectly intentionally disrupt or interfere with the Service or the web site in any manner that may materially adversely affect Medical Media Systems
      or any User.
    </p>
    <p>
      &#x2022; Upload, post, email or otherwise transmit any material that contains software viruses or any other code, files or programs designed to disable, interrupt, or limit
      the functionality of any computer hardware, computer software, or telecommunications equipment or facilities.
    </p>
  </div>
  <p>10. DISCLAIMER OF CONTENT</p>
  <p style="margin-bottom: 32px">
    You understand and acknowledge that Medical Media Systems assumes no responsibility to screen or review content provided as part of the services and that Medical Media Systems
    shall have the right (but not the obligation) in its sole discretion to refuse, monitor or to remove any content that is available through the service. Medical Media Systems
    expressly disclaims all responsibility or liability to you or any other person or entity for the performance or nonperformance of content review. You acknowledge and agree that
    you assume all risk associated with the use of any and all content.
  </p>

  <p>11. OWNERSHIP OF CONTENT</p>

  <p style="margin-bottom: 32px">
    The Medical Media Systems Information is the property of Medical Media Systems or its licensor(s) and is protected by copyright and other intellectual property laws. Access to
    the Medical Media Systems Information is licensed, not sold, to User for use only under the terms of this Agreement, and Medical Media Systems reserves all rights not expressly
    granted to User.
  </p>

  <p style="margin-bottom: 32px">
    You understand and agree that your use of Medical Media Systems entitles Medical Media Systems to monitor usage of all Content and to disclose such Content if required to do so
    by law or in the good faith belief that such disclosure will promote: compliance with legal process, enforcement of these Terms and Conditions; assistance in responding to
    claims that Content violates the rights of third parties; protection of the personal safety of any person; or protection of the property rights of Medical Media Systems or any
    other person or entity.
  </p>

  <p>12. LINKS</p>

  <p style="margin-bottom: 32px">
    The Service may provide, or third parties may provide, links to other World Wide Web Sites. You acknowledge and agree that such links are provided for your convenience and do
    not reflect any endorsement by Medical Media Systems with respect to the provider of such linked site or the quality, reliability or any other characteristic or feature of such
    linked site and Medical Media Systems is not responsible in any manner (including without limitation with respect to any loss or injury you suffer) for any matter associated
    with the linked site, including without limitation, the content provided on or through any such linked site or your reliance thereon. Medical Media Systems makes no
    representations or warranties with respect to any linked site: your use of any linked site is solely at your own risk. In addition, you should be aware that your use of any
    linked site is subject to the terms and conditions applicable to that site.
  </p>

  <p>12.1 REPORTED USAGE</p>

  <p>
    Our website is often recommended by the educational institution which the User attends. These educational institutions often request information from us regarding usage of our
    website by their students (Users). In the situation where an educational institution lists, suggests, recommends or requires a subscription to the Medical Media Systems
    service, either in writing or verbally, you agree to allow us to release to the educational institution where you, the User, are enrolled, the following information.
  </p>
  <blockquote style="margin-bottom: 32px">
    <p>A. Your full name, website user ID and email address.</p>
    <p>B. Date of initial registration and last usage of our website.</p>
    <p>C. Which video programs you viewed and when, as of the date we accessed usage information.</p>
  </blockquote>

  <p>13. INDEMNITY</p>

  <p style="margin-bottom: 32px">
    You agree to defend, indemnify and hold Medical Media Systems, its officers, directors, employees and agents, harmless from and against any third party claim, action or demand
    (&quot;Claim&quot;) and all liabilities and settlements related thereto, including without limitation, reasonable legal and accounting fees, resulting from, or alleged to
    result from your use of the Content, the Services (including the interactive areas of the Services) in a manner that violates these Terms and Conditions. Medical Media Systems
    shall provide notice to you promptly of such Claim and shall reasonably cooperate with you, at your expense, in your defense of any such Claim.
  </p>

  <p>14. RELATIONSHIPS WITH ADVERTISERS</p>

  <p style="margin-bottom: 32px">
    Your communications with or participation in the promotion of any person or entity advertising on the Medical Media Systems service (&quot;advertisers&quot;), including without
    limitation, terms and conditions of the offer and sale of products and services offered by the advertiser, are independent of Medical Media Systems and are solely between you
    and the advertiser. You acknowledge and agree that Medical Media Systems shall not be responsible or liable to you for any loss, injury or damage of any kind arising out of any
    communication provided by advertisers on the service or any dealing with any advertiser that you may have. Medical Media Systems makes no representations or warranties with
    respect to advertisers and their products, including without limitation, the suitability or reputation any advertiser or any product or service offered by any advertiser.
  </p>

  <p>15. TRADEMARK AND COPYRIGHT INFORMATION</p>

  <p style="margin-bottom: 32px">
    The Living Atlas of Medicine is a trademark of Medical Media Systems. Other trademarks are used with the permission of their respective owners. You agree not to use or display
    trademarks without the prior written consent of Medical Media Systems or the owner of such mark.
  </p>

  <p>16. COPYRIGHT INFORMATION</p>

  <p style="margin-bottom: 32px">
    The Content of this Service is owned by Medical Media Systems and/or its affiliates and third parties and is protected by the United States Copyright Act of 1976 as amended and
    the copyright laws of other countries. Certain materials are used by permission of their respective owners. The Content of the Service, including graphic images, buttons, and
    text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without the prior written permission of Medical Media Systems, except
    that, subject to your compliance with these Terms and Conditions, Medical Media Systems authorizes you to view or download a single copy of educational software programs, when
    available, which are specifically designated for downloading, solely for your personal, noncommercial use provided that you keep intact all copyright, trademark, and other
    proprietary notices. Modification of Content or use of Content for any other purpose is a violation of the rights of Medical Media Systems, its affiliates or third parties.
    Nothing herein shall be construed as conferring by implication, or otherwise, any license or right under any copyright, patent, trademark, or other proprietary interest of
    Medical Media Systems, its affiliates or third parties.
  </p>

  <p>17. JURISDICTION AND VENUE.</p>

  <p style="margin-bottom: 32px">
    You and Medical Media Systems and any of its affiliates, employees, contractors, officers, or directors agree that this agreement and the relationship between you and Medical
    Media Systems will be governed by the laws of the State of Washington, without respect to its conflict of laws provisions and that venue with respect to any dispute between you
    and Medical Media Systems will rest exclusively in the courts of Kitsap County and the federal court for the State of Washington. You and Medical Media Systems further agree and
    expressly consent to exercise of the personal jurisdiction of the courts in the State of Washington.
  </p>

  <p>18. WARRANTY; DISCLAIMER.</p>

  <p style="margin-bottom: 32px">
    The Medical Media Systems information is provided to user &quot;as is.&quot; except as expressly provided under this agreement, Medical Media Systems and its affiliates, agents
    and licensors: (a) cannot and do not warrant the accuracy, completeness, currency or non-infringement of the Medical Media Systems information provided hereunder; and (b)
    expressly disclaim all warranties and conditions, express, implied or statutory, including without limitation the implied warranties or conditions of merchantability, fitness
    for a particular purpose, and non-infringement. The laws of some jurisdictions do not permit waivers of certain warranties, so portions of the above disclaimer may not apply to
    User. In the event Medical Media Systems cannot waive any warranty, the duration and scope of such warranty will be the minimum permitted under applicable law.
  </p>

  <p>19. LIMITATION OF LIABILITY.</p>

  <p style="margin-bottom: 32px">
    Neither Medical Media Systems nor its affiliates, agents or licensors shall be liable under any claim, demand or action arising out of or relating to user's use of the Medical
    Media Systems information, nor Medical Media Systems' performance of (or failure to perform) any obligation under this agreement, nor for direct, special, incidental or
    consequential damages, including, without limitation, damages due to lost profits or business interruption, or other damages, even if Medical Media Systems, its affiliates,
    agents or licensors have been advised of the possibility of such loss or damages and whether or not such loss or damages is/are foreseeable and notwithstanding the failure of
    essential purpose of any limited remedy. The laws of some jurisdictions do not permit the disclaimer of liability for certain types of damages, so portions of the above may not
    apply to User.
  </p>

  <p>20. U. S. GOVERNMENT END USERS.</p>

  <p style="margin-bottom: 32px">
    The Medical Media Systems Information is a &quot;commercial item&quot; as that term is defined at FAR 2.101, consisting of &#x201C;Electronic and Information Technology
    (EIT)&#x201D; and &quot;commercial computer software documentation&quot; as such terms are used in 48 C.F.R. 12.212 and is provided to the U.S. Government only as a commercial
    end item. Consistent with FAR 12.212 and DFARS 227.7202, all U.S. Government End Users acquire the Medical Media Systems Information with only those rights set forth herein.
  </p>

  <p>21. EXPORT LAW.</p>

  <p style="margin-bottom: 32px">
    The Medical Media Systems Information and related technology are subject to U.S. export control laws and may be subject to export or import regulations in other countries. User
    agrees not to export the Medical Media Systems Information under any circumstances whatsoever, and by downloading the Medical Media Systems Information, User warrants that
    User's receipt of the Medical Media Systems Information does not violate the laws or regulations, including applicable sanctions or embargoes, of the U.S. or any other country.
    User will indemnify and hold Medical Media Systems harmless from any and all claims, losses, liabilities, damages, fines, penalties, costs and expenses (including attorney's
    fees) arising from or relating to any breach by User of its obligations and warranty under this paragraph. User's obligations under this paragraph will survive the expiration
    or termination of this Agreement.
  </p>

  <p>22. GENERAL.</p>

  <p style="margin-bottom: 32px">
    22.1 GOVERNING LAW. This Agreement and the legal relations between the parties arising hereunder shall be governed by and interpreted in accordance with the laws of the State
    of Washington exclusively, as such laws apply to contracts between Washington State residents performed entirely within Washington State. The Application of the United Nations Convention on
    Contracts for the International Sale of Goods is expressly excluded.
  </p>

  <p>22.2 ARBITRATION - CHOICE OF FORUM AND VENUE.</p>

  <p style="margin-bottom: 32px">
    Any dispute, controversy or claim arising out of or relating to this Agreement shall be settled by arbitration in Kitsap County Washington, by a single arbitrator in accordance
    with the American Arbitration Association (&quot;AAA&quot;) rules. The arbitrator shall be empowered to award only those damages which are permitted in this Agreement, subject
    to any disclaimers of damages and liability limits set forth herein. The award rendered by the arbitrator shall include costs of the arbitration and reasonable costs for
    experts and other witnesses. Judgment on the award may be entered in any court having jurisdiction. Nothing in this Agreement shall be deemed as preventing either party from
    seeking provisional relief from any court of competent jurisdiction, in order to protect that party's name or proprietary rights. The award of the arbitrator shall be final and
    binding upon the parties without appeal or review except as permitted by Washington State law.
  </p>

  <p>22.3 PRIVACY.</p>

  <p style="margin-bottom: 32px">
    Medical Media Systems' current privacy policies are available on the Website, at
    <a [routerLink]="['/privacy']">{{ baseUrl }}privacy</a>, and are incorporated herein by reference.
  </p>

  <p>22.4 WAIVER.</p>

  <p style="margin-bottom: 32px">
    The failure of either party to require performance by the other party of any provision hereof shall not affect the full right to require such performance at any time
    thereafter; nor shall the waiver by either party of a breach of any provision hereof be taken or held to be a waiver of the provision itself.
  </p>

  <p>22.5 ASSIGNMENT.</p>

  <p style="margin-bottom: 32px">
    Neither this Agreement nor any rights or obligations of User hereunder may be assigned or delegated by User in whole or in part without the prior written approval of Medical
    Media Systems. Any assignment or delegation in derogation of the foregoing shall be null and void.
  </p>

  <p>22.6 SEVERABILITY.</p>

  <p style="margin-bottom: 32px">
    If any part of this Agreement is for any reason found to be unenforceable, all other parts nevertheless remain enforceable as long as a party's rights under this Agreement are
    not materially affected. In lieu of the unenforceable provision, the parties will substitute or add as part of this Agreement a provision that will be as similar as possible in
    economic and business objectives as was intended by the unenforceable provision.
  </p>

  <p>22.7 COMPLETE AGREEMENT.</p>

  <p style="margin-bottom: 32px">
    This Agreement is the complete and exclusive statement of the agreement between Medical Media Systems and User which supersedes any proposal or prior agreement, oral or
    written, and any other communications between the parties in relation to the subject matter of this Agreement.
  </p>

  <p style="margin-bottom: 32px">
    These Terms and Conditions govern your use of the Service. You also may be subject to additional terms and conditions that may apply when you use third party content or
    services available through the Service. If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such
    provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect. The failure of Medical Media
    Systems to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or condition. You agree that regardless of any
    statute or law to the contrary, any claim or cause of action arising out of or related to the Service or these Terms and Conditions must be filed within one year after such
    claim or cause of action arose. The section titles of these Terms and Conditions are merely for convenience and will not have any effect on the substantive meaning of this
    Agreement.
  </p>

  <p style="margin-bottom: 32px">
    The website, its content and any provided software is protected by United States copyright law and international treaty. Unauthorized reproduction or distribution is subject to
    civil and criminal penalties.
  </p>

  <!-- MAIN CONTENT -->
  <h3>Privacy Policy</h3>

  <p style="margin-bottom: 32px">Please take a moment to read the following policy to learn how we handle your personal information.</p>

  <p style="margin-bottom: 32px">
    At Medical Media Systems, we are committed to respecting your privacy. We recognize that when you choose to provide us with information about yourself, you trust us to act in a
    responsible manner. We take this trust very seriously, and that's why we have put a policy in place to protect your personal information and to document our commitment to you
    that we will not sell or share your personally identifiable information, including your name, email address, or the information you look up in our medical references, without
    your permission or as required by law.
  </p>

  <p id="anchortop">
    The Medical Media Systems Privacy Policy set out below describes our policies and practices regarding the information we collect from you, how we use that information, and our
    commitment to you to safeguard that information. Specifically, this policy addresses the following topics:
  </p>
  <div class="indented" style="margin-bottom: 32px">
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor1')">What information does Medical Media Systems collect? </span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor2')">How does Medical Media Systems use the information we collect?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor3')">Does Medical Media Systems track your use of its products?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor4')">How does Medical Media Systems protect your information?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor5')">Will Medical Media Systems share the information it collects with others?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor6')">Choice and access to your information</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor7')">Cookies, links and other matters</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor8')">Important terms</span></div>
  </div>

  <p id="anchor1" class="anchor">What information does Medical Media Systems collect?</p>

  <p>
    At times, we may request that you voluntarily supply us with personal information. Generally this information is requested when you register for a software subscription or
    online service or when you provide information in connection with an online transaction.
  </p>

  <div class="indented" style="margin-bottom: 32px">
    <p>
      &#x2022; When you order, we ask you for your name, email address, billing address, telephone number, and credit card information so that we can complete the transaction. We
      also ask for demographic and contact information including your name, email address, date of birth, profession, specialty, and educational level so that we can provide you
      with more customized service and information as well as to insure that only health professionals have access to our website.
    </p>
    <p>
      &#x2022; There may be other instances when we ask for information from you. For example, when you participate in a survey or product test or when you enter a contest or other
      promotional event, we may ask for additional personal information. The information collected, its purpose, and how/by whom it will be used will be explained to you at the
      point of collection if jointly offered with a partner organization or within the accompanying terms for other situations.
    </p>
    <p>
      Additionally, we may supplement your data with information from third parties, including the AMA. For example, we might compare the name, school and professional information
      you provide with relevant practitioner databases to help verify the accuracy of our subscriber database.
    </p>
  </div>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor2" class="anchor">How does Medical Media Systems use the information we collect?</p>
  <p style="margin-bottom: 32px">
    One of Medical Media Systems' strengths is its community of subscribers. At Medical Media Systems, we strive to extend beyond our applications and provide other services that
    bring additional value to our subscribers. To do this, we need to understand who our subscribers are and collect information that will assist us in tailoring subscribers'
    experiences.
  </p>

  <p style="margin-bottom: 32px">
    Your satisfaction with our services is essential to our success, and so we use the information we collect carefully. Medical Media Systems uses your personal information to
    operate our website and/or affect your transactions, and to assist with the continual improvement of the content and function of Medical Media Systems products. For this
    reason, we monitor customer traffic patterns and usage to help us improve the design, layout, and usability of our products and to provide content and product features of
    interest to you.
  </p>

  <p style="margin-bottom: 32px">
    We use contact and demographic information to contact you regarding relevant Medical Media Systems services and products. For instance, we may contact you (for example, by
    email) to let you know that we have released a new product or service or a new version of an existing product. We may also use contact and demographic information to send you
    materials from some of our partners or to invite you to provide us with feedback or to participate in a survey being conducted by one of our partners. If you do not wish to
    receive these kinds of emails, you may choose to opt out by updating your profile.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor3" class="anchor">Does Medical Media Systems track your use of its products?</p>
  <p style="margin-bottom: 32px">
    Medical Media Systems does collect usage information about our products, such as which streaming video segments are viewed. We use this data to improve our subscribers&#x2019;
    experience and to prioritize new product development. Most importantly, we do not sell personally identifiable information about your use of Medical Media Systems products to
    anyone.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor4" class="anchor">How does Medical Media Systems protect your information?</p>
  <p style="margin-bottom: 32px">
    Medical Media Systems safeguards the security of the data you send us with physical, electronic, and managerial procedures. These procedures include limiting access to the
    information in various ways depending on the nature of the information as well as training our employees on the proper handling of subscriber information. While we believe we
    have taken appropriate measures to protect the information that we have received, we urge you to take every precaution to protect your personal data when you are on the
    Internet. Change your passwords often, use a combination of letters and numbers, and make sure you use a secure browser.
  </p>

  <p style="margin-bottom: 32px">
    When you place orders or access your account information, we offer the use of a secure server. The secure server software (SSL) encrypts all information you input before it is
    sent to us. Furthermore, we take extra steps to protect this data from unauthorized access, disclosure, alteration, or destruction.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor5" class="anchor">Will Medical Media Systems share the information it collects with others?</p>
  <p style="margin-bottom: 32px">
    As a rule, Medical Media Systems does not share or sell its subscribers' email addresses, or disclose the identity of its subscribers; or, most importantly, disclose
    information on how subscribers are using Medical Media Systems products, except in aggregate or non-personally identifiable form. Such tracking data is used internally by
    Medical Media Systems primarily for developing and enhancing products and improving technical usability. For more information, refer to &quot;How does Medical Media Systems use
    the information we collect?&quot;
  </p>

  <p style="margin-bottom: 32px">
    However, we obviously must disclose certain information to credit card processors when you make an online purchase. The service providers we use are not permitted to use your
    information for any secondary purposes. Medical Media Systems may also disclose personal information as allowed or required by applicable law, including making disclosures that
    are necessary or advisable to: (a) protect the rights, safety, or property of subscribers of our website and products, the public, or Medical Media Systems, and (b) conform to
    legal or regulatory requirements. No other disclosures of personally identifiable information will be made without your prior consent.
  </p>

  <p style="margin-bottom: 32px">
    Medical Media Systems may provide aggregate or non-personally identifiable statistics about our customers, sales, product usage or traffic patterns, and related website
    information, but these statistics will not include any personally identifying information. For example, we may share aggregated demographic data about our subscribers, such as
    the number of Medical Media Systems subscribers in Washington or the observation that students have more need for heart sound interpretation tutorials than attending physicians.
  </p>

  <p style="margin-bottom: 32px">An exception to this is regarding reported usage to the educational institution where the User is enrolled, per agreement to our Terms.</p>

  <p style="margin-bottom: 32px">
    Please be assured, however, that we do not sell your name, email address, contact information, software usage information, or any other other personally identifiable
    information to outside or third parties.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor6" class="anchor">Choice and Access to Your Information</p>
  <p style="margin-bottom: 32px">
    Medical Media Systems tries to provide you with relevant information on a variety of topics through our newsletters and other email communications. At the same time, we
    understand that not every subscriber is interested in all of the information and opportunities we offer. So that you may customize your experience with Medical Media Systems,
    we ask you to select the types of email that you want to receive. The initial selection is made during the registration process, but you can opt into or out of any of these
    email services at any time by updating your profile as described below. Go to the bottom of the registration form, or click on &#x201C;Update E-mail Services&#x201D; and select
    your choices; if you unselect a box, you will NOT receive emails of the type listed.
  </p>

  <p style="margin-bottom: 32px">
    You also have the option of correcting, updating, and/or changing contact and demographic information and other matters previously provided and stored on our database. If you
    wish to correct, update, or change your information, simply update your profile.
  </p>

  <p style="margin-bottom: 32px">
    To update your profile, please go to the the home page of our website at https://www.medicalmediasystems.com and sign in using your Medical Media Systems username and password.
    Then, click &quot;Update My Profile&quot; and provide or edit the appropriate information.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor7" class="title-text">Cookies, Links and Other Matters</p>

  <p class="title-text">What about &quot;cookies&quot; and web server log files?</p>

  <p style="margin-bottom: 32px">
    &quot;Cookies&quot; are small pieces of information that are stored by your browser on your computer's hard drive. We use both cookies session and persistent cookies on our
    website to help us provide you with more personalized service. Most web browsers automatically accept cookies, but you can usually change your browser to prevent that if you
    would rather not store the information for future use. Disabling cookies in your browser may prevent access to some parts of our website.
  </p>

  <p style="margin-bottom: 32px">
    As is true of most websites, we gather and use certain information automatically and store it in log files. This information includes Internet protocol (IP) addresses, browser
    type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp. We use this information to analyze trends, to administer the website, to track
    users&#x2019; movements around the website and to gather demographic information about our user base as a whole. We do not link the information we store in cookies to any
    personally identifiable information you submit while on our website.
  </p>

  <p class="title-text">What about links to other websites?</p>

  <p style="margin-bottom: 32px">
    The Medical Media Systems website may from time to time contain links to or from other websites. Medical Media Systems does not share your personal information with those
    websites and is not responsible for their privacy practices. We encourage you to learn about the privacy policies of those companies.
  </p>

  <p class="title-text">What about changes to this statement?</p>
  <p>The Medical Media Systems Privacy Policy is subject to change at any time.</p>
  <p>
    By using our website, you consent to the collection and use of this information by Medical Media Systems as disclosed in this statement. If we decide to materially change our
    privacy practices, we will post a prominent notice of those changes on the homepage and/or send an email so that you are always kept informed of what data we collect, how we
    use it, and under what circumstances we disclose it. Please check this page for changes from time to time to make sure you are aware of our latest privacy practices.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor8" class="title-text">Important Terms</p>

  <p style="margin-bottom: 32px">
    In this policy, when we use the term &quot;we&quot; or &quot;our&quot;, we mean both Medical Media Systems and third parties with whom Medical Media Systems contracts to assist
    in its business operations. These contractors may, however, only access your information in furtherance of their services to Medical Media Systems and may not use or disclose
    your information except as permitted by Medical Media Systems.
  </p>

  <p class="title-text">What is &quot;personally identifiable&quot; information?</p>
  <p style="margin-bottom: 32px">
    When reviewing any privacy policy on the Internet, you should be familiar with the terms &quot;personally identifiable&quot; and &quot;non-personally identifiable&quot;
    information. &quot;Personally identifiable information&quot; is any information that can be used to identify, contact or locate you as an individual, as well as any additional
    data tied to such information. Common examples are your name and email address, along with purchase information associated with those fields. Non-personally identifiable
    information is information, either in &quot;profile&quot; or &quot;aggregate&quot; form &#151; that, in contrast, is not intended to identify you as an individual. For
    instance, we may store a profile of an oncologist in New York without tying this information to a name, address, or other personally identifiable information. We may also
    aggregate information to produce general data, such as figures that show that half of our subscribers are over thirty-five years old.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p class="title-text">Tell us what you think</p>

  <p style="margin-bottom: 32px">
    Our goal is to respect your privacy. To help us improve our privacy policies, please give us your feedback. If you have any questions or suggestions about this privacy
    statement, please contact us at:
    <a href="mailto:support@medicalmediasystems.com">support@medicalmediasystems.com</a>
  </p>
</app-content-balloon>
