<app-content-balloon>
  <p style="margin-bottom: 32px">Please take a moment to read the following policy to learn how we handle your personal information.</p>

  <p style="margin-bottom: 32px">
    At Medical Media Systems, we are committed to respecting your privacy. We recognize that when you choose to provide us with information about yourself, you trust us to act in a
    responsible manner. We take this trust very seriously, and that's why we have put a policy in place to protect your personal information and to document our commitment to you
    that we will not sell or share your personally identifiable information, including your name, email address, or the information you look up in our medical references, without
    your permission or as required by law.
  </p>

  <p id="anchortop">
    The Medical Media Systems Privacy Policy set out below describes our policies and practices regarding the information we collect from you, how we use that information, and our
    commitment to you to safeguard that information. Specifically, this policy addresses the following topics:
  </p>
  <div class="indented" style="margin-bottom: 32px">
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor1')">What information does Medical Media Systems collect? </span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor2')">How does Medical Media Systems use the information we collect?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor3')">Does Medical Media Systems track your use of its products?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor4')">How does Medical Media Systems protect your information?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor5')">Will Medical Media Systems share the information it collects with others?</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor6')">Choice and access to your information</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor7')">Cookies, links and other matters</span></div>
    <div>&#x2022; <span class="link" (click)="scrollTo('anchor8')">Important terms</span></div>
  </div>

  <p id="anchor1" class="anchor">What information does Medical Media Systems collect?</p>

  <p>
    At times, we may request that you voluntarily supply us with personal information. Generally this information is requested when you register for a software subscription or
    online service or when you provide information in connection with an online transaction.
  </p>

  <div class="indented" style="margin-bottom: 32px">
    <p>
      &#x2022; When you order, we ask you for your name, email address, billing address, telephone number, and credit card information so that we can complete the transaction. We
      also ask for demographic and contact information including your name, email address, date of birth, profession, specialty, and educational level so that we can provide you
      with more customized service and information as well as to insure that only health professionals have access to our website.
    </p>
    <p>
      &#x2022; There may be other instances when we ask for information from you. For example, when you participate in a survey or product test or when you enter a contest or other
      promotional event, we may ask for additional personal information. The information collected, its purpose, and how/by whom it will be used will be explained to you at the
      point of collection if jointly offered with a partner organization or within the accompanying terms for other situations.
    </p>
    <p>
      Additionally, we may supplement your data with information from third parties, including the AMA. For example, we might compare the name, school and professional information
      you provide with relevant practitioner databases to help verify the accuracy of our subscriber database.
    </p>
  </div>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor2" class="anchor">How does Medical Media Systems use the information we collect?</p>
  <p style="margin-bottom: 32px">
    One of Medical Media Systems' strengths is its community of subscribers. At Medical Media Systems, we strive to extend beyond our applications and provide other services that
    bring additional value to our subscribers. To do this, we need to understand who our subscribers are and collect information that will assist us in tailoring subscribers'
    experiences.
  </p>

  <p style="margin-bottom: 32px">
    Your satisfaction with our services is essential to our success, and so we use the information we collect carefully. Medical Media Systems uses your personal information to
    operate our website and/or affect your transactions, and to assist with the continual improvement of the content and function of Medical Media Systems products. For this
    reason, we monitor customer traffic patterns and usage to help us improve the design, layout, and usability of our products and to provide content and product features of
    interest to you.
  </p>

  <p style="margin-bottom: 32px">
    We use contact and demographic information to contact you regarding relevant Medical Media Systems services and products. For instance, we may contact you (for example, by
    email) to let you know that we have released a new product or service or a new version of an existing product. We may also use contact and demographic information to send you
    materials from some of our partners or to invite you to provide us with feedback or to participate in a survey being conducted by one of our partners. If you do not wish to
    receive these kinds of emails, you may choose to opt out by updating your profile.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor3" class="anchor">Does Medical Media Systems track your use of its products?</p>
  <p style="margin-bottom: 32px">
    Medical Media Systems does collect usage information about our products, such as which streaming video segments are viewed. We use this data to improve our subscribers&#x2019;
    experience and to prioritize new product development. Most importantly, we do not sell personally identifiable information about your use of Medical Media Systems products to
    anyone.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor4" class="anchor">How does Medical Media Systems protect your information?</p>
  <p style="margin-bottom: 32px">
    Medical Media Systems safeguards the security of the data you send us with physical, electronic, and managerial procedures. These procedures include limiting access to the
    information in various ways depending on the nature of the information as well as training our employees on the proper handling of subscriber information. While we believe we
    have taken appropriate measures to protect the information that we have received, we urge you to take every precaution to protect your personal data when you are on the
    Internet. Change your passwords often, use a combination of letters and numbers, and make sure you use a secure browser.
  </p>

  <p style="margin-bottom: 32px">
    When you place orders or access your account information, we offer the use of a secure server. The secure server software (SSL) encrypts all information you input before it is
    sent to us. Furthermore, we take extra steps to protect this data from unauthorized access, disclosure, alteration, or destruction.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor5" class="anchor">Will Medical Media Systems share the information it collects with others?</p>
  <p style="margin-bottom: 32px">
    As a rule, Medical Media Systems does not share or sell its subscribers' email addresses, or disclose the identity of its subscribers; or, most importantly, disclose
    information on how subscribers are using Medical Media Systems products, except in aggregate or non-personally identifiable form. Such tracking data is used internally by
    Medical Media Systems primarily for developing and enhancing products and improving technical usability. For more information, refer to &quot;How does Medical Media Systems use
    the information we collect?&quot;
  </p>

  <p style="margin-bottom: 32px">
    However, we obviously must disclose certain information to credit card processors when you make an online purchase. The service providers we use are not permitted to use your
    information for any secondary purposes. Medical Media Systems may also disclose personal information as allowed or required by applicable law, including making disclosures that
    are necessary or advisable to: (a) protect the rights, safety, or property of subscribers of our website and products, the public, or Medical Media Systems, and (b) conform to
    legal or regulatory requirements. No other disclosures of personally identifiable information will be made without your prior consent.
  </p>

  <p style="margin-bottom: 32px">
    Medical Media Systems may provide aggregate or non-personally identifiable statistics about our customers, sales, product usage or traffic patterns, and related website
    information, but these statistics will not include any personally identifying information. For example, we may share aggregated demographic data about our subscribers, such as
    the number of Medical Media Systems subscribers in Washington State or the observation that students have more need for heart sound interpretation tutorials than attending physicians.
  </p>

  <p style="margin-bottom: 32px">An exception to this is regarding reported usage to the educational institution where the User is enrolled, per agreement to our Terms.</p>

  <p style="margin-bottom: 32px">
    Please be assured, however, that we do not sell your name, email address, contact information, software usage information, or any other other personally identifiable
    information to outside or third parties.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor6" class="anchor">Choice and Access to Your Information</p>
  <p style="margin-bottom: 32px">
    Medical Media Systems tries to provide you with relevant information on a variety of topics through our newsletters and other email communications. At the same time, we
    understand that not every subscriber is interested in all of the information and opportunities we offer. So that you may customize your experience with Medical Media Systems,
    we ask you to select the types of email that you want to receive. The initial selection is made during the registration process, but you can opt into or out of any of these
    email services at any time by updating your profile as described below. Go to the bottom of the registration form, or click on &#x201C;Update E-mail Services&#x201D; and select
    your choices; if you unselect a box, you will NOT receive emails of the type listed.
  </p>

  <p style="margin-bottom: 32px">
    You also have the option of correcting, updating, and/or changing contact and demographic information and other matters previously provided and stored on our database. If you
    wish to correct, update, or change your information, simply update your profile.
  </p>

  <p style="margin-bottom: 32px">
    To update your profile, please go to the the home page of our website at {{ baseUrl }} and sign in using your Medical Media Systems username and password. Then, click
    &quot;Update My Profile&quot; and provide or edit the appropriate information.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor7" class="title-text">Cookies, Links and Other Matters</p>

  <p class="title-text">What about &quot;cookies&quot; and web server log files?</p>

  <p style="margin-bottom: 32px">
    &quot;Cookies&quot; are small pieces of information that are stored by your browser on your computer's hard drive. We use both cookies session and persistent cookies on our
    website to help us provide you with more personalized service. Most web browsers automatically accept cookies, but you can usually change your browser to prevent that if you
    would rather not store the information for future use. Disabling cookies in your browser may prevent access to some parts of our website.
  </p>

  <p style="margin-bottom: 32px">
    As is true of most websites, we gather and use certain information automatically and store it in log files. This information includes Internet protocol (IP) addresses, browser
    type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp. We use this information to analyze trends, to administer the website, to track
    users&#x2019; movements around the website and to gather demographic information about our user base as a whole. We do not link the information we store in cookies to any
    personally identifiable information you submit while on our website.
  </p>

  <p class="title-text">What about links to other websites?</p>

  <p style="margin-bottom: 32px">
    The Medical Media Systems website may from time to time contain links to or from other websites. Medical Media Systems does not share your personal information with those
    websites and is not responsible for their privacy practices. We encourage you to learn about the privacy policies of those companies.
  </p>

  <p class="title-text">What about changes to this statement?</p>
  <p>The Medical Media Systems Privacy Policy is subject to change at any time.</p>
  <p>
    By using our website, you consent to the collection and use of this information by Medical Media Systems as disclosed in this statement. If we decide to materially change our
    privacy practices, we will post a prominent notice of those changes on the homepage and/or send an email so that you are always kept informed of what data we collect, how we
    use it, and under what circumstances we disclose it. Please check this page for changes from time to time to make sure you are aware of our latest privacy practices.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p id="anchor8" class="title-text">Important Terms</p>

  <p style="margin-bottom: 32px">
    In this policy, when we use the term &quot;we&quot; or &quot;our&quot;, we mean both Medical Media Systems and third parties with whom Medical Media Systems contracts to assist
    in its business operations. These contractors may, however, only access your information in furtherance of their services to Medical Media Systems and may not use or disclose
    your information except as permitted by Medical Media Systems.
  </p>

  <p class="title-text">What is &quot;personally identifiable&quot; information?</p>
  <p style="margin-bottom: 32px">
    When reviewing any privacy policy on the Internet, you should be familiar with the terms &quot;personally identifiable&quot; and &quot;non-personally identifiable&quot;
    information. &quot;Personally identifiable information&quot; is any information that can be used to identify, contact or locate you as an individual, as well as any additional
    data tied to such information. Common examples are your name and email address, along with purchase information associated with those fields. Non-personally identifiable
    information is information, either in &quot;profile&quot; or &quot;aggregate&quot; form &#151; that, in contrast, is not intended to identify you as an individual. For
    instance, we may store a profile of an oncologist in New York without tying this information to a name, address, or other personally identifiable information. We may also
    aggregate information to produce general data, such as figures that show that half of our subscribers are over thirty-five years old.
  </p>

  <p style="margin-bottom: 32px">
    <span class="link" (click)="scrollTo('anchortop')">top</span>
  </p>

  <p class="title-text">Tell us what you think</p>

  <p style="margin-bottom: 32px">
    Our goal is to respect your privacy. To help us improve our privacy policies, please give us your feedback. If you have any questions or suggestions about this privacy
    statement, please contact us at:
    <a href="mailto:support@medicalmediasystems.com">support@medicalmediasystems.com</a>
  </p>
</app-content-balloon>
