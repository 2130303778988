import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { validate } from 'secure-password-validator';

@Injectable({
  providedIn: 'root',
})
export class PasswordValidator {
  validatePassword(password: string): { valid: boolean; message?: string } {
    if (password === null || password === undefined) {
      return { valid: false, message: 'Password cannot be empty' };
    }

    let message = '';
    const result = validate(password, {
      minLength: 8,
      digits: true,
      letters: true,
      uppercase: true,
      lowercase: true,
      symbols: true,
    });

    if (!result.valid) {
      const items = [];
      let isAnd = false;

      message = 'Password must';

      if (result.errors.indexOf('MIN_LENGTH') >= 0) {
        isAnd = result.errors.length > 1;
        message = 'Password must be at least 8 characters';
      }

      if (result.errors.indexOf('DIGITS') >= 0) {
        items.push('number');
      }

      if (result.errors.indexOf('UPPERCASE') >= 0) {
        items.push('upper case letter');
      }

      if (result.errors.indexOf('LOWERCASE') >= 0) {
        items.push('lower case letter');
      }

      if (result.errors.indexOf('SYMBOLS') >= 0) {
        items.push('symbol');
      }

      if (isAnd) {
        message = message + ' and ';
      }

      if (items.length > 0) {
        message = message + ' contain at least 1 ' + items.join(', ');
      }
    } else {
      message = '';
    }

    return {
      valid: result.valid,
      message,
    };
  }

  validateForForm(control: AbstractControl): ValidationErrors | null {
    const result = this.validatePassword(control.value);

    return !result.valid ? { password: result.message } : null;
  }
}
