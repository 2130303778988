<!--- DESKTOP / TABLET --->
<div *ngIf="isDesktop() || isTablet()" class="component-content">
  <div style="z-index: -2; position: absolute; left: 0; top: -25px; overflow: hidden; max-height: calc(100%)">
    <img style="width: 100%" alt="Angular Logo" src="/images/Landing-page-144DPI.jpg" />
  </div>
</div>

<!--- MOBILE --->
<div *ngIf="isMobile()" class="component-content">
  <img width="100%" alt="Angular Logo" src="/images/mobile_hands_backgrund.png" />
</div>
