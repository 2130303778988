<app-content-balloon width="300px">
  <div class="d-flex flex-column center" style="margin-bottom: 16px">
    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <input matInput [(ngModel)]="username" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput type="password" [(ngModel)]="password" />
    </mat-form-field>
  </div>
  <div class="d-flex flex-row" style="font-size: 12px">
    <div style="flex: 1 1 auto" class="d-flex flex-column">
      <div style="flex: 1 1 auto"><a [routerLink]="['/forgot-password']">Forgot your password?</a></div>
      <div style="flex: 1 1 auto"><input type="checkbox" [(ngModel)]="staySignedIn" v />Stay Signed In</div>
    </div>
    <div style="flex: 1 1 auto">
      <button mat-flat-button (click)="login()" color="primary">Sign In</button>
    </div>
  </div>
  <div class="center" style="margin-top: 32px; font-size: 12px">
    <p>Don't have an account? Click <a [routerLink]="['/register']">here</a> to subscribe!</p>
  </div>
</app-content-balloon>
