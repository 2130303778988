import { Component } from "@angular/core";

@Component({
  selector: "app-support-login",
  templateUrl: "./support-login.component.html",
  styleUrls: ["./support-login.component.css"],
})
export class SupportLoginComponent {
  constructor() {}
}
