import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { StaticPagesService } from "../static-pages.service";

@Component({
  selector: "app-static-pages",
  templateUrl: "./static-pages.component.html",
  styleUrls: ["./static-pages.component.css"],
})
export class StaticPagesComponent implements OnInit {
  content: SafeHtml;

  constructor(
    private activatedRoute: ActivatedRoute,
    private staticPages: StaticPagesService,
    private sanitizer: DomSanitizer
  ) {}

  transform(v: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v);
  }

  ngOnInit() {
    var thisPtr = this;

    this.activatedRoute.queryParams.subscribe((params) => {
      this.staticPages.getPageHtml(params["target"]).then((content) => {
        this.content = this.transform(
          content
            .replace(/<body>/gi, "")
            .replace(/<\/body>/gi, "")
            .replace(/<html>/gi, "")
            .replace(/<\/html>/gi, "")
            .replace(/<br \/>/gi, "")
        );
      });
    });
  }
}
