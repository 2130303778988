import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class StaticPagesService {
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {}

  getPageHtml(target: string): Promise<string> {
    const url = `${this.baseUrl}pages/${target}.html`;

    return this.http.get(url, { responseType: "text" }).toPromise();
  }
}
