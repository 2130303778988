<app-content-balloon width="750px">
  <div class="section">
    <div class="title-text">Contact Us</div>

    <p>
      We welcome your questions, comments and suggestions. Feel free to tell us how we can make our site better, or what additional video segments you would like to see, either of
      examination technique, or of abnormal findings. (Email will get you the fastest response, but if you need to call, fax, or mail, please click
      <a [routerLink]="['/contact-information']">HERE</a> for our contact information.)
    </p>

    <input type="hidden" name="send" value="1" />

    <div class="d-flex flex-row">
      <div class="d-flex flex-column" style="flex: 1 1" *ngIf="form">
        <form [formGroup]="form">
          <div class="content-section">
            <div class="title-text header">Personal Information</div>
            <div class="content-section-content">
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input formControlName="firstName" required matInput />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input formControlName="lastName" required matInput />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Zip / Postal Code</mat-label>
                <input formControlName="zipCode" required matInput />
              </mat-form-field>
              <app-country-select [required]="true" [value]="form.controls['country'].value" (valueChanged)="onCountryChanged($event)"></app-country-select>
              <mat-form-field>
                <mat-label>Telephone</mat-label>
                <input formControlName="phoneNumber" required matInput />
              </mat-form-field>
              <mat-form-field>
                <mat-label>E-Mail Address</mat-label>
                <input formControlName="email" required matInput />
              </mat-form-field>
            </div>
          </div>
          <div class="content-section">
            <div class="title-text header">Professional Classification</div>
            <div class="content-section-content">
              <mat-form-field>
                <mat-label>Profession</mat-label>
                <mat-select formControlName="profession">
                  <mat-option value="" selected>-- please select --</mat-option>
                  <mat-option value="Medicine" selected>Medicine (MD or DO)</mat-option>
                  <mat-option value="Nursing">Nursing</mat-option>
                  <mat-option value="Dentistry">Dentistry</mat-option>
                  <mat-option value="Podiatry">Podiatry</mat-option>
                  <mat-option value="Chiropractic">Chiropractic</mat-option>
                  <mat-option value="Naturopathy">Naturopathy</mat-option>
                  <mat-option value="Allied Health">Allied Health</mat-option>
                  <mat-option value="EMT / Paramedic">EMT / Paramedic</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="this.form.controls['profession'].value === 'Other'">
                <mat-label>If Other Profession, Please Explain:</mat-label>
                <input formControlName="professionOther" matInput />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Level of Training</mat-label>
                <mat-select formControlName="training">
                  <mat-option value="" selected>-- please select --</mat-option>
                  <mat-option value="Student">Student</mat-option>
                  <mat-option value="Resident">Resident</mat-option>
                  <mat-option value="Post-Graduate Student">Post-Graduate Student (MA, PhD)</mat-option>
                  <mat-option value="Practitioner or Instructor" selected>Practitioner or Instructor</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Specialty or Major:</mat-label>
                <input formControlName="specialty" matInput />
              </mat-form-field>
            </div>
          </div>
          <div class="content-section">
            <div class="title-text header">Message</div>
            <div class="content-section-content">
              <textarea formControlName="message" required wrap="soft" cols="66" rows="10"></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column">
    <div *ngIf="showRecaptcha" class="d-flex flex-row" style="padding-bottom: 16px">
      <div class="flex-1-1-auto"></div>
      <re-captcha #recaptcha (resolved)="handleCorrectCaptcha($event)" type="image"></re-captcha>
      <div class="flex-1-1-auto"></div>
    </div>
    <div class="d-flex flex-row center" style="padding-bottom: 16px">
      <button mat-raised-button color="primary" *ngIf="showSubmitContactButton" (click)="doContact()" mat-button [disabled]="!this.form?.valid || (!recaptchaSucceeded && showRecaptcha)">
        Submit
      </button>
      <div *ngIf="!showSubmitContactButton" style="font-weight: bold">Sending contact request...Please Wait</div>
    </div>
  </div>
</app-content-balloon>
