import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OtpDialogData } from "../model/mms.model";

@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss']
})
export class OtpDialogComponent implements OnInit {

  verificationCode: string;
  verificationFailed = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: OtpDialogData, private _dialogRef: MatDialogRef<OtpDialogComponent>) { }

  ngOnInit() {
    this.data.sendVerificationCode().subscribe(response => {
      if (!response.success) {
        this.data.success = false;
        this.data.dataToken = null;
        this.data.message = 'Verification email could not be sent';
        this.verificationFailed = true;
        console.log('oops! could not send code');
        this._dialogRef.close({ success: false, message: response.message });
        this.data.onVerifyFailure?.();
      }
    });
  }

  buttonClicked(): void {
    if (!this.verificationCode) {
      return;
    }

    this.data.verify(this.verificationCode).subscribe(response => {
      if (!response.success) {
        // Verification failed, clear code, display error and try again
        this.verificationCode = null;
        this.verificationFailed = true;
      } else {
        // Verification succeeded, save token and pass back to application.
        this.data.success = true;
        this.data.dataToken = response.dataToken;
        this.data.message = null;
        this._dialogRef.close(this.data);
      }
    });
   }

  cancelClicked(): void {
    // user clicked cancel.
    this.data.success = false;
    this.data.message = 'user canceled';
    this._dialogRef.close(this.data);
  }

  isDisabled(): boolean {
    if (this.verificationCode === null || this.verificationCode === undefined) {
      return true;
    }

    return this.verificationCode.length <= 0;
  }
}
