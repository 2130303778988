import { Component, Inject } from "@angular/core";

@Component({
  selector: 'app-sound-dialog',
  templateUrl: './sound-dialog.component.html'
})
export class SoundDialogComponent {
  public siteName: string;

  constructor(@Inject('BASE_URL') private _baseUrl: string) {
    this.siteName = this._baseUrl.replace('http://', '').replace('https://', '').replace(/\/$/, '');
  }
}
