<div class="menu-container">
  <div class="mms-logo">
    <a href="/"><img src="/images/MMS-logo.gif" alt="logo" /></a>
  </div>
  <div class="login-box" *ngIf="!isIpAuth">
    <div *ngIf="!isAuthenticated">
      <div>
        <input #loginUsername type="text" placeholder="Email" [(ngModel)]="username" (keydown.enter)="login()" />
      </div>
      <div>
        <input type="password" placeholder="Password" [(ngModel)]="password" (keydown.enter)="login()" />
      </div>
      <div class="button-row">
        <input type="checkbox" [(ngModel)]="staySignedIn" v />Stay Signed In
        <input type="button" value="Login" (click)="login()" />
      </div>
      <div class="centered">
        <a [routerLink]="['/forgot-password']">Forgot your password?</a>
      </div>
    </div>
    <div *ngIf="isAuthenticated">
      <div>Welcome, {{ userService.loggedInUser?.firstName }} {{ userService.loggedInUser?.lastName }}!</div>
      <div><input type="button" value="Log Out" (click)="authService.logout()" /></div>
    </div>
  </div>

  <div class="menu-spacer"></div>

  <div class="menu-buttons">
    <button *ngIf="!isAuthenticated" mat-button [class]="videoPlaybackCss" (click)="navigate('videoPlayback')">DEMO</button>

    <button *ngIf="isAuthenticated" mat-button [class]="programsCss" (click)="navigate('programs')">PROGRAMS</button>

    <button *ngIf="isAuthenticated" mat-button [class]="simulationsCss" (click)="navigate('simulations')">SIMULATIONS</button>

    <button mat-button [class]="textbookCss" (click)="navigate('textbook')">TEXTBOOK</button>

    <button *ngIf="!isAuthenticated" mat-button [class]="registerCss" (click)="navigate('register')">REGISTER</button>

    <button *ngIf="isAuthenticated && showAccountMenu" mat-button [class]="accountCss" (click)="navigate('account')">ACCOUNT</button>

    <button mat-button [class]="helpCss" (click)="navigate('help')">HELP</button>
  </div>
</div>

<!--- MOBILE --->
<div class="mobile-menu-container">
  <div class="mobile-button-row">
    <div class="mobile-actions">
      <div>
        <a [routerLink]="['/mobile-navigation']"><img alt="mobile-navigation" src="/images/mobile-main-menu-button.jpg" /></a>
      </div>
      <div *ngIf="isAuthenticated">
        <a [routerLink]="['/mobile-programs']"><img alt="programs" src="/images/mobile-programs-button.jpg" /></a>
      </div>
      <div *ngIf="!isAuthenticated">
        <a [routerLink]="['/videoPlayback']"><img alt="demo" src="/images/mobile-programs-button.jpg" /></a>
      </div>
    </div>
    <div class="mobile-logo">
      <a [routerLink]="['/landing']"><img alt="home" src="/images/MMS-logo.gif" /></a>
    </div>
    <div class="mobile-login-box">
      <div *ngIf="!isAuthenticated">
        <a [routerLink]="['/mobile-login']">Sign In</a>
      </div>
      <div *ngIf="isAuthenticated"><a (click)="authService.logout()">Log Out</a></div>
    </div>
  </div>
</div>
