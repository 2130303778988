<div class="content-section">
  <h3>{{title}}</h3>

  <div class="content-section-content flex-1-0-auto">
    <div *ngIf="step === 0">
      <ng-content select="[initial]"></ng-content>
    </div>

    <div *ngIf="step === 1" class="d-flex flex-column">
      <form [formGroup]="form">
        <p>An email has been sent to {{emailAddress}} with a verification code.  Please check your email and enter that code below.</p>
        <em>This code is only valid for 15 minutes.</em>

        <div class="content-section-content">
          <mat-form-field appearance="standard">
            <mat-label>Verification Code</mat-label>
            <input matInput maxlength="6" formControlName="verificationCode" (keyup.enter)="buttonClicked()" />
          </mat-form-field>
        </div>
      </form>
    </div>

    <div *ngIf="step === 2 && hasActionStep">
      <ng-content select="[action]"></ng-content>
    </div>
  </div>

  <div class="button-bar">
    <button mat-raised-button (click)="buttonClicked()" [disabled]="isButtonDisabled()">{{getButtonText()}}</button>
  </div>
</div>
