<div class="content-container d-flex flex-column">
  <!-- Video menu bar across the top -->
  <div class="center" style="flex: 0 0 auto">
    <ng-container *ngFor="let menuItem of menuHierarchy">
      <span *ngIf="menuItem.title.trim() !== ''">
        <button
          mat-button
          #menuTrigger="matMenuTrigger"
          (click)="openAVideoMenu(menuTrigger, 250, $event)"
          (touchstart)="startTouch()"
          (mouseenter)="openAVideoMenu(menuTrigger, 250, $event)"
          (mouseleave)="signalMenuClose(1000)"
          [class]="this[menuItem.title.replace(' ', '').trim().toLowerCase() + 'css']"
          [matMenuTriggerFor]="main_menu">
          {{ menuItem.title }}

          <mat-menu [hasBackdrop]="false" [overlapTrigger]="false" #main_menu>
            <ng-container *ngFor="let subMenuItem of menuItem.subMenus">
              <span *ngIf="subMenuItem.subMenus.length > 0">
                <button mat-menu-item (mouseenter)="signalMenuStayOpen(250)" (mouseleave)="signalMenuClose(250)" [matMenuTriggerFor]="sub_menu">
                  <span [ngClass]="{ 'green-text': subMenuItem.availableInDemo }" [innerHtml]="transformHtml(subMenuItem.title)"></span>

                  <mat-menu #sub_menu>
                    <ng-container *ngFor="let subSubMenuItem of subMenuItem.subMenus">
                      <span *ngIf="subSubMenuItem.subMenus.length > 0">
                        <button mat-menu-item (mouseenter)="signalMenuStayOpen(250)" (mouseleave)="signalMenuClose(250)" [matMenuTriggerFor]="sub_sub_menu">
                          <span [ngClass]="{ 'green-text': subSubMenuItem.availableInDemo }" [innerHtml]="transformHtml(subSubMenuItem.title)"></span>

                          <mat-menu #sub_sub_menu>
                            <ng-container *ngFor="let subSubSubMenuItem of subSubMenuItem.subMenus">
                              <button
                                mat-menu-item
                                (mouseenter)="signalMenuStayOpen(250)"
                                (mouseleave)="signalMenuClose(250)"
                                (click)="displayVideoList(subSubSubMenuItem); highlightMenuItem(menuItem.title.replace(' ', '').trim().toLowerCase() + 'css')">
                                <span [ngClass]="{ 'green-text': subSubSubMenuItem.availableInDemo }" [innerHtml]="transformHtml(subSubSubMenuItem.title)"></span>
                              </button>
                            </ng-container>
                          </mat-menu>
                        </button>
                      </span>
                      <span *ngIf="subSubMenuItem.subMenus.length < 1">
                        <button
                          mat-menu-item
                          (mouseenter)="signalMenuStayOpen(250)"
                          (mouseleave)="signalMenuClose(250)"
                          (click)="displayVideoList(subSubMenuItem); highlightMenuItem(menuItem.title.replace(' ', '').trim().toLowerCase() + 'css')">
                          <span [ngClass]="{ 'green-text': subSubMenuItem.availableInDemo }" [innerHtml]="transformHtml(subSubMenuItem.title)"></span>
                        </button>
                      </span>
                    </ng-container>
                  </mat-menu>
                </button>
              </span>
              <span *ngIf="subMenuItem.subMenus.length < 1">
                <button
                  mat-menu-item
                  (mouseenter)="signalMenuStayOpen(250)"
                  (mouseleave)="signalMenuClose(250)"
                  (click)="displayVideoList(subMenuItem); highlightMenuItem(menuItem.title.replace(' ', '').trim().toLowerCase() + 'css')">
                  <span [ngClass]="{ 'green-text': subMenuItem.availableInDemo }" [innerHtml]="transformHtml(subMenuItem.title)"></span>
                </button>
              </span>
            </ng-container>
          </mat-menu>
        </button>
      </span>
    </ng-container>
  </div>

  <div class="d-flex flex-row" style="flex: 0 1 540px; justify-content: center">
    <!-- Video Container -->
    <app-player [startFullscreen]="false" [autoplay]="true" [pageItem]="playingItem"></app-player>

    <!-- Video List Panel -->
    <div style="flex: 0 0 450px; background-color: white; overflow-y: auto">
      <div class="black-arial d-flex flex-column" style="height: 100%">
        <div *ngIf="showVideoList" class="d-flex flex-column">
          <div class="video-menu-title">{{ currentVideoMenuTitle }}</div>
          <div class="video-menu-columns">
            <div *ngFor="let aColumn of columnList" class="video-menu-column">{{ aColumn.title }}</div>
          </div>

          <div style="display: flex; flex-direction: column; min-height: min-content; margin-bottom: 16px; overflow-y: auto">
            <div *ngFor="let pageItem of organizedPageItems" class="video-menu">
              <!-- Item is a video link -->
              <div [ngStyle]="{ width: pageItem.right !== null ? '70%' : '100%' }" *ngIf="!pageItem.left.imageLink && !pageItem.left.pdfLink">
                <span [innerHTML]="outputNbsps(pageItem.left.indentLevel)"></span>
                <a [class.hlinkb]="pageItem.left.availableInDemo" (click)="playVideo(pageItem.left)" [ngStyle]="{ 'cursor': pageItem.left.availableInDemo ? 'pointer' : 'auto' }">
                  <span [innerHtml]="transformHtml(pageItem.left.title)"></span>
                  <span style="padding-left: 8px;" *ngIf="pageItem.left.duration">({{ pageItem.left.duration }})</span></a>
              </div>
              <div *ngIf="!pageItem.left.imageLink && !pageItem.left.pdfLink && pageItem.right !== null && pageItem.right !== undefined" style="width: 30%" class="center">
                <a [class.hlinkb]="pageItem.right.availableInDemo" (click)="playVideo(pageItem.right)" [ngStyle]="{ 'cursor': pageItem.right.availableInDemo ? 'pointer' : 'auto' }">
                  <img alt="dot" src="/images/dot1.gif" /> <span *ngIf="pageItem.right.duration">({{ pageItem.right.duration }})</span></a>
              </div>

              <!-- Item is an image link -->
              <div [ngStyle]="{ width: '100%' }" *ngIf="pageItem.left.imageLink">
                <span [innerHTML]="outputNbsps(pageItem.left.indentLevel)"></span>
                <a [class.hlinkb]="pageItem.left.availableInDemo" (click)="playVideo(pageItem.left)" [ngStyle]="{ 'cursor': pageItem.left.availableInDemo ? 'pointer' : 'auto' }">
                  <span [innerHtml]="transformHtml(pageItem.left.title)"></span>
                </a>
              </div>

              <!-- Item is a pdf link -->
              <div [ngStyle]="{ width: '100' }" *ngIf="pageItem.left.pdfLink">
                <span [innerHTML]="outputNbsps(pageItem.left.indentLevel)"></span>
                <a [class.hlinkb]="pageItem.left.availableInDemo" (click)="playVideo(pageItem.left)" [ngStyle]="{ 'cursor': pageItem.left.availableInDemo ? 'pointer' : 'auto' }">
                  <span [innerHtml]="transformHtml(pageItem.left.title)"></span>
                </a>
                &nbsp;
                <a *ngIf="pageItem.left.availableInDemo" [href]="baseUrl + pageItem.left.pdfLink" [download]="getPdfFilename(pageItem.left)">
                  <img src="images/pdf-link.png" width="16" height="16" alt="download as pdf" style="vertical-align: text-bottom"/>
                 </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Demo Content -->
        <div *ngIf="currentMenu.pageId === 99" class="center d-flex flex-column" style="width: 450px; align-items: center">
          <div style="flex: 1 1 auto"></div>
          <div style="flex: 0 0 auto">
            <div style="margin-top: 16px">Any link which is colored <span style="color: green">green</span> is available for this demonstration.</div>
            <div style="margin-top: 16px">Please click on <a [routerLink]="['/how-to-use']">"How to Use"</a> for instruction.</div>

            <div style="margin-top: 32px">
              <a *ngIf="!showVideo" [routerLink]="['/textbook']">
                <div><img alt="textbook" src="/images/RA2-cover-small.gif" /></div>
                <div>New Matching Textbook!</div>
              </a>
            </div>
          </div>
          <div style="flex: 1 1 auto"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer with no sound help -->
  <div class="d-flex flex-row center" style="justify-content: center; padding-top: 8px">
    <div style="flex: 0 0 720px; color: white">
      No sound? Click
      <button style="border-width: 0; font-size: 14px; color: white; background-color: RGB(48, 6, 38); text-decoration: underline" (click)="helpChromeSound()">
        <span style="text-decoration: underline; cursor: pointer">here</span></button
      >.
    </div>
    <div style="flex: 0 0 450px"></div>
  </div>
</div>
