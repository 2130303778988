import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent {
  isDesktop(): boolean {
    return environment.envId === 'desktop';
  }

  isMobile(): boolean {
    return environment.envId === 'mobile';
  }

  isTablet(): boolean {
    return environment.envId === 'tablet';
  }
}
