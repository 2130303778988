import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

@Component({ template: '' })
export class BaseScrollingComponent {
  constructor(@Inject('BASE_URL') public baseUrl: string, @Inject(DOCUMENT) private document: Document) {}

  scrollTo(id: string) {
    const element = this.document.getElementById(id);

    if (element) {
      element.scrollIntoView(true);
    }
  }
}
