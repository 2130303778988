<app-content-balloon width="650px">
  <div class="title-text">Support - <span style="font-weight: bold">Downloading and Viewing e-book</span></div>
  <p style="font-weight: bold">
    To download and view our e-book &quot;Rapid Access Guide to Physical Examination,&quot; You will need to follow these four steps in order:
  </p>

  <p style="padding-bottom: 32px">
    If you already have an Adobe ID, skip to
    <a [routerLink]="['/support-ebook-ereader']">(2) Download the specialized e-reader software.</a>
  </p>

  <p>
    <a [routerLink]="['/support-ebook-adobe-id']">1. Obtain an Adobe ID and password.</a>
  </p>
  <p>
    <a [routerLink]="['/support-ebook-ereader']">2. Download the specialized e-reader software.</a>
  </p>
  <p>
    <a [routerLink]="['/support-ebook-download']">3. Download our e-book into the e-reader.</a>
  </p>
  <p style="padding-bottom: 32px">
    <a [routerLink]="['/support-ebook-nav']">4. Navigate our e-book.</a>
  </p>
  <p style="padding-bottom: 32px"><a [routerLink]="['/help']">[back to main support page]</a></p>
  <p>
    If you cannot find the information you need,
    <a [routerLink]="['/contact-us']"><span style="font-weight: bold; color: #0033cc">click here</span></a>
    to send us a message.<br />
  </p>
</app-content-balloon>
