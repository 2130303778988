<div>
  <h3>Registration Form - Email Address Authentication</h3>

  <div class="d-flex flex-column">
    <div class="content-section">
      <app-otp
        #otp
        [verify]="doVerification.bind(this)"
        [doSendVerificationCode]="sendVerificationCode.bind(this)"
        [hasActionStep]="false"
        [emailAddress]="emailAddress"
        buttonTitle="Verify"
      >

      </app-otp>
      <!-- <div *ngIf="serviceFailure" class="content-section-content">
        <p>
          Sorry, we encountered an error while trying to send a confirmation email to {{ emailAddress }}. Please try again later. If the issue persists, please send us a message
          from our <a [routerLink]="['/contact-us']">Contact Us</a> page.
        </p>
      </div>

      <div *ngIf="!serviceFailure && confirmationSent && !confirmationReceived" class="content-section-content">
        <p>
          An email has been sent to {{ emailAddress }} to confirm you have access to the account. Please check your email and follow the directions in that email to confirm your
          email address. Once that has been completed, please return to this page to continue the registration process. If you do not get an email within the next couple of
          minutes, please check your spam folder.
        </p>

        <p><strong>NOTE:</strong>&nbsp;&nbsp;Please do not close this window, return when you have finished verifying your email address.</p>
        <div class="center overlay-panel">
          <div style="flex: 0 0 auto">
            <mat-progress-spinner class="center" color="primary" mode="indeterminate" [diameter]="48"></mat-progress-spinner>
          </div>
          <div class="overlay-text">Waiting for Validation . . .</div>
        </div>
      </div>

      <div *ngIf="!serviceFailure && confirmationReceived" class="content-section-content">
        Thank you for confirming your email address with us. Please continue by clicking next.
      </div> -->
    </div>
  </div>
</div>
