import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MenuEntry, PageItem, TemplateColumn } from '../model/mms.model';

@Injectable({
  providedIn: 'root',
})
export class Menu2Service {
  constructor(private _http: HttpClient, @Inject('BASE_API_URL') private _baseApiUrl: string, @Inject('BASE_URL') private _baseUrl: string) {}

  public loadRootMenu(): Observable<MenuEntry> {
    return this._http.get<MenuEntry>(`${this._baseApiUrl}menus/107`).pipe(
      tap((menuEntry: MenuEntry) => {
        this._sortMenu(menuEntry);

        return menuEntry;
      }),
    );
  }

  public getPageItems(pageId: number): Observable<any> {
    return this._http.get<Array<PageItem>>(`${this._baseApiUrl}pages/${pageId}/items`);
  }

  public getDacast(
    pageItemId: number,
    autoplay: boolean = true,
    allowFullscreen: boolean = true,
    ): Observable<Blob> {

    return this._http.get(`${this._baseUrl}videoPlayer?pageItemId=${pageItemId}&fullscreen=${allowFullscreen}&autoplay=${autoplay}`, {
      responseType: 'blob',
    });
  }

  public getPageColumnDescriptions(pageId: number): Observable<any> {
    return this._http.get<Array<TemplateColumn>>(`${this._baseApiUrl}pages/${pageId}/column-descriptions`);
  }

  private _sortMenu(menu: MenuEntry): void {
    if (menu.subMenus?.length > 0) {
      const menuSorter = (m1, m2) => m1.sortOrder - m2.sortOrder;

      menu.subMenus.sort(menuSorter);
      menu.subMenus.forEach((subMenu) => {
        this._sortMenu(subMenu);
      });
    }
  }
}
