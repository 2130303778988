import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Medical Media Systems';

  getYear() {
    return String(new Date().getFullYear());
  }
}
