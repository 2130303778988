import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasePlayerComponent } from 'src/app/base-player.component';
import { Menu2Service } from 'src/app/services/menus.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css'],
})
export class ProgramsComponent extends BasePlayerComponent {
  jsVideoLink: SafeHtml;

  constructor(
    @Inject('BASE_URL') public baseUrl: string,
    protected menuService: Menu2Service,
    protected _sanitizer: DomSanitizer,
    protected dialog: MatDialog,
  ) {
    super(menuService, _sanitizer, dialog, false);
  }
}
