import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactUsRequest, StatusMessageResponse } from './model/mms.model';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient, @Inject('BASE_API_URL') private baseApiUrl: string) {}

  sendContactRequest(request: ContactUsRequest, captchaResponse: string): Observable<StatusMessageResponse> {
    const headers = new HttpHeaders({});

    if (captchaResponse) {
      headers['captcha-response'] = captchaResponse;
    }

    return this.http.post<StatusMessageResponse>(`${this.baseApiUrl}contact`, request, { headers });
  }
}
