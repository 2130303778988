<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions class="center">
  <button mat-button (click)="onDismiss()">{{cancelText}}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{okText}}</button>
</div>
