import { Component } from "@angular/core";

@Component({
  selector: "app-support-playback",
  templateUrl: "./support-playback.component.html",
  styleUrls: ["./support-playback.component.css"],
})
export class SupportPlaybackComponent {
  constructor() {}
}
