import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IpCompany } from '../model/mms.model';

@Injectable({
  providedIn: 'root',
})
export class SchoolsService {
  constructor(private http: HttpClient, @Inject('BASE_API_URL') private baseApiUrl: string) {}

  getSchoolsList(): Observable<Array<IpCompany>> {
    return this.http.get<Array<IpCompany>>(`${this.baseApiUrl}ip-companies/`);
  }
}
