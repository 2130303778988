import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RegistrationStep, StatusMessageResponse } from 'src/app/model/mms.model';
import { OtpComponent } from 'src/app/otp/otp.component';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-register-email-confirmation',
  templateUrl: './email-confirmation-step.component.html',
})
export class EmailConfirmationComponent implements RegistrationStep {
  readonly showClearButton = true;
  readonly showPreviousButton = true;
  readonly showNextButton = true;
  readonly showSubmitButton = false;

  @ViewChild('otp') otpComponent: OtpComponent;

  @Input() emailAddress: string;
  @Input() dataTokenId: number;
  @Input() username: string;
  @Input() emailValidationRequired = true;

  @Output() emailValidated = new EventEmitter();

  serviceFailure = false;
  confirmationSent = true;
  confirmationReceived = false;

  private _verified = false;

  constructor(private _subscriptionService: SubscriptionService) {}

  onActivate(): void {
    // Advance to second step
    this.otpComponent.buttonClicked();
  }

  doVerification(code: string): Observable<StatusMessageResponse> {
    return of({ success: true });
  }

  sendVerificationCode(dataToken: string): Observable<StatusMessageResponse> {
    return of({ success: true });
  }

  getEmailAddress(): string {
    return null;
  }

  private _checkEmailVerificationStatus(): void {
    console.log('checking if email has been validated.');
    this._subscriptionService.isEmailValidated(this.username, this.dataTokenId).subscribe({
      next: (response) => {
        if (response.success) {
          console.log('response succeeded and was is validated');
          this.serviceFailure = false;
          this.confirmationReceived = true;

          setTimeout(() => this.emailValidated.next(), 3000);
        } else {
          console.log('response came back waiting for verification');
          // Service call succeeded, but email is not yet validated.
          // Try again in 10 seconds
          setTimeout(this._checkEmailVerificationStatus.bind(this), 10000);
        }
      },
      error: () => {
        console.log('got an error trying to check on email address validation.');
        this.serviceFailure = true;
        this.confirmationSent = false;
        this.confirmationReceived = false;
      },
    });
  }

  isNextDisabled(): boolean {
    return this.serviceFailure || !this.confirmationReceived;
  }
}
