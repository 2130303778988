import { Component } from '@angular/core';
import { GlobalVarsService } from '../global-vars.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  constructor(public globalVarsService: GlobalVarsService) {}

  getYear() {
    return String(new Date().getFullYear());
  }
}
